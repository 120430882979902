import React from "react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import hide from "../assets/svg/hide.png";
import EyeIcon from "../assets/svg/EyeIcon";
import { updatePassword } from "../redux/auth/actions";
import { APP_CONST } from "../config/const";
import { Toaster } from "../utils/toaster";
import { logoutUser } from "../redux/auth/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const ChangePasswordModal = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const deviceDetector = new DeviceDetector();
  // const userAgent = window.navigator.userAgent;
  // const device = deviceDetector.parse(userAgent);

  const validateData = (data) => {
    const dataToValidate = data ? data : formData;
    const { oldPassword, newPassword, confirmPassword } = dataToValidate;
    const newErrors = {};
    if (!oldPassword || oldPassword === "") {
      newErrors.oldPassword = "Old Password cannot be empty";
    }
    if (!newPassword || newPassword === "") {
      newErrors.newPassword = "New Password cannot be empty";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm Password cannot be empty";
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Confirm password must match";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = () => {
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      setIsLoading(true);
      let data = { ...formData };
      delete data.confirmPassword;
      updatePassword(data)
        .then((res) => {
          setIsLoading(false);
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res.data.data.message);
          handleClose();
          dispatch(logoutUser());
        })
        .catch((err) => {
          setIsLoading(false);
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          handleClose();
        });
    }
  };
  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    validateData(tempFormData);
    setFormData(tempFormData);
  };
  return (
    <div>
      <div>
        <a variant="" onClick={handleShow}>
          {/* <FaEdit /> */}
          Reset Password
        </a>

        <Modal show={show} onHide={handleClose} className="changePasswordModal">
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Header closeButton>
            <Modal.Title>Reset Your Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <input className="changes_password" type="password" placeholder="Old Password" />
            <input className="changes_password" type="password" placeholder="New Password" />
            <input className="changes_password" type="password" placeholder="Confirm Password" /> */}
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  className="changes_password"
                  placeholder="Enter old password*"
                  name="oldPassword"
                  onChange={handleChange}
                  style={{ color: "black" }}
                  isInvalid={!!errors.oldPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.oldPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control
                  //
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password*"
                  className="changes_password"
                  name="newPassword"
                  style={{ color: "black" }}
                  onChange={handleChange}
                  isInvalid={!!errors.newPassword}
                />
                <span
                  className="icon_view"
                  onClick={() => setShowPassword((s) => !s)}
                >
                  {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control
                  //
                  type="password"
                  placeholder="Enter confirm password*"
                  className="changes_password"
                  name="confirmPassword"
                  style={{ color: "black" }}
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                />
                {/* <span className="icon" onClick={() => setShowPassword((s) => !s)}>
                {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
              </span> */}
                <Form.Control.Feedback type="invalid">
                  {errors?.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleSubmit();
              }}
              disabled={isLoading}
            >
              {isLoading ? "Updating" : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
