import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import Slider1 from "../assets/images/banner/home/IPL.jpg";
import Slider2 from "../assets/images/banner/home/slide2.jpeg";
import Slider3 from "../assets/images/banner/home/slide3.png";
import Slider4 from "../assets/images/banner/home/slide4.png";
import Slider5 from "../assets/images/banner/home/slide5.jpeg";
import Slider6 from "../assets/images/banner/home/slide6.jpg";
import { postData, tagsData } from "../lib/data/bonus.data";
const MainSlider = ({ banners }) => {
  // console.log("Post Data", postData);
  const ticketsData = postData.filter(
    (item) => item.heading === "Free Tickets Terms & Condition"
  );
  // console.log("ticketsData", ticketsData);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };
  const navigate = useNavigate();
  // const { sportsData, oddsData, showBetSlip } = useSelector(
  //   (state) => state.sports
  // );
  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const bannersData = [
    {
      id: 1,
      href: "/sports/Cricket",
      img: Slider1,
    },
    {
      id: 2,
      href: "/sports/Cricket",
      img: Slider2,
    },
    {
      id: 3,
      href: "/sports/Cricket",
      img: Slider6,
    },
    // {
    //   id: 4,
    //   href: "/sports/Cricket",
    //   img: Slider4,
    // },
    {
      id: 5,
      href: "/affiliates",
      img: Slider5,
    },
    {
      id: 6,
      href: "/sports/Cricket",
      img: Slider3,
    },
  ];
  const handleImageClick = (data) => {
    if (data.id === 2) {
      setShowModal(true);
      setModalContent(ticketsData);
    } else if (data.id === 6) {
      setShowModal(true);
      setModalContent(tagsData);
    } else {
      window.location.href = data.href;
    }
  };

  // console.log("ticketsDataaaa:", ticketsData);
  // console.log("modalContent:", modalContent);
  return (
    <div>
      <Slider {...settings} className="d-none">
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="position-relative" key={index}>
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        {(b?.ctabtns.length &&
                          b.ctabtns.map((c, i) => {
                            return (
                              <Button
                                variant="primary"
                                className="button-primary d-none"
                                onClick={() => {
                                  window.location.href = c.actionLink;
                                }}
                              >
                                {c.label}
                              </Button>
                            );
                          })) ||
                          null}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  // href={
                  //   b.order === 1 ? "/sportsbook/cricket" : "/sportsbook/soccer"
                  // }
                  onClick={() => {
                    b.order === 1
                      ? navigate("/sports/Cricket", {})
                      : navigate("/sports/Cricket", {});
                  }}
                  className="d-none"
                >
                  <img src={b.link} alt="First Slide" />
                </a>
              </div>
            );
          })}
      </Slider>

      <Slider {...settings} className="">
        {bannersData.map((data) => {
          return (
            <div className="position-relative" key={data.id}>
              {/* <a href={item.href} key={id}> */}
              <img
                src={data.img}
                alt="slider1"
                onClick={() => {
                  // console.log("Item Id: ", data.id);
                  handleImageClick(data);
                  // console.log("Clicked item :", data);
                }}
              />
              {/* </a> */}
            </div>
          );
        })}
      </Slider>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        className="BonusPostModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {modalContent && modalContent.map((item) => item.content)}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MainSlider;
