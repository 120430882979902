import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { slotsData } from "../../../../lib/data/slots.data";

import PlayBtn from "../../../../assets/images/play-btn.png";

import { useSelector } from "react-redux";

const Slots = ({ showLayout = true }) => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      {showLayout && (
        <div>
          <HeaderAfterLogin />
        </div>
      )}
      <main className="main">
        <div className="slots-section">
          <div className="games-section">
            <div className="game-heading">
              <span>Slots</span>
            </div>
            <ul>
              {Object.values(slotsData).map((item) => {
                return (
                  <li className="game-section">
                    <a href={isAuth ? item.redirectUrl : "/login"}>
                      <img src={item.imgUrl} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                      <p>{item.name}</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>
      {showLayout && (
        <div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Slots;
