import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

export class BeforeTerms extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <Header />
        </div>
        <div>
          <main className="main mt-5">
            <div className="container">
              <h2 className="sectionTitle"> Terms & Conditions</h2>
              <div className="terms-content">
                <h3
                  style={{
                    margin: "20px 0 20px 0",
                  }}
                >
                  INTRODUCTION: KEY DEFINITIONS AND WHAT MAKES UP YOUR TERMS OF
                  USE
                </h3>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0 20px 0",
                    fontWeight: "700",
                  }}
                >
                  The following definitions are used in these terms and
                  conditions:
                </h4>
                <p>
                  1. "Access Device" means any electronic means of accessing the
                  Services, including, but not limited to, computers, smartphone
                  devices, feature phones, tablet devices, touch devices or any
                  home entertainment system such as video games consoles and
                  smart TVs (or by any other remote means);
                </p>
                <p>
                  2. "Bonus Terms" means any terms and conditions and/or rules
                  with regard to promotions, bonuses and special offers which
                  may apply to any part of the Services from time to time;
                </p>
                <p>
                  3. "Supervision institution" means the supervision institution
                  of commercial gambling in Curacao;
                </p>
                <p>
                  4. "General Terms" means the terms and conditions set out in
                  this document;
                </p>
                <p>
                  5. "Group" includes New Entertainment Development N.V.,
                  registration No.162581, registered address is at Abraham de
                  Veerstraat 9. The division of competence of group companies is
                  detailed in these Terms and Conditions;
                </p>
                <p>
                  6. "Operator" means Group of companies - New Entertainment
                  Development N.V., registration No. 162581, registered address
                  is at Abraham de Veerstraat 9;
                </p>
                <p>
                  7. "Payment Processor" means New Entertainment Development
                  N.V., registration No. 162581, registered address is at
                  Abraham de Veerstraat 9;
                </p>
                <p>
                  8. "Privacy Policy" means the Operator's privacy policy
                  accessed via the Privacy Policy link, which is an inseparable
                  part of these Terms and Conditions;
                </p>
                <p>
                  9. "Rules" means the Betting Rules and the Game Rules
                  specifically applicable to the relevant type of betting and/or
                  gaming, as identified in more detail in paragraph 1.3;
                </p>
                <p>
                  10. "Refund" means a reversal of a means as per request of a
                  Player deposited in the Player Account not used for the
                  Services.
                </p>
                <p>
                  11. "Services" means, as appropriate, the services offered for
                  the time being by the Operator through the Website and/or via
                  any Access Device application;
                </p>
                <p>
                  12. "Terms of Use" means (a) the General Terms; (b) the
                  Privacy Policy; (c) where appropriate under paragraph 1.3, the
                  relevant Rules, Bonus Terms and Additional Terms applicable to
                  the Services that are being used by You; and
                </p>
                <p>
                  13. "Website" means the website or any respective page,
                  subpage, subdomain or section thereof from time to time,
                  located at or accessible via the domain name: bharatplays.com.
                </p>
                <p>
                  1.2 By using and/or visiting any section of the Website, or by
                  opening an account with the Operator through the Website, You
                  agree to be bound by the Terms of Use and You accordingly: (a)
                  agree to the use of electronic communications in order to
                  enter into contracts; and (b) waive any applicable rights or
                  requirements which require a signature by hand, to the extent
                  permitted by any applicable law; (c) agree, that in order to
                  use our service you are requested to provide us with certain
                  personal information which shall be processed on the basis of
                  our Privacy Policy. The Terms of Use do not affect your
                  statutory rights.
                </p>
                <p>
                  1.3 In addition, where You play any game, or place a bet using
                  the Services, or otherwise use the Services, You agree to be
                  bound by the Rules of any game You play ("Game Rules"), as set
                  out under the relevant general Help section and any Rules
                  tabs, in respect of any new games, the rules applicable to
                  such game; any Bonus Terms; any terms and conditions relating
                  to withdrawals and any other terms applicable to the Services
                  and/or which You are required to confirm Your agreement to as
                  part of the Services.
                </p>
                <p>
                  1.4 The original text of the Terms of Use is in English and
                  any interpretation of them will be based on the original
                  English text. If the Terms of Use or any documents or notices
                  related to them are translated into any other language, the
                  original English version will prevail.
                </p>
                <p>
                  1.5 Please read the Terms of Use carefully before accepting
                  them. Once You have accepted the Terms of Use, please print
                  the Terms of Use and store them, along with all confirmation
                  emails, additional terms, transaction data, game rules, fair
                  deal rules and payment methods relevant to Your use of the
                  Website. Please note that the Terms of Use are subject to
                  change, as set out in paragraph 3 below.
                </p>
                <p>
                  1.6 If You do not agree to accept and be bound by the Terms of
                  Use please do not open an account, and/or continue to use Your
                  Account. Your continued use of any of the Services will
                  constitute acceptance of the Terms of Use which we have
                  presented to You and are in force from time to time.
                </p>
                <p>
                  1.7 For the avoidance of doubt, each and all sections of the
                  Website are governed by the Terms of Use, and You should
                  ensure at all times that Your use of the Services is in
                  accordance with the Terms of Use.
                </p>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0 20px 0",
                    fontWeight: "700",
                  }}
                >
                  GENERAL TERMS
                </h4>
                <h5>2. CONTRACTING PARTIES</h5>
                <p>
                  2.1 The Terms of Use shall be agreed between You and the
                  Operator and the Payment processor. All information on the
                  Website is provided by the provider of services on the
                  Website, a company New Entertainment Development N.V.
                  (hereinafter bharatplays.com), is a company operating
                  bharatplays.com. Services are provided to card holder by New
                  Entertainment Development N.V. In the event of any inquiries
                  and complaints, please direct them to New Entertainment
                  Development N.V. New Entertainment Development is incorporated
                  under the laws of Curacao, Netherlands Antilles and operates
                  its regulated activities in terms of License No. 365-JAZ. New
                  Entertainment Development N.V. as Operator in these Terms and
                  Conditions is referred to as bharatplays, bharatplays.com,
                  “We”, “Us”, “Our”, “Management”, “Site” or “Company” that you
                  enter contract with. The Player and registered Account Holder
                  shall be referred to as “You”, “Yours”, “Customer” or “The
                  Player”.
                </p>
                <p>
                  2.2 In the case of terms and conditions relating to monies
                  held in Your Account from time to time, to any Operator Group
                  company which holds such money and shall (where appropriate)
                  be deemed to include our agents, partners, and suppliers.
                </p>
                <h5>3. CHANGES TO THE TERMS OF USE</h5>
                <p>
                  3.1 We may need to change the Terms of Use from time to time
                  for a number of reasons, including (without limitation) for
                  commercial reasons, to comply with law or regulations, to
                  comply with instructions, guidance or recommendations from a
                  regulatory body, or for customer service reasons. The most
                  up-to-date Terms of Use can be accessed from the Terms and
                  Conditions link in the footer section of the Website.
                </p>
                <p>
                  3.2 Where we wish to make substantial changes to the Terms of
                  Use, we will give You as much prior notice of such changes as
                  is reasonably practicable via one of the methods set out in
                  paragraph 3.3. For minor or insubstantial changes, we may not
                  give You any notice of such changes. So You are advised to
                  review the Terms of Use through the Terms and Conditions link
                  on the Website on a regular basis.
                </p>
                <p>
                  3.3 Where we make changes to the Terms of Use which we wish to
                  notify You of, we will do so by such a method of notification
                  as we may, in our discretion, deem appropriate.
                </p>
                <h5>4. OPENING YOUR ACCOUNT</h5>
                <p>
                  4.1 In order to place a bet or play a game using the Services,
                  You will need to open an account with the Operator ("Your
                  Account" or "Account").
                </p>
                <p>
                  4.2 In order to open Your Account for use with the Services,
                  You can:
                  <ul>
                    <li>
                      4.2.1 click on Join Now on the Website and follow the
                      on-screen instructions; or
                    </li>
                    <li>
                      4.2.2 open by such other Account opening method as shall,
                      from time to time be offered by the Operator;
                    </li>
                  </ul>
                </p>
                <p>
                  {" "}
                  4.3 When You open Your Account You will be asked to provide us
                  with personal information, including Your name and date of
                  birth and appropriate contact details, including an address,
                  telephone number and e-mail address ("Your Contact Details").
                  You may update Your Contact Details from time to time by
                  contacting Customer Services; or through the My Account
                  management page on the Website: or by such other method as
                  shall, from time to time, be offered by the Operator.
                </p>
                <p>
                  {" "}
                  4.4 In opening Your Account You warrant that:
                  <ul>
                    <li>
                      4.4.1 You understand and accept the risk that, by using
                      the Services, You may, as well as winning money also lose
                      money;
                    </li>
                    <li>
                      4.4.2 You are: (a) over 18 years of age, and (b) above the
                      age at which gambling or gaming activities are legal under
                      the law or jurisdiction that applies to You (the "Relevant
                      Age");
                    </li>
                    <li>
                      4.4.3 gambling is not illegal in the territory where You
                      reside;
                    </li>
                    <li>4.4.4 You are legally able to enter into contracts;</li>
                    <li>4.4.5 You have not been excluded from gambling; and</li>
                    <li>
                      4.4.6 You do not have an Account opened with us already;
                      or
                    </li>
                    <li>
                      4.4.7 You have not already had an Account closed by us for
                      any reason.
                    </li>
                  </ul>
                </p>
                <p>
                  4.5 Your Account must be registered in Your own, correct,
                  real, name and personal details and it shall only be issued
                  once for You and not duplicated through any other person,
                  family, household, address (postal or IP), email address,
                  Access Device or any environment where Access Devices are
                  shared (e.g. schools, workplaces, public libraries etc) and/or
                  account in respect of the Services. Any other accounts which
                  You open with us, or which are beneficially owned by You in
                  relation to the Services shall be "Duplicate Accounts". We may
                  close any Duplicate Account (but shall not be obliged to do
                  so). If we close a Duplicate Account:
                  <ul>
                    <li>
                      4.5.1 all bonuses, free bets and winnings accrued from
                      such bonuses and free bets obtained using that Duplicate
                      Account will be void and forfeited by You;
                    </li>
                    <li>
                      4.5.2 we may, at our entire discretion, void all winnings
                      and refund all deposits (less amounts in respect of void
                      winnings) made in respect of that Duplicate Account and,
                      to the extent not recovered by us from the relevant
                      Duplicate Account, any amounts to be refunded to us by You
                      in respect of a Duplicate Account may be recovered by us
                      directly from any other of Your Accounts (including any
                      other Duplicate Account); or
                    </li>
                    <li>
                      4.5.3 we may, at our entire discretion, allow usage of the
                      Duplicate Account to be deemed valid in which case all
                      losses and stakes placed by or for You through the
                      Duplicate Account shall be retained by us.
                    </li>
                  </ul>
                </p>
                <p>
                  4.6 You are not allowed to register on the Website and use our
                  services if you are a resident of Aruba, Australia, Bonaire,
                  Curacao, France, Iran, Iraq, Netherlands, Saba, Spain, St
                  Maarten, Statia, U.S.A. or the U.S.A. dependencies, United
                  Kingdom. We reserve the right to refuse customers from any
                  other countries over and above the aforementioned
                  jurisdictions at our own discretion.
                </p>
                <p>
                  4.7 You cannot transfer, sell, or pledge Your Account to
                  another person. This prohibition includes the transfer of any
                  assets of value of any kind, including but not limited to
                  ownership of accounts, winnings, deposits, bets, rights and/or
                  claims in connection with these assets, legal, commercial, or
                  otherwise. The prohibition on said transfers also includes
                  however is not limited to the encumbrance, pledging,
                  assigning, usufruct, trading, brokering, hypothecation and/or
                  gifting in cooperation with a fiduciary or any other third
                  party, company, natural or legal individual, foundation and/or
                  association in any way shape or form.
                </p>
                <h5>
                  5. VERIFICATION OF YOUR IDENTITY; ANTI-MONEY LAUNDERING
                  REQUIREMENTS
                </h5>
                <p>
                  5.1 You warrant that:
                  <ul>
                    <li>
                      5.1.1 the name and address You supply when opening Your
                      Account are correct; and
                    </li>
                    <li>
                      5.1.2 You are the rightful owner of the money which You at
                      any time deposit in Your Account.
                    </li>
                  </ul>
                </p>
                <p>
                  5.2 By agreeing to the Terms of Use You authorise us to
                  undertake any such verification checks from time to time as we
                  may require ourselves or may be required by third parties
                  (including, but not limited to, regulatory bodies) to confirm
                  these facts (the "Checks"). You agree that from time to time,
                  upon our request (including requests from Payment processor),
                  You may be required to provide additional details in respect
                  of any of such information You have provided us, including in
                  relation to any deposits which You have made into Your
                  Account.
                </p>
                <p>
                  5.3 Whilst we are undertaking any Checks from time to time, we
                  may restrict You from withdrawing funds from Your Account
                  and/or prevent access to all or certain parts of the Website.
                  Please note that we may from time to time re-perform the
                  Checks for regulatory, security or other business reasons. If
                  any such restrictions cause You a problem, please contact us
                  at – support@bharatplays.com
                </p>
                <p>
                  5.4 In certain circumstances we may have to contact You and
                  ask You to provide further information to us directly in order
                  to complete the Checks. For this purpose, we will be entitled,
                  at our sole discretion, to require that You provide us with a
                  notarised ID or any equivalent certified ID according to the
                  applicable law of Your jurisdiction or otherwise, proof of
                  address, utility bills, bank details, bank statements and bank
                  references and any documentation that validates your source of
                  funds. Until such information has been supplied to our
                  satisfaction we may prevent any activity to be undertaken by
                  You in relation to the Account, or we may where we reasonably
                  believe that deliberately incorrect information has been
                  provided by You, keep any amount deposited on the Account
                  following the closure of the Account by us.
                </p>
                <p>
                  5.5 It may be an offence for persons under the Relevant Age to
                  make use of the Website. If we are unable to confirm that You
                  are the Relevant Age then we may suspend Your Account until
                  such time that we are able to confirm that You are the
                  Relevant Age. If You are subsequently proven to have been
                  under the Relevant Age at the time You made any gambling or
                  gaming transactions with us, then:
                  <ul>
                    <li>5.5.1 Your Account will be closed;</li>
                    <li>
                      5.5.2 all transactions made whilst You were underage will
                      be made void, and all related funds deposited by You will
                      be returned by the payment method used for the deposit of
                      such funds, wherever practicable;
                    </li>
                    <li>
                      5.5.3 any deposits made whilst You were under the Relevant
                      Age will be returned to You; and
                    </li>
                    <li>
                      5.5.4 any winnings which You have accrued during such time
                      when You were under the Relevant Age will be forfeited by
                      You (and shall be deducted from the amount of any deposit
                      returned under paragraph 5.5.3) and You will return to us
                      on demand any such funds which have been withdrawn from
                      Your Account.
                    </li>
                  </ul>
                </p>
                <h5>6. KNOW YOUR CLIENT POLICY</h5>
                <p>
                  To make the Age Verification process as easy as possible, here
                  are a few hints for when you send in your documents:
                  <ul className="subpoints">
                    <li>● Each document must be sent as a separate image</li>
                    <li>
                      ● Photo Captured images are preferred, but if you'd rather
                      scan your documents, send them as a .jpeg
                    </li>
                  </ul>
                </p>
                <p>
                  When you take your picture:
                  <ul className="subpoints">
                    <li>
                      ● Be sure that the first 6 and the last 4 digits of your
                      credit card number should be visible, and CVV should be
                      hidden
                    </li>
                    <li>
                      ● Your picture must be in focus and all the text easy to
                      read
                    </li>
                    <li>
                      ● Your full passport photo page or ID must be in shot,
                      don't leave any bits out or cover with your hand or
                      fingers
                    </li>
                    <li>
                      ● Your lighting must be good; to stop any glare don't use
                      flash
                    </li>
                  </ul>
                  We need all of this because it's part and parcel of gambling
                  responsibly - and the applicable law requires it.
                </p>
                <p>
                  6.1. Why do I need to verify my account?
                  <ul>
                    <li>
                      When you sign up for a new account we check that you are
                      over 18 and we verify that you are who you say you are, (a
                      process called Know Your Customer 'KYC'). It's part and
                      parcel of gambling responsibly, and something that the law
                      requires us to do.
                    </li>
                  </ul>
                </p>
                <p>
                  6.2. What do I need to provide?
                  <ul>
                    <li>
                      To verify your age we will accept one of the following
                      documents:
                      <ul className="subpoints">
                        <li>● Passport (recommended)</li>
                        <li>● Driving license</li>
                        <li>● National ID Card</li>
                        <li>
                          ● Birth Certificate (verification takes up to 24
                          hours)
                        </li>
                        <li>
                          ● Payment card (note that first 6 and last 4 digits
                          should be visible and CVV should be hidden).
                        </li>
                      </ul>
                    </li>
                  </ul>
                  Card data shall not be collected and stored by Us without PCI
                  DSS.
                </p>
                <p>
                  6.3. How can I send you my documents?
                  <ul>
                    <li>
                      The quickest and easiest way to verify your account is
                      online. You can also send the documents via email. Here
                      are a few tips on how to get it right first time:
                      <ul className="subpoints">
                        <li>● Sending your documents via email</li>
                        <li>
                          ● Take a photo of each of your documents and save the
                          photos (or, scan them and save as a .jpeg)
                        </li>
                        <li>
                          ● Open the email account that you used to register for
                          our Website
                        </li>
                        <li>
                          ● Open a new email, addressed to
                          support@bharatplays.com
                        </li>
                        <li>● Attach the photos</li>
                        <li>
                          ● Enter your account number or username in the
                          'Subject' bar at the top of your email
                        </li>
                        <li>● Press "Send"</li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      When you register for an account, on the account
                      registration page, we'll need you to provide certain
                      information for security reasons:
                      <ul className="subpoints">
                        <li>
                          ● Your name and contact details, including a valid
                          email address, which needs to be unique to your
                          account
                        </li>
                        <li>
                          ● Your date of birth - you must be at least 18 years
                          old
                        </li>
                        <li>
                          ● A unique username (your username may contain only
                          letters, numbers, underscore and hyphen symbols) and a
                          password which means only you can access your account
                        </li>
                        <li>● Your betting currency and a deposit limit</li>
                        <li>● A security question and the answer</li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p>
                  Our registration page checks your details as you enter them
                  and will let you know if there's a problem, so you can make
                  any changes you need to before you click on the 'Create My
                  Account' button. For legal reasons when travelling abroad you
                  may not be able to access your account. We are unable to
                  accept accounts or offer any of our products or services to
                  clients resident in the following countries:
                </p>
                <p>Game provider Restricted territories</p>
                <p>
                  1x2 Network No Customers shall be accepted from UK, Italy,
                  Sweden, Australia, France, Israel, United States of America,
                  Afghanistan, Algeria, Cuba, Curacao, Egypt, Haiti, Iran, Iraq,
                </p>
                <p>
                  Laos, Libya,North Korea, Pakistan, Palestinian territory
                  occupied, Philippines, Somalia, Syria. Ainsworth No Customers
                  shall be accepted from USA, Canada, Curacao, Australia, China,
                  Singapore, Iran, North Korea, Cuba, Sudan and Syria
                </p>
                <p>
                  Amatic No Customers shall be accepted from Afghanistan,
                  Albania, Algeria, Angola, Antigua and Barbuda, Armenia,
                </p>
                <p>
                  Australia, Austria, Bosnia, Cambodia, China, Cuba, Curacao,
                  Cyprus, Ecuador,
                </p>
                <p>
                  Estonia, France and Territories, French Polynesia, Georgia,
                  Guyana, Hong Kong, Indonesia, Iran, Iraq, Israel, Kuwait,
                  Laos, Libya, Liechtenstein, Macao, Mexico, Myanmar, Namibia,
                  Netherlands Antilles
                </p>
                <p>
                  Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea,
                  Peru,
                </p>
                <p>
                  Philippines, Singapore, Slovakia, South Africa, South Korea,
                  Sudan,
                </p>
                <p>
                  Switzerland, Syria, Taiwan, Tunisia, Uganda, United States of
                  America and Territories, Vietnam, Yemen, Zimbabwe
                </p>
                <p>
                  Apollo No Customers shall be accepted fromAlgeria,
                  Afghanistan, Armenia, Antigua & Barbuda, Bahrain, Bangladesh,
                  Bhutan, Brunei, Cambodia, China, Cuba, Curacao,
                </p>
                <p>
                  Georgia, Guyana, Iran, Iraq, Israel, Kahnawake, Kazakhstan,
                  Kuwait, Libya, Malaysia, Myanmar, North Korea,
                  Pakistan,Panama,
                </p>
                <p>
                  Papua New Guinea, Philippines, Qatar, Saudi Arabia, Sudan,
                  Syria, Thailand, United States of America and its Territories.
                </p>
                <p>
                  Asia Gaming No Customers shall be accepted from Taiwan, Hong
                  Kong, Macau, America, Singapore & Philippines, Curacao.
                </p>
                <p>
                  Asia Live Tech No Customers shall be accepted from USA,
                  Russia, Curacao.
                </p>
                <p>
                  Authentic Gaming No Customers shall be accepted from Israel,
                  Hong Kong, China, Afganistan, Curacao, Cyprus, Ethiopia, Iran,
                  Iraq, Kuwait, Somalia, Syria, Sweden, Yemen, UK, USA, France,
                  Yemen, Germany.
                </p>
                <h5>For the game GREAT BRITISH ROULETTE:</h5>
                <p>
                  Afghanistan, Botswana, Cambodia, China, Curacao, Cyprus,
                  Ethiopia, Egypt, France, Ghana, Hong Kong, Iceland, Iran,
                  Iraq, Israel, Japan, Kuwait, Macau, Malaysia, Mongolia, North
                  Korea, Pakistan, Panama, Saudi Arabia, Singapore, Sint
                  Maarten, Somalia, Syria, The Bahamas, Trinidad and Tobago,
                  Turkey, United States of America, Yemen, Zimbabwe
                </p>
                <h5>
                  The Excluded Territories for Great British Roulette are:
                </h5>
                <p>
                  Afghanistan, Botswana, Cambodia, China, Curacao, Cyprus,
                  Ethiopia, Egypt, France, Ghana, Hong Kong, Iceland, Iran,
                  Iraq, Israel, Japan, Kuwait, Macau, Malaysia, Mongolia, North
                  Korea, Pakistan, Panama, Saudi Arabia, Singapore, Sint
                  Maarten, Somalia, Syria, The Bahamas, Trinidad and Tobago,
                  Turkey, United States of America, Yemen and Zimbabwe.
                </p>
                <p>
                  BEEFEE GAMING No Customers shall be accepted from Afghanistan,
                  Iran, Iraq, Israel, Libya, Luxembourg, Malaysia, Singapore,
                  Sudan, Syria, the USA and its territories, Curacao
                  BetConstruct No Customers shall be accepted from USA, Curacao.
                </p>
                <p>
                  BetGamesTV No Customers shall be accepted from France and
                  United States of America and Territories, Curacao.
                </p>
                <p>
                  BetRadarVS No Customers shall be accepted from USA, Curacao.
                </p>
                <p>
                  BetSoft No Customers shall be accepted from United States of
                  America and Territories, Curacao.
                </p>
                <p>
                  BLUEPRINT GAMING "PROHIBITED COUNTRIES: Australia, United
                  States and its Territories and Possessions, Curacao, Germany,
                  Iran, Syria, North Korea, Cuba, Sudan, South Sudan and Myanmar
                </p>
                <p>
                  MUST BE LICENSED TO OPERATE IN THE FOLLOWING: Austria Belgium
                  Estonia Denmark Spain France Italy Sweden Portugal Latvia
                  Czech Republic Romania Switzerland Croatia Lithuania Bulgaria
                  United Kingdom Canada (State Lotteries Only) Hungary TOP CAT /
                  THUNDERCATS / THE GOONIES / MARS ATTACKS!
                </p>
                <p>
                  PROHIBITED COUNTRIES: Afghanistan Australia Bahrain Burma
                  (Myanmar) China Cuba Hong Kong Hungary Indonesia Iran Iraq
                  Israel Japan Kuwait Malaysia North Korea Qatar Pakistan
                  Russian Federation Saudi Arabia South Africa South Korea Sudan
                  Syria Thailand Turkey Ukraine United Arab Emirates United
                  States and its Territories and Possessions Germany
                </p>
                <p>
                  MUST BE LICENSED TO OPERATE IN THE FOLLOWING: Austria Belgium
                  Canada (State Lotteries Only) Denmark Estonia France Spain
                  Greece Italy Sweden Portugal Latvia Czech Republic Romania
                  Switzerland Croatia Lithuania Bulgaria Hungary United Kingdom
                  WORMS & WORMS RELOADED ADDITIONAL PROHIBITED TERRITORRIES:
                  United States and its Territories and Possessions, Canada
                </p>
                <p>
                  CASPERS MYSTERY MIRROR + HAGAR THE HORRIBLE RESTRICTED
                  TERRITORRIES: Prohibited in all territories EXCEPT; UK and
                  Europe (with the exception of Germany)
                </p>
                <p>
                  DEAL OR NO DEAL PROHIBITED & RESTRICTED TERRITORRIES (Applies
                  to following game titles: Deal or No Deal What’s In Your Box,
                  Deal or No Deal Scratchcard (Instant Win),{" "}
                </p>
                <p>
                  Deal or No Deal The Perfect Play, Deal or NO Deal Megaways™):
                </p>
                <p>
                  PROHIBITED COUNTRIES: United States and its Territories and
                  Possessions Germany, Hong Kong Turkey France Spain Belgium
                  Singapore Iran Syria North Korea Sudan Italy Sweden Denmark
                  Spain Portugal Latvia Czech Republic Estonia Romania
                  Switzerland Croatia Lithuania Bulgaria Hungary Any regulated
                  worldwide territory, except UK
                </p>
                <p>
                  MUST BE LICENSED TO OPERATE IN THE FOLLOWING:United Kingdom
                  NAKED GUN + TED + ANDY CAPP + INSPECTOR GADGET + SPINAL TAP
                  PROHIBITED{" "}
                </p>
                <p>
                  TERRITORRIES: United States and its Territories and
                  Possessions, Canada BEAVIS & BUTTHEAD PROHIBITED TERRITORIES:
                  United States and its Territories and Possessions Germany Cuba
                  Iran Syria Ukraine Russia Sudan North Korea SAUSAGE PARTY™
                  PROHIBITED & RESTRICTED TERRITORIES: United States and its
                  Territories and Possessions, Germany, Iran, Syria, North
                  Korea, Cuba, Sudan, South Sudan and Myanmar
                </p>
                <p>
                  MUST BE LICENSED TO OPERATE IN THE FOLLOWING: Austria Belgium
                  Estonia Denmark Spain France Italy Sweden Portugal Latvia
                  Czech Republic Romania Switzerland Croatia Lithuania Bulgaria
                  Hungary United Kingdom
                </p>
                <p>
                  RICK AND MORTY™ RESTRICTED TERRITORRIES: Prohibited in all
                  territories EXCEPT; United Kingdom, Sweden, Spain, Portugal,
                  Italy, Ireland, Belgium, Denmark, Romania, Greece, Finland,
                  and Norway"
                </p>
                <p>
                  Booming Games No Customers shall be accepted from Australia,
                  Belgium (other than with license), Bulgaria (other than with
                  license), Cayman Islands, Czech Republic (other than with
                  license),
                </p>
                <p>
                  Columbia (other than with license), Curacao, Denmark (other
                  than with license), Estonia (other than with license), Iran,
                  Iraq,
                </p>{" "}
                <p>
                  Israel, Italy (other than with license), Latvia (other than
                  with license), Lithuania (other than with license), North
                  Korea,
                </p>
                <p>
                  Portugal (other than with license), Romania (other than with
                  license), Saudi Arabia, Singapore, Slovakia (other than with
                  license),
                </p>
                <p>
                  Spain (other than with license), United Kingdom (other than
                  with license), United States of America, and all territories
                  and possessions,
                </p>
                <p>
                  being the U.S. Virgin Islands, U.S. Minor Outlying Islands,
                  Guam, Puerto Rico, Marianas Islands and American Samoa,
                  Vatican City
                </p>
                <p>
                  Booongo No Customers shall be accepted from USA, Netherlands,
                  Israel, Curacao
                </p>
                <p>
                  Concept Gaming No Customers shall be accepted from France,
                  Netherlands, USA, Curacao
                </p>
                <p>
                  CQ9 No Customers shall be accepted from Taiwan and Singapore
                  USA, Curacao
                </p>
                <p>
                  Digitain No Customers shall be accepted from United States of
                  America, Republic of Armenia, Aruba, Bonaire, Curacao, France,
                  Netherlands, Saba,
                </p>
                <p>Singapore, Statia and St Maarten, Curacao.</p>
                <p>
                  EDICT No Customers shall be accepted from Afghanistan,
                  Algeria, American Samoa, Angola, Argentina, Armenia, Aruba,
                  Australia, Azerbaijan, Bahamas, Bahrain, Bangladesh, Barbados,
                  Belarus, Belgium, Bolivia, Bosnia and Herzegovina, Brunei,
                  Burundi, Cambodia, Canada, Cayman Islands, China (People’s
                  Republic of), Christmas Island, Cocos Island, Colombia, Congo
                  (Republic of the), Cook Islands, Cote D’Ivoire (Ivory Coast),
                  Cuba, Curacao, Czech Republic, Denmark, Dominica, Ecuador,
                  Egypt, Eritrea, Ethiopia, Fiji, Finland, France, French
                  Guiana, French Polynesia, French Southern Territories,
                  Germany, Ghana, Grenada, Guadeloupe, Guam, Guatemala, Guinea,
                  Guyana, Haiti, Heard and McDonald Island, Holy See (Vatican
                  City), Honduras, Hong Kong, Hungary, Indonesia, Iran, Iraq,
                  Israel, Italy, Japan, Jordan, Kenya, Korea (North), Korea
                  (South), Kuwait, Kyrgyzstan, Lao People’s Democratic Republic,
                  Lebanon, Liberia, Libya, Macao, Malawi, Malaysia, Marshall
                  Islands, Martinique, Mayotte, Micronesia, Mongolia,
                  Mozambique, Myanmar/ Burma, Namibia, Nauru, Netherlands, New
                  Caledonia, Nicaragua, Niger, Nigeria, Niue, Norfolk Island,
                  Northern Mariana Islands, Norway, Pakistan, Palestinian
                  Territories, Palestinian Territory (Occupied), Panama, Papua
                  New guinea, Paraguay, Philippines, Poland, Portugal, Puerto
                  Rico, Qatar, Republic of China (ROC)/ Taiwan, Reunion, Russia,
                  Rwanda, Saint Barthelemy, Saint Kitts & Nevis, Saint Martin,
                  Saint Pierre and Miquelon, Sao Tome and Principe, Saudi
                  Arabia, Sierra Leone, Singapore, Slovakia, Somalia, Spain, Sri
                  Lanka, St Pierre & Miquelon, St Vincent & Grenadines, Sudan,
                  Suriname, Switzerland, Syrial Arab Republic, Tajikistan,
                  Tanzania, Thailand, Timor-Leste, Trinidad and Tobago, Tunisia,
                  Turkey, Turkmenistan, Uganda, United Kingdom, United States of
                  America, United States Minor Outlying Islands, US Minor
                  Islands, Uzbekistan, Venezuela, Vietnam, Virgin Islands (US),
                  Wallis Futuna, Yemen (Republic of), Zaire.
                </p>
                <p>
                  EGT interactive No Customers shall be accepted from United
                  States of America and its territories and possessions,
                  including the U.S. Virgin Islands, the U.S. Minor Outlying
                  Islands, Guam, Curacao, Puerto Rico, Mariana Islands, American
                  Samoa, Georgia, Australia, Netherlands and the United Kingdom.
                </p>
                <p>
                  Elk Studios No Customers shall be accepted from USA, France,
                  Australia, China, Curacao
                </p>
                <p>
                  Endorphina No Customers shall be accepted from USA, Curacao,
                  France, Australia
                </p>
                <p>
                  Evolution Gaming No Customers shall be accepted from Burma
                  (Myanmar), China, Cuba, Curacao, Cyprus, Iran, Israel, Latvia,
                  Turkey, Russian Federation, Sudan, Syria,United States of
                  America,
                </p>
                <p>
                  Australia, North Korea, Iran, Taiwan, South Africa, Canada,
                  (British Columbia, Manitoba and Quebec Provinces).
                </p>
                <p>
                  Restricted for game "Dream Catcher": Denmark, Romania, Spain
                </p>
                <p>Restricted for game "American Roulette": Spain</p>
                <p>
                  Additional rectrictions for Branded game Craps: Denmark,
                  Romania, Spain, Belgium, Portugal
                </p>
                <p>
                  EVOplay No Customers shall be accepted from USA, Curacao, UK,
                  Israel, North Korea, Iran, Australia
                </p>
                <p>
                  Ezugi No Customers shall be accepted from GB – United Kingdom,
                  NL - Netherlands, US – United state, RO – Romania, ZA - South
                  Africa, BG – Bulgaria, HU – Hungary LV – Latvia for local
                  players, ES – Spain, DK – Denmark, CO – Colombia, IT – Italy
                  LT – Lithuania
                </p>
                <p>
                  The following countries are permanently blocked
                  <ul>
                    <li>1. IL -Israel</li>
                    <li>2. AU – Australia</li>
                    <li>3. FR – France </li>
                    <li>4. SX +BQ - Dutch West Indies</li>
                    <li> 5. CW – Curacao</li>
                    <li> 6. IR - Iran</li>
                    <li>
                      {" "}
                      7. KP - Democratic republic of North Korea 8. SE – Sweden
                    </li>
                  </ul>
                </p>
                <p>
                  Fugaso No Customers shall be accepted from USA, UK, Curacao
                </p>
                <p></p>GameART No Customers shall be accepted from France,
                Belgium, Hong Kong, Israel, UK, United States of America and its
                territories, UK, Denmark
                <p>Sweden, Portugal, Curacao.</p>
                <h5>For games:</h5>
                <p>
                  Dragon King, Wild Dolphin, Venetia, Lady Luck, Wolf
                  Quest,Explosive Reels, Gold Of Ra, Dancing Lions, Phoenix
                  Princess, Fortune Panda, Magic Unicorn, Ancient Gong, Power
                  Dragon, Jumpin Pot, African Sunset)
                </p>
                <p>
                  France, Belgium, Curacao, Sweden, Denmark, Hong Kong, Israel,
                  UK, United States of America and its territories, American
                  Samoa, Australia, Brunei, Cambodia, China, Hong Kong, India,
                  Indonesia, Japan, North Korea, South Korea, Laos, Macau,
                  Malaysia, Mongolia, Burma, New zealand, Paupa New Guinea,
                  Philippines, Singapore, Sri Lanka, Taiwan, Thailand, Timor,
                  Vietnam, Denmark, Genii
                </p>
                <p>
                  No Customers shall be accepted from Australia, Bulgaria,
                  Denmark, Curacao, Estonia, Spain, United Kingdom, Italy,
                  Netherlands, Poland, Portugal, Romania, United States, South
                  Africa
                </p>
                <p>
                  GG Network No Customers shall be accepted from Afganistan,
                  Albania. Netherlands Antilles, Andora, American Samoa,
                  Australia, Aland Islands, Bahrain, Bonaire - Sant Eustatius
                  and Saba, Belize,Cuba, Curacao, Algeria, Ecuador, Eritrea,
                  Spain, Ephiopia, France, South Georgia and the South Sandwich
                  Islands, Ireland, Israel, India, Iraq, Italy, Jordan, Korea -
                  Democratic, United States of America and Territories,
                </p>
                <p>
                  GoldenRace No Customers shall be accepted from Afghanistan,
                  Antigua and Barbuda, Cuba, Kenya, Cyprus, Curacao, Denmark,
                  Italy, France and its territories, Hong Kong, Iran, Iraq,
                  Israel, Kahnawake, Libya, Macau, Netherlands, Antilles, Spain,
                  Sudan, Syria, the Philippines, Turkey, the USA and its
                  territories, the United Kingdom
                </p>
                <p>
                  Habanero No Customers shall be accepted from Cyprus Curacao
                  France Philippines Singapore South Africa Taiwan United States
                  Restricted territories unless licensed: Italy United Kingdom*
                  Bulgaria Colombia Croatia Denmark Estonia Latvia Lithuania
                  Portugal Romania Spain Sweden
                </p>
                <p>
                  HIGH 5 GAMES No Customers shall be accepted from Afghanistan,
                  Algeria, Angola, Antigua & Barbuda, Cambodia, China, Cuba,
                  Cyprus, Guyana, Hong Kong, Indonesia, Iran, Iraq, Israel,
                  Kahnawake, Kuwait, Libya, Macau, Myanmar, Namibia, Netherlands
                  Antilles, North Korea, Pakistan, Papua New Guinea, Sudan,
                  Syria, The Philippines, Turkey, Uganda, United States of
                  America and its Territories, Yemen, USA, Curacao
                </p>
                <p>Igrosoft No Customers shall be accepted from USA, Curacao</p>
                <p>
                  ISoftBet No Customers shall be accepted from United States,
                  France, French Polynesia, Afghanistan, Antigua and Barbuda,
                  Belgium (unless operator has a license), China, Cuba, Curacao,
                  Cyprus, Hong Kong, Iran, Iraq, Israel, Libya, Macau,
                  Netherlands Antilles, Serbia (unless operator has a license),
                  Sudan, Syria, Great Britain
                </p>
                <p>
                  Kiron No Customers shall be accepted from Curacao, USA, India,
                  Italy, Australia, Singapore, South Africa
                </p>
                <p>
                  Leander No Customers shall be accepted from USA, China,
                  France, Israel & Hong KongNo
                </p>
                <p>
                  LiveGames No Customers shall be accepted from USA, Curacao,
                  Italy
                </p>
                <p>
                  Lucky Streak No Customers shall be accepted from Israel and
                  USA, Curacao
                </p>
                <p>
                  MARKOR TECHNOLOGY No Customers shall be accepted from USA,
                  Curacao, Singapore, Israel, Syria, Iraq, Afghanistan, Bosnia
                  and Herzegovina, Guyana, Iran, Lao PDR, Syria, Uganda,
                  Vanuatu, Yemen, Democratic People's Republic of Korea
                </p>
                <p>
                  Medialive No Customers shall be accepted from USA, France,
                  Denmark, Curacao
                </p>
                <p>
                  MicroGaming No Customers shall be accepted from Philippines,
                  France, Singapore, USA, South Africa, Australia, Sweden,
                  Curacao.
                </p>
                <p>
                  Regulated: Belgium, Bulgaria, Denmark, Italy, Latvia,
                  Portugal, Romania, Spain, UK, Germany: Schleswig-Holstein"
                </p>
                <p>Superbranded:</p>
                <p>"Philippines, France, Singapore, USA, South Africa.</p>
                <p>
                  Additional restricted jurisdictions for superbranded game
                  ""Playboy"": Cuba,Iran, Libya, Manmar, North Korea, Sudan,
                  Syria, Canada.
                </p>
                <p>
                  Regulated: Belgium, Bulgaria, Denmark, Italy, Latvia, Romania,
                  Spain, Turkey, UK, Germany: Schleswig-Holstein, Svalbard and
                  Jan Mayen Islands"
                </p>
                <p>
                  mr.Slotty No Customers shall be accepted from United States of
                  America and Territories, and Lithuanian markets, Curacao.
                </p>
                <p>
                  NetEnt 1. Absolute Restriction
                  <ul>
                    <li>
                      United States of America, United Kingdom, Spain, France
                      and Italy.
                    </li>
                  </ul>
                </p>
                <p>
                  2. Blacklisted Territories NetEnt content shall not be served
                  in the following territories: Afghanistan, Albania, Algeria,
                  Angola, Australia, Bahamas, Botswana, Curacao, Ecuador,
                  Ethiopia, Ghana, Guyana, Hong Kong, Iran, Iraq, Israel,
                  Kuwait, Namibia, Nicaragua, North Korea, Pakistan, Panama,
                  Philippines, Singapore, Sudan, Syria, Taiwan, Trinidad and
                  Tobago, Tunisia, Uganda, Yemen, Zimbabwe.
                </p>
                <p>
                  {" "}
                  3. Regulated Territories (only permitted to offer if receipt
                  of a license from the local regulator which has been seen and
                  approved by NetEnt): Belgium, Bulgaria, Czech Republic,
                  Colombia, Croatia, Denmark, Estonia, France, Italy, Latvia,
                  Lithuania, Mexico, Portugal, Romania, Serbia, Spain, Sweden,
                  Switzerland, United Kingdom, United States of America.
                </p>
                <p>
                  {" "}
                  4. Branded Game Territories
                  <ul>
                    <li>
                      {" "}
                      4.1 Jumanji, emojiplanet, Guns & Roses, Jimi Hendrix,
                      Motörhead and Conan cannot be offered in the following
                      territories: Afghanistan, Albania, Algeria, Angola,
                      Australia, Bahamas, Botswana, Curacao, Ecuador, Ethiopia,
                      Ghana, Guyana, Hong Kong, Iran, Iraq, Israel, Kuwait,
                      Namibia, Nicaragua, North Korea, Pakistan, Panama,
                      Philippines, Singapore, Sudan, Syria, Taiwan, Trinidad and
                      Tobago, Tunisia, Uganda, Yemen, Zimbabwe, Belgium,
                      Bulgaria, Czech Republic, Colombia, Croatia, Denmark,
                      Estonia, France, Italy, Latvia, Lithuania, Mexico,
                      Portugal, Romania, Serbia, Spain, Sweden, Switzerland,
                      United Kingdom, United States of America.
                    </li>
                    <li>
                      {" "}
                      4.2 In addition to the jurisdictions set out in paragraph
                      <ul>
                        <li>
                          4.1, Planet of the Apes Video Slot must not be offered
                          in the following territories: Azerbaijan, China,
                          India, Malaysia, Qatar, Russia, Thailand, Turkey,
                          Ukraine.
                        </li>
                      </ul>
                    </li>
                    <li>
                      4.3 Vikings Video Slot must not be offered in the
                      additional jurisdictions: Azerbaijan, Cambodia, Canada,
                      China, France, India, Indonesia, Laos, Malaysia, Myanmar,
                      Papua New Guinea, Qatar, Russia, South Korea, Thailand,
                      Turkey, Ukraine, United States of America{" "}
                    </li>
                    <li>
                      {" "}
                      4.4 In addition to the jurisdictions set out in paragraph
                      <ul>
                        <li>
                          4.1, Narcos Video Slot must not be offered in the
                          following territories: Indonesia, South Korea.
                        </li>
                      </ul>
                    </li>
                    <li>
                      {" "}
                      4.5 In addition to the jurisdictions set out in paragraph
                      4.1, Street Fighter Video Slot must not be offered in the
                      following territories:
                      <ul>
                        <li>
                          Anguilla, Antigua & Barbuda, Argentina, Aruba,
                          Barbados, Bahamas, Belize, Bermuda, Bolivia, Bonaire,
                          Brazil, British Virgin Islands, El Salvador,
                          Greenland, Grenada, Guadeloupe, Guatemala, Haiti,
                          Honduras, Jamaica, Japan, Martinique, Mexico,
                          Montserrat, Navassa Island, Paraguay, Peru, Puerto
                          Rico, Saba, Saint Barthelemy, Saint Eustatius, Saint
                          Kitts and Nevis, Saint Lucia, Saint Maarten, Saint
                          Martin, Saint Pierre and Miquelon, Saint Vincent and
                          the Grenadines, South Korea, Suriname, Turks and
                          Caicos Islands, United States of America, Uruguay, US
                          Virgin Islands, Venezuela.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p>
                  5. Universal Monsters ( Dracula, The Mummy, The Wolf Man,
                  Creature from the Black Lagoon and The Invisible Man), can
                  only be played in the following territories:
                </p>
                <p>
                  Andorra, Armenia, Belarus, Belgium, Bosnia and Herzegovina,
                  Brazil, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark,
                  Estonia, Finland, Georgia, Germany, Greece, Hungary, Iceland,
                  Ireland, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta,
                  Moldova, Monaco,Montenegro, Netherland, Norway, Peru, Poland,
                  Portugal, Romania, Russia, San Marino, Serbia, Slovakia,
                  Slovenia, Sweden, Switzerland.
                </p>
                <p>
                  NoLimit city No Customers shall be accepted from the United
                  States of America, Curacao.
                </p>
                <p>
                  {" "}
                  Oriental No Customers shall be accepted from USA, Curacao{" "}
                </p>
                <p>
                  Pariplay No Customers shall be accepted from Afghanistan,
                  Albania, Algeria, Angola, Australia, The Bahamas, Barbados,
                  Botswana, Cambodia, Congo, Curacao, Cyprus, Ecuador, France
                  and its territories, Ghana, Guyana, Hong Kong, Iran, Iraq,
                  Israel, Jamaica, Latvia, Lithuania, Mauritius, Mongolia,
                  Myanmar, Nicaragua, North Korea, Panama, Philippines, Poland,
                  Saudi Arabia, Singapore, South Africa, South Korea, Sudan,
                  Taiwan, United States of America, Vatican City, Zimbabwe
                </p>
                <p>
                  {" "}
                  Restricted Jurisdictions: Belgium, Bulgaria, Czech Republic,
                  Colombia, Denmark, Estonia, Italy, Malta, Portugal, Romania,
                  Spain, Sweden, United Kingdom.
                </p>
                <p>
                  {" "}
                  Patagonia No Customers shall be accepted from Aruba, Bonaire,
                  Curacao, France, Netherlands, Saba, Singapore, Statia, St
                  Maarten, USA{" "}
                </p>
                <p>
                  PG SOFT No Customers shall be accepted from Singapore,
                  Malaysia, Taiwan, America, Latvia, Curacao.
                </p>
                <p>
                  Pinnacle No Customers shall be accepted from Production
                  environment: UK, US, Singapore
                </p>
                <p>
                  {" "}
                  Platipus No Customers shall be accepted from USA, it's
                  territories and possessions, Israel and Iran, Curacao.
                </p>
                <p>
                  {" "}
                  PlaynGO No Customers shall be accepted from AFGHANISTAN;
                  ALDERNEY; AMERICA SAMOA; ANGOLA; AUSTRALIA; BELGIUM; BOSNIA
                  AND HERZEGOVINA; CAMBODIA; CROATIA; CZECH REPUBLIC; DEMOCRATIC
                  PEOPLE´S REPUBLIC OF KOREA (DPRK); DENMARK; DUTCH CARRIBEAN
                  INCLUDING CURACAO AND ARUBA; ETHIOPIA; HUNGARY; IRAQ; ISLAMIC
                  REPUBLIC OF IRAN; ITALY; LAO´S PEOPLE'S DEMOCRATIC REPUBLIC;
                  LATVIA; LITHUANIA; NETHERLANDS, PORTUGAL, ROMANIA; SINGAPORE;
                  SPAIN; SWEDEN; Switzerland; Serbia; SYRIAN ARAB REPUBLIC;
                  UGANDA; UNITED KINGDOM; USA; VANUATU; YEMEN. Prohibited Games
                  in particular jurisdictions Prohibited Games in Denmark: -
                  HUGO
                </p>
                <p>
                  {" "}
                  Playson + Direct No Customers shall be accepted from USA;
                  Australia; Israel, Curacao.
                </p>
                <p>
                  {" "}
                  PragmaticPlay No Customers shall be accepted from USA, France,
                  Israel, Ukraine, Taiwan, Australia, North Korea, Cyprus,
                  Curacao, Philippines
                </p>
                <p>
                  India states: Nagaland, Sikkim, Telangana, Andhra Pradesh
                  regulated markets:
                  <ul className="subpoints">
                    <li>● Great Britain</li>
                    <li>● Italy</li>
                    <li>● Denmark</li>
                    <li>● Spain</li>
                    <li>● Bahamas</li>
                    <li>● Gibraltar</li>
                    <li>● Sweden</li>
                    <li>● Portugal</li>
                    <li>● Romania</li>
                    <li>● Bulgaria</li>
                    <li>● Lithuania</li>
                    <li>● Serbia</li>
                    <li>● South Africa</li>
                  </ul>
                </p>
                <p>
                  Blocked countries for Peaky Blinders: UK, USA, Hong Kong,
                  Turkey, France Belgium, Spain, Singapore, Iran, Syria, North
                  Korea, Sudan
                </p>
                <p>
                  Quickspin No Customers shall be accepted from Australia,
                  Belgium, Bulgaria, Colombia, Cyprus, Curacao, Denmark*,
                  Estonia, France, Hong Kong, Israel, Italy*, Macau, Poland,
                  Portugal, Romania*, Singapore, Spain, the Czech Republic, the
                  Republic of Turkey, the Philippines, the United Kingdom*, the
                  USA and its territories, and Ukraine, Sweden, Switzerland,
                  Zimbabwe, Sudan, Nigeria, Libya, North Korea, Iran, Iraq,
                  Cuba.
                </p>
                <p>
                  RED RAKE GAMING No Customers shall be accepted from Croatia,
                  Denmark, France, Italy, Portugal, Romania, Spain, UK and Asia
                  (except Turkey, Russia and Armenia), USA, Curacao
                </p>
                <p>
                  Red Tiger Gaming No Customers shall be accepted from
                  Afghanistan, Albania, Algeria, Angola, Australia, Bahamas,
                  Botswana, Bulgaria, Curacao, Ecuador, Ethiopia, Ghana, Guyana,
                  Hong Kong, Iran, Iraq, Israel, Kuwait, Namibia, Nicaragua,
                  North Korea, Pakistan, Panama, Philippines, Singapore, Sudan,
                  Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, Yemen,
                  Zimbabwe, Belgium, Colombia, Croatia, Czech Republic, Denmark,
                  Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal,
                  Romania, Serbia, Spain, Sweden, Switzerland, United Kingdom,
                  United States of America.
                </p>
                <p>
                  Additionally to the first part this list shall only apply to
                  the Game “Reel King Mega”:
                </p>
                <p>
                  Cambodia, China, Cuba, Curacao, France, French Guiana,
                  Guadeloupe, Indonesia, Iran, Iraq, Israel, Ivory Coast,
                  Martinique, North Korea, Pakistan, South Africa, Southern
                  Cyprus, Syria, Thailand, Turkey, United States of America,
                  Vietnam.
                </p>
                <p>
                  ReelNRG For Curacao – We have France, Curacao, Netherlands and
                  USA as restricted.
                </p>
                <p>For MGA/UK Licence:</p>Afghanistan, Algeria, American Samoa,
                Azerbaijan, Bangladesh, Barbados, Bhutan, Brazil, Belarus,
                Cambodia, Cayman Islands, China, Cuba, Cyprus, Ecuador, France,
                Guam, Guatemala, Indonesia, Iran, Iraq, Israel, Japan, Jordan,
                Kuwait, Kyrgyztan, Libya, Maldives, Mali, Mauritania, Oman,
                Pakistan, Palau, Qatar, Russia,Saudi Arabia, Somalia, Sudan,
                Syria, Taiwan, Tuvalu,Ukraine, Uzbekistan, Yemen.
                <p>
                  Relax Gaming No Customers shall be accepted from Algeria,
                  Afghanistan, Antigua & Barbuda, Cuba, Curacao, Guyana,
                  Iran,Iraq, Israel, Kahnawake, Kuwait, Libya, Myanmar, North
                  Korea,{" "}
                </p>
                <p>
                  Pakistan, Panama, Papua New Guinea, Sudan, Syria, Unites
                  States of America and its Territories, Yemen, Zimbabwe
                </p>
                <p>Regulated:</p>
                <p>
                  Belgium, Denmark, Estonia, France and its Territories, Italy,
                  Latvia, Lithuania, Malta, Romania, Spain, Sweden, United
                  Kingdom
                </p>
                <p>
                  Please also note the following additional territories for Push
                  Gaming and Big Time Gaming.
                </p>
                <p>Big Time Gaming:</p>
                <p>Australia</p>
                <p>Canada</p>
                <p>Push Gaming:</p>
                <p>Australia</p>
                <p>
                  SA Gaming No Customers shall be accepted from Philippines,
                  Korea and China, USA, Curacao
                </p>
                <p>
                  Spadegaming No Customers shall be accepted from USA, Israel
                  and Australia
                </p>
                <p>Spigo No Customers shall be accepted from USA, Curacao.</p>
                <p>
                  Spinmatic No Customers shall be accepted from France,
                  Netherlands, USA, Curacao
                </p>
                <p>
                  Spinola No Customers shall be accepted from Australia,
                  American Samoa, Cuba, Curacao, Denmark, France, France
                  (Metropolitan), French Guiana, Hong Kong, India, Iraq, Iran,
                  Korea, Libyan Arab Jamahiriya, Myanmar, Macau, Nothern Mariana
                  Islands, FrenchPolynesia, Sudan, French Southern Territiries,
                  USA, Virgin Islands, Zimbabwe
                </p>
                <p>
                  Spinomenal No Customers shall be accepted from USA, ISR,
                  Australia, UK, Armenia, Ecuador and France, Curacao.
                </p>
                <p>
                  Stakelogic Jurisdictions restricted due to FATF AML
                  restrictions:
                </p>
                <p>
                  Algeria, Myanmar, Afghanistan, North Korea, Antigua & Barbuda,
                  Pakistan, Cuba, Panama, Guyana, Papua New Guinea, Iran, Sudan,
                  Iraq, Syria, Israel, United States of America and its
                  Territories, Curacao, Kahnawake, Yemen, Kuwait, Zimbabwe,
                  Libya
                </p>
                <p>
                  Restricted jurisdictions subject to B2C operator holding the
                  relevant local online gaming license from the competent
                  licensing authority of the specific jurisdiction:
                </p>
                <p>
                  Belgium, Portugal, Denmark, Romania, Estonia, Spain, France
                  and its Territories, Sweden, Italy, Switzerland, Latvia,
                  Lithuania, United Kingdom, Malta, Ukraine.
                </p>
                <p>United States of America: New Jersey, Pennsylvania,</p>
                <p>
                  Thunderkick No Customers shall be accepted from USA, Curacao,
                  France, Australia, Iraq, Iran, Libya, Myanmar, People’s
                  Republic of Korea, Sudan, Zimbawe, Netherlands
                </p>
                <p>
                  Conditional Jurisdictions: Denmark, Canada, Spain, Portugal,
                  Italy, Belgium, Romania, Czech Republic, Sweden
                </p>
                <p>
                  TomHorn No Customers shall be accepted from United States of
                  America and Territories, and Israel, Curacao.
                </p>
                <p>
                  Vivo No Customers shall be accepted from USA, Israel, Costa
                  Rica and Argentina, Curacao.
                </p>
                <p>
                  WAC No Customers shall be accepted from Australia, Curacao,
                  Netherlands and Usa
                </p>
                <p>
                  Wazdan No Customers shall be accepted from Afganistan, Antigua
                  and Barbuda, Armenia, Australia, Belgium, Bulgaria, China,
                  Cuba, Cyprus, Denmark, France and its territories, Hong Kong,
                  Italy, Iran, Iraq, Kahnawake, Libya, Macau, Malasia, the
                  Netherlands, the former Netherlands Antilles, Poland,
                  Portugal, Republic of Serbia, Singapore, Spain, Sudan, Syria,
                  the Philippines, Turkey, the USA and its territories, UK,
                  Curacao.
                </p>
                <p>
                  Worldmatch No Customers shall be accepted from Australia and
                  Usa, Curacao, France.
                </p>
                <p>
                  XPro Gaming No Customers shall be accepted from Israel, USA,
                  Bulgaria, South Korea, Curacao.
                </p>
                <p>
                  Yggdrasil Alderney (unless under a local licence),
                  Afghanistan, American Samoa, Australia (unless under a local
                  licence), Bahamas, Belgium (unless under a local licence),
                  Bulgaria (unless under a local licence), Botswana, Cambodia,
                  Congo, Democratic People’s Republic of Korea (DPRK), Denmark
                  (unless under a local licence), Egypt, Eritrea, Estonia
                  (unless under a local licence), Ethiopia, France (unless under
                  a local licence), French Guyana (.FR), Gibraltar (unless under
                  a local licence), Ghana, Greenland (.DK) (unless under a local
                  licence), Guadaloupe (.FR) (unless under a local licence),
                  Guam, Guinea Bissau, Holy See (Vatican City), Isle of Man
                  (unless under a local licence), Iran, Iraq, Italy (unless
                  under a local licence), Jersey (unless under a local licence),
                  Kuwait, Lebanon, Libya, Malaysia, Malta (unless under a local
                  licence), Marshall Islands, Martinique (.FR), Nicaragua, North
                  Korea, Occupied Palestinian Territory, Pakistan, Panama,
                  Poland, Portugal (unless under a local licence), Puerto Rico,
                  Qatar, Reunion (.FR), Saint Barthelemy (.FR), Saint Martin
                  (.FR), Saint Pierre and Miquelon (.FR), Singapore, Slovakia
                  (unless under a local licence), Spain (unless under a local
                  licence), Somalia, South Africa, South Sudan, Sri Lanka,
                  Sudan, Syria, Sweden (unless under a local licence),
                  Switzerland (unless under a local licence), Taiwan, Trinidad &
                  Tobago, Tunisia, United Kingdom (unless with a local licence),
                  USA, US Minor Outlying Islands, Venezuela, Virgin Islands
                  (U.S.), Yemen, Zimbabwe.
                </p>
                <p>
                  The list of game providers and territories, from where the
                  Customers are not accepted may be amended from time to time.
                </p>
                <h5>7. USERNAME, PASSWORD, PIN and CUSTOMER INFORMATION</h5>
                <p>
                  7.1 After opening Your Account, You must take all reasonable
                  steps to avoid disclosing (whether deliberately or
                  accidentally) Your username, password and/or account number to
                  anyone else, including (where practicable) ensuring that
                  up-to-date security software is downloaded onto Your Access
                  Device.
                </p>
                <p>
                  7.2 All transactions made where Your username and password
                  and/or account number have been entered correctly will be
                  regarded as valid, whether or not authorised by You, and we
                  shall not be liable for any claims in the event that You
                  disclose Your username, password or account number to anyone
                  else (whether deliberately or accidentally).
                </p>
                <p>
                  7.3 If You have lost or forgotten Your Account details, or
                  have a reason to believe that such details are known to an
                  unauthorised third party, please contact us immediately for a
                  replacement through Support@bharatplays.com, details of which
                  can be found on the Contact Us page.
                </p>
                <h5> 8. DEPOSITS, WITHDRAWALS AND REFUNDS FROM YOUR ACCOUNT</h5>
                <p>
                  8.1 If You wish to participate in the Services, You must
                  deposit monies into Your Account from an account or source of
                  which You are the account holder. Such monies may (subject to
                  paragraph 5) then be used by You to place bets or play games.
                  If You use a payment method in respect of which You are not
                  the account holder, we reserve the right to treat any deposit
                  into the Account as being invalid (and any winnings arising
                  from such deposit as void) pending the satisfactory completion
                  of all relevant Checks.
                </p>
                <p>
                  Withdrawals (including – Refunds) shall be provided within 7
                  (seven) days time after completion of KYC and approval of Your
                  Account. Please note, that Refunds are performed only if there
                  was no game activity on Your account after deposit of
                  respective amount of monies that are reclaimed by you as
                  Refund. All other withdrawals from Your Account will be
                  considered as pay-out of your winnings generated as a result
                  of use of the Services.
                </p>
                <p>
                  In order to request a Refund or any other withdrawal, please
                  contact our support by email to support@bharatplays.com and
                  provide all documents as requested by the support in order to
                  complete Your KYC. Should You require Our contact details,
                  please open the “Contact Us” section. List of the documents
                  required to complete Your KYC procedure shall be denoted in
                  the email sent to You by our support.
                </p>
                <p>
                  Please note, that Refunds may be requested no later than 7
                  (seven) days after deposit of respective means to Your
                  Account. After this term all withdrawals shall be considered
                  as pay-out of your winnings generated a result of use of the
                  Services.
                </p>
                <p>
                  8.2 Withdrawals shall be provided within 7 (seven) days time
                  after completion of KYC and approval of Your Account. For the
                  avoidance of doubt Your Account shall not be used by You as a
                  bank account and, should we become aware of deposits into and
                  withdrawals (including charge-back) from Your Account without
                  commensurate betting or gaming activity, we reserve the right
                  to deduct an administration charge (whether or not we close or
                  suspend the account). Monies deposited with us in Your Account
                  shall not attract interest. The time period, when you can
                  expect your withdrawal at your personal bank account, depends
                  on the operation of the respective payment service provider or
                  bank. For avoidance of doubt upon your request we can confirm
                  pay-out of the monies requested by You, should that be
                  necessary.
                </p>
                <p>
                  8.3 To the extent required by Your local law or tax or other
                  authorities You are responsible for reporting Your winnings
                  and losses arising from the Services.
                </p>
                <p>
                  8.4 You can set a deposit limit on Your Account in any one
                  day. For details of how to set up a deposit limit please
                  contact support@bharatplays.com Any confirmed reductions to
                  your deposit limit will take effect immediately.
                </p>
                <p>
                  8.5 Subject to terms of Closure of Your Account or any other
                  terms set out in these Terms and Conditions or any amendments
                  thereof, You may request withdrawal of funds from Your Account
                  at any time provided that:
                  <ul>
                    <li>
                      8.5.1 all payments made into Your Account have been
                      confirmed as cleared and none have been charged-back,
                      reversed or otherwise cancelled;
                    </li>
                    <li>
                      8.5.2 any Checks referred to Verification of Your
                      Identity; Money Laundering Requirements have been
                      completed by us to our satisfaction; and
                    </li>
                    <li>
                      8.5.3 You have complied with any other relevant withdrawal
                      conditions affecting Your Account (e.g. any applicable
                      Bonus Terms).
                    </li>
                  </ul>
                </p>
                <p>
                  8.6 On any withdrawal approved by us, provided that You give
                  us sufficient information as to how the funds should be
                  transferred to You, we will return the relevant funds to You
                  (less charges incurred or any other amount required to be
                  deducted from Your withdrawal in order to comply with any
                  applicable law).
                </p>
                <p>
                  8.7 We will attempt to accommodate Your request regarding the
                  payment method and currency of payment of Your withdrawal.
                  This, however, cannot be guaranteed. Therefore, we may process
                  and pay withdrawals to a different payment method than the one
                  requested by You, such as through different payment providers,
                  a bank draft or wire transfer (any charges associated with
                  relevant payment methods are set out in the Website).
                  Similarly, in certain cases, the currency of Your withdrawal
                  may not be the currency in which Your deposit was made or that
                  was otherwise requested by You and, in circumstances where we
                  are required to convert Your deposits between different
                  currencies, the conversion rate used by us will set upon our
                  discretion.
                </p>
                <p>
                  8.8 Inactive Account Fee. If You do not use Your Account for
                  betting or gaming, making a deposit, withdrawal or transfer,
                  or if it is otherwise inactive, for a period of at least 13
                  consecutive months, then it will be an "Inactive Account". All
                  Inactive Accounts will incur a fee ("Inactive Account Fee"),
                  the details of which can be found in the Help section of the
                  Website. We will notify You when Your Account becomes an
                  Inactive Account, and at least 14 days before any Inactive
                  Account Fee is deducted from it.
                </p>
                <p>
                  8.9 LIMITATIONS:
                  <ul>
                    <li>The min. amount of deposit is 10 EUR.</li>
                    <li>The min. amount of withdrawal is 10 EUR.</li>
                    <li>
                      All payout requests are processed in the order of the
                      queue within 72 hours if the requested amount does not
                      exceed 1,000 EUR or the equivalent in another currency and
                      up to 7 days in case the requested amount exceeds 1,000
                      EUR.
                    </li>
                    <li>
                      Withdrawals are processed without commission. Commission
                      for the deposit depends only on the user’s payment system.
                    </li>
                  </ul>
                </p>
                <p>
                  8.10 PAYMENT SYSTEM RESTRICTED COUNTRIES
                  <ul>
                    <li>
                      8.10.1 Visa / Mastercard We support gaming/gambling
                      payouts via OCT (Original Credit Transfer) from Visa or
                      also via Payment Transfer (Mastercard). Prerequisites for
                      paying out are that an initial pay-in was done and for
                      Mastercard this pay-in needs to have been made via a 3-D
                      Secure MID. Additional requirements are that the
                      respective credit card is not a corporate credit card and
                      the respective card is issued in a country that is
                      supported by the respective card scheme (see below). The
                      per transaction limits are 60,000.00 EUR for Visa and
                      5,000.00 EUR for Mastercard.
                    </li>
                    <li>
                      8.10.2 Visa :- For Visa (OCT) there is a list of countries
                      not supported. List of non-participating countries for
                      OCT:
                      <ul className="subpoints">
                        <li>● USA</li>
                        <li>● Australia</li>
                        <li>● Hongkong</li>
                        <li>● India</li>
                        <li>● Indonesia</li>
                        <li>● Japan</li>
                        <li>● Korea</li>
                        <li>● Malaysia</li>
                        <li>● Singapore</li>
                      </ul>
                    </li>
                    <li>
                      8.10.3 Mastercard Mastercard PT list of countries
                      supported. List of participating countries for PT:
                      <ul className="subpoints">
                        <li>● Andorra</li>
                        <li>● Austria</li>
                        <li>● Belgium</li>
                        <li>● Cyprus</li>
                        <li>● Czech republic</li>
                        <li>● Denmark</li>
                        <li>● Estonia</li>
                        <li>● France</li>
                        <li>● Germany</li>
                        <li>● Gibraltar</li>
                        <li>● Greece</li>
                        <li>● Hungary</li>
                        <li>● Iceland</li>
                        <li>● Ireland</li>
                        <li>● Italy</li>
                        <li>● Latvia</li>
                        <li>● Luxembourg</li>
                        <li>● Malta</li>
                        <li>● Monaco</li>
                        <li>● Netherlands</li>
                        <li>● Norway</li>
                        <li>● San marino</li>
                        <li>● Slovakia</li>
                        <li>● Slovenia</li>
                        <li>● Spain</li>
                        <li>● Sweden</li>
                        <li>● Switzerland</li>
                        <li>● Turkey</li>
                        <li>● United kingdom</li>
                      </ul>
                    </li>

                    <li>
                      8.10.4 Skrill Countries restricted for registration.
                      Residents of this country category can access the
                      websites, but cannot register/sign up for an account:
                      <ul className="subpoints">
                        <li> ● Afghanistan </li>
                        <li>● Angola</li>
                        <li>● Barbados</li>
                        <li>● Benin</li>
                        <li>● Bonaire</li>
                        <li>● Burkina Faso</li>
                        <li>● Cape Verde</li>
                        <li>● Cuba</li>
                        <li>● Comoros</li>
                        <li>● Djibouti</li>
                        <li>● East Timor</li>
                        <li>● Eritrea</li>
                        <li>● Faroe Islands</li>
                        <li>● French Polynesia</li>
                        <li>● Gambia</li>
                        <li>● Greenland</li>
                        <li>● Grenada</li>
                        <li>● Guadeloupe</li>
                        <li>● Guyana</li>
                        <li>● Iran</li>
                        <li>● Iraq</li>
                        <li>● Japan</li>
                        <li>● Kyrgyzstan</li>
                        <li>● Lao People's Democratic Republic</li>
                        <li>● Libya</li>
                        <li>● Macao</li>
                        <li>● Martinique</li>
                        <li>● Namibia</li>
                        <li>● Nauru</li>
                        <li>● New Caledonia</li>
                        <li>● Niger</li>
                        <li>● North Korea</li>
                        <li>● Palau</li>
                        <li>● Reunion</li>
                        <li>● Saint Barthelemy</li>
                        <li>● Saint Martin (Sint Maarten)</li>
                        <li>● Samoa</li>
                        <li>● South Sudan</li>
                        <li>● Sudan</li>
                        <li>● Suriname</li>
                        <li>● Syria</li>
                        <li>● Tajikistan</li>
                        <li>● Togo</li>
                        <li>● Turkmenistan</li>
                        <li>● US Minor Outlying Islands</li>
                      </ul>
                    </li>

                    <li>
                      8.10.5 Skrill Banned countries. Residents of this country
                      category cannot register an account, nor access the Skrill
                      domains:
                      <ul className="subpoints">
                        <li> ● Afghanistan</li>
                        <li>● Cuba</li>
                        <li>● Eritrea</li>
                        <li>● Iran</li>
                        <li>● Iraq</li>
                        <li>● Japan</li>
                        <li>● Kyrgyzstan</li>
                        <li>● Libya</li>
                        <li>● North Korea</li>
                        <li>● South Sudan</li>
                        <li>● Sudan</li>
                        <li>● Syria</li>
                      </ul>
                    </li>

                    <li>
                      8.10.6 Skrill Restricted for Gaming and Binary:
                      <ul className="subpoints">
                        <li>
                          ● American Samoa (relevant state licenses required)
                        </li>
                        <li>● Brazil</li>
                        <li>● Canada</li>
                        <li>● China (Pr)</li>
                        <li>● Guam (relevant state licenses required)</li>
                        <li>● Hong Kong</li>
                        <li>● Israel</li>
                        <li>● Macao</li>
                        <li>
                          ● Puerto Rico (relevant state licenses required)
                        </li>
                        <li>
                          ● Northern Mariana Island (relevant state licenses
                          required)
                        </li>
                        <li>● Singapore</li>
                        <li>● Turkey</li>
                        <li>● United Arab Emirates</li>
                        <li>● United States Of America</li>
                        <li>● US Minor Outlying Islands</li>
                        <li>● US Virgin Islands</li>
                      </ul>
                    </li>

                    <li>
                      8.10.7 Neteller Does not provide service to the following
                      countries:
                      <ul className="subpoints">
                        <li>● Afghanistan</li>
                        <li>● Benin</li>
                        <li>● Bonaire</li>
                        <li>● Burundi</li>
                        <li>● Central African Republic</li>
                        <li>● Chad</li>
                        <li>● China</li>
                        <li>● Congo Republic</li>
                        <li>● Crimea</li>
                        <li>● Cuba</li>
                        <li>● Djibouti</li>
                        <li>● Equatorial Guinea</li>
                        <li>● Eritrea</li>
                        <li>● Gabon</li>
                        <li>● Gambia</li>
                        <li>● Guinea</li>
                        <li>● Guinea-Bissau</li>
                        <li>● Guyana</li>
                        <li>● Iran</li>
                        <li>● Iraq</li>
                        <li>● Kazakhstan</li>
                        <li>● Kyrgyzstan</li>
                        <li>● Lao People's Democratic Republic</li>
                        <li>● Liberia</li>
                        <li>● Libya</li>
                        <li>● Madagascar</li>
                        <li>● Malawi</li>
                        <li>● Mali</li>
                        <li>● Mauritania</li>
                        <li>● Mongolia</li>
                        <li>● Montserrat</li>
                        <li>● Myanmar</li>
                        <li>● Nauru</li>
                        <li>● Niger</li>
                        <li>● Niue</li>
                        <li>● Norfolk Island</li>
                        <li>● North Korea</li>
                        <li>● Palau</li>
                        <li>● Papua New Guinea</li>
                        <li>● Saint Barthelemy</li>
                        <li>● Saint Kitts and Nevis</li>
                        <li>● Saint Martin</li>
                        <li>● Sint Maarten</li>
                        <li>● Sierra Leone</li>
                        <li>● Sudan (North and South)</li>
                        <li>● Suriname</li>
                        <li>● Syria</li>
                        <li>● Tajikistan</li>
                        <li>● Timor-Leste</li>
                        <li>● Togo</li>
                        <li>● Turkmenistan</li>
                        <li>● Uzbekistan</li>
                        <li>● Western Sahara</li>
                        <li>● Yemen</li>
                      </ul>
                    </li>

                    <li>
                      8.10.8 Neteller Requires a local license for specific
                      countries:
                      <ul className="subpoints">
                        <li>● Australia</li>
                        <li>● Belgium</li>
                        <li>● France</li>
                        <li>● Germany</li>
                        <li>● Japan</li>
                        <li>● Spain</li>
                        <li>● Turkey</li>
                        <li>● UK</li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <h5>9. BONUSES</h5>
                <p>
                  9.1. bharatplays reserves the right to remove, add or change
                  any bonus that is available in the account of any Customer and
                  that has not been claimed. Any claimed and active bonus will
                  not be removed by bharatplays under regular circumstances.
                </p>
                <p>
                  9.2. Bonuses might have wagering requirements attached to
                  them. These requirements will be appropriately displayed
                  before the Customer decides to use a bonus when wagering
                  requirements are applicable. Withdrawal amounts will be only
                  possible for the real money balance the bonus money balance
                  upon withdrawal will be lost. bharatplays has the right to
                  choose which game and service contributes what amount to the
                  wagering requirements. The wagering requirements have a
                  minimum of 25 times the amount of bonus and the real money sum
                  contributing to the bonus figure.
                </p>
                <p>
                  9.3. Bonuses can be received once per person (Customer), per
                  household, per address, per mobile phone, per shared computer
                  and per shared IP address. Risk-free bets on any games or
                  services do not count toward the wagering requirements.
                  Winnings from free spins are added to the real money balance.
                </p>
                <p>
                  9.4. The maximum amount of money to be won from a no deposit
                  free spin bonus and gameplay with mentioned bonus funds is 50
                  EUR or any other currency equivalent. Any winnings exceeding
                  50 EUR without making a real money deposit will be void and
                  will be kept by bharatplays. Example, a customer receives a no
                  deposit free spins bonus. The free spins get played out and
                  the customer wins 10 EUR. The customer proceeds to play other
                  games and wins 90 EUR, totaling 100 EUR in the bharatplays
                  cashier. In this case the customer has exceeded the cap of 50
                  EUR winnings, 50 EUR will be available for withdrawal and 50
                  EUR will be kept by bharatplays.
                </p>
                <p>
                  9.5. The maximum bet when having an active bonus is EUR 5 per
                  spin or EUR 0.5 per bet line.bharatplays reserves the right to
                  void bets and wager turnovers resulting from larger bets made
                  on games and/or services of bharatplays.
                </p>
                <p>
                  9.6. bharatplays reserves the right to impose various
                  restrictions to different countries where Customers may reside
                  regarding bonuses for reasons undisclosed. We further reserve
                  the right to offer specific bonuses to individual customers or
                  customer groups and their termination, change or other
                  alteration without explanation and/or compensations in any
                  form.
                </p>
                <p>
                  9.7. If a player or a group of players are abusing casino
                  bonuses, #(CasinoName) has the right to confiscate any wins
                  thereof and to close the player’s account(s). Abusive
                  behaviour towards bonuses and/or bonus abuse means:
                  <ul>
                    <li>
                      {" "}
                      i) A Player places bets and/or wagers amounting to the
                      maximum allowed per bonus terms to raise the variance in
                      order increase the balance
                    </li>
                    <li>
                      {" "}
                      ii) Player lowers the bet and/or wager size after a big
                      win and changes the game to one with a lower volatility
                    </li>
                    <li>
                      {" "}
                      iii) Player makes deposits to cap out the bonus amount but
                      not more. Applicable to match bonuses of 50% and above
                    </li>
                  </ul>
                  After the account has been flagged as bonus abusing it is
                  forbidden to use any other bonuses and/or participate in
                  promotions at bharatplays.com.[3]
                </p>
                <p>
                  9.8 In our casino any player is eligible to receive any amount
                  of Free Spins. Free Spins amount and availability are
                  determined by casino administrator. Free Spins - is a slot
                  spin in which the player's balance is not charged for any
                  money.
                </p>
                <p>
                  9.9 Winnings received as a result of Free Spins are credited
                  to the player’s bonus balance. Free Spins winnings have to be
                  wagered. Wager requirements varies from x5 to x35, applied to
                  the whole sum. Wagering must be done using only Real money
                  balance. You can wager a bonus in every slot eligible for
                  this. You are able to withdraw money only after all wagering
                  requirements are complete. If during the withdrawal you have
                  any bonus money that are not completely wagered, all bonus
                  money will be cancelled.
                </p>
                <h5>10. LEGAL USE OF THE WEBSITE</h5>
                <p>
                  10.1 Access to or use of the Website or any of the Services
                  via the Website may not be legal for some or all residents of
                  or persons in certain countries. We do not intend that the
                  Website should be used for betting, gaming or any other
                  purposes by persons in countries in which such activities are
                  illegal, which includes the United States of America and those
                  territories listed in the Website. The fact that the Website
                  is accessible in any such country, or appears in the official
                  language of any such country shall not be construed as a
                  representation or warranty with respect to the legality or
                  otherwise of the access to and use of the Website, and the
                  making of deposits or receipt of any winnings from Your
                  Account. The availability of the Website does not constitute
                  an offer, solicitation or invitation by us for the use of or
                  subscription to betting, gaming or other services in any
                  jurisdiction in which such activities are prohibited by law.
                </p>
                <p>
                  10.2 It is Your responsibility to determine the law that
                  applies in the location in which You are present. You should
                  ensure that You will be acting legally in Your jurisdiction in
                  opening Your Account and/or using the Website and You
                  represent, warrant and agree that You will do so.
                </p>
                <p>
                  10.3 If it becomes apparent to us that You are a resident in a
                  country in which the use of the Website is not legal or You
                  are using the Website from a country in which the use of the
                  Website is not legal, we shall be entitled to immediately
                  close Your Account, in which case any balance on the Account
                  on the date of such closure will be refunded to You as soon as
                  it is practicable for us to do so.
                </p>
                <h5>11. PLACING YOUR BET AND/OR GAMING USING THE SERVICES</h5>
                <p>
                  11.1 In order to place a bet or access a Service you should
                  follow the instructions provided at the respective section of
                  the Website.
                </p>
                <p>
                  11.2 It is Your responsibility to ensure that the details of
                  any bet, stake or similar transaction that you place using the
                  Services (a "Transaction") are correct when using the Website
                  (either directly, through an application or otherwise) in
                  accordance with the relevant Game Rules, as appropriate.
                </p>
                <p>
                  11.3 Your Transaction history can be accessed by you, by
                  clicking My Account on the Website, or through our Customer
                  Services team (including by opting to receive a written
                  statement).
                </p>
                <p>
                  11.4 We reserve the right to refuse the whole or part of any
                  Transaction requested by You at any time in our sole
                  discretion, or where You have breached the Terms of Use. No
                  Transaction is accepted by us until You have given the
                  appropriate confirmation (or it has otherwise been accepted by
                  us) in accordance with paragraph 11.2. If You are in any doubt
                  as to whether a Transaction has been accepted successfully,
                  You should contact support@bharatplays.com
                </p>
                <p>
                  11.5 Once a Transaction has been accepted by us, You cannot
                  cancel the transaction unless we agree otherwise.
                </p>
                <p>
                  11.6 In respect of gaming, the relevant Game Rules shall set
                  out the point at which no further stakes or bets will be
                  accepted by us.
                </p>
                <p>
                  11.7 We may cancel or amend a Transaction due to Collusion,
                  Cheating, Fraud and Criminal Activity, Errors or Omissions, as
                  well as because of the terms set out for Verification of Your
                  Identity.
                </p>
                <h5>12. REMOTE GAMING OR BETTING</h5>
                <p>
                  12.1 Where You are accessing the Services via an electronic
                  form of communication You should be aware that:
                  <ul>
                    <li>
                      12.1.1 in relation to Your use of the Website for the
                      placing of bets or playing of games:
                      <ul className="subpoints">
                        <li>
                          12.1.1.1 You may be using a connection or equipment
                          which is slower than such equipment used by others and
                          this may affect Your performance in time critical
                          events offered via the Website;
                        </li>
                        <li>
                          12.1.1.2 You may encounter system flaws, faults,
                          errors or service interruption which will be dealt
                          with in accordance with IT Failure as below;
                        </li>
                        <li>
                          12.1.1.3 the Game Rules for each event or game offered
                          via the Website are available and should be considered
                          by You prior to Your use of the Services offered via
                          the Website.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <h5>13. COLLUSION, CHEATING, FRAUD AND CRIMINAL ACTIVITY</h5>
                <p>
                  13.1 The following practices (or any of them) in relation to
                  the Services:
                  <ul className="subpoints">
                    <li>● abuse of bonuses or other promotions</li>
                    <li>
                      ● and/or using unfair external factors or influences
                      (commonly known as cheating);
                    </li>
                    <li>
                      ● and/or taking unfair advantage (as defined below);
                    </li>
                    <li>● opening any Duplicate Accounts;</li>
                    <li>
                      ● and/or undertaking fraudulent practice or criminal
                      activity (as defined below), constitute{" "}
                    </li>
                  </ul>
                </p>
                <p>
                  13.2 If You suspect a person is engaged in any Prohibited
                  Practice, You shall as soon as reasonably practicable report
                  it to us by e-mailing us or calling Customer Services.
                </p>
                <p>
                  13.3 You agree that You shall not participate in or be
                  connected with any form of Prohibited Practice in connection
                  with Your access to or use of the Services.
                </p>
                <p>
                  13.4 If:
                  <ul>
                    <li>
                      13.4.1 we have reasonable grounds to believe that You have
                      participated in or have been connected with any form of
                      Prohibited Practice (and the basis of our belief shall
                      include the use by us (and by our gaming partners and our
                      other suppliers) of any fraud, cheating and collusion
                      detection practices which are used in the gambling and
                      gaming industry at the relevant time); or
                    </li>
                    <li>
                      13.4.1 we have reasonable grounds to believe that You have
                      participated in or have been connected with any form of
                      Prohibited Practice (and the basis of our belief shall
                      include the use by us (and by our gaming partners and our
                      other suppliers) of any fraud, cheating and collusion
                      detection practices which are used in the gambling and
                      gaming industry at the relevant time); or
                    </li>
                    <li>
                      13.4.2 You have placed bets and/or played online games
                      with any other online provider of gambling services and
                      are suspected (as a result of such play) of any Prohibited
                      Practice or otherwise improper activity; or
                    </li>
                    <li>
                      13.4.3 we become aware that You have "charged back" or
                      denied any of the purchases or deposits that You made to
                      Your Account; or
                    </li>
                    <li>
                      13.4.4 in our reasonable opinion your continued use of the
                      Services may be detrimental to our regulated status,
                      including our continued ability to be licensed by the
                      Licensing Authority; or
                    </li>
                    <li>
                      13.4.5 You become bankrupt or suffer analogous proceedings
                      anywhere in the world, then, (including in connection with
                      any suspension and/or termination of Your Account) we
                      shall have the right, in respect of Your Account (and/or
                      any other account held by You with an Operator Group
                      company) to withhold the whole or part of the balance
                      and/or recover from the account the amount of any
                      deposits, pay-outs, bonuses or winnings which have been
                      affected by or are in any way attributable to any of the
                      event(s) contemplated in this paragraph 13.4. The rights
                      set out in this paragraph 13.4 are without prejudice to
                      any other rights (including any common law rights) that we
                      may have against You, whether under the Terms of Use or
                      otherwise.
                    </li>
                  </ul>
                </p>
                <p>
                  13.5 For the purposes of the paragraph 12:
                  <ul>
                    <li>
                      13.5.1 "fraudulent practice" means any fraudulent activity
                      engaged in by You or by any person acting on Your behalf
                      or in collusion with You, and shall include, without
                      limitation: (a) fraudulent charge-backs and rake-back
                      activity; (b) the use by You or any other person who was
                      participating in the same game as You at any time, of a
                      stolen, cloned or otherwise unauthorised credit or debit
                      card, as a source of funds; (c) the collusion by You with
                      others in order to gain an unfair advantage (including
                      through bonus schemes or similar incentives offered by
                      us); (d) any attempt to register false or misleading
                      account information; and (e) any actual or attempted act
                      by You which is reasonably deemed by us to be illegal in
                      any applicable jurisdiction, made in bad faith, or
                      intended to defraud us and/or circumvent any contractual
                      or legal restrictions, regardless of whether such act or
                      attempted act actually causes us any damage or harm;
                    </li>
                    <li>
                      13.5.2 "criminal activity" shall include, without
                      limitation, money laundering and any offence under any law
                      or regulation in Your country, where you are playing from
                      or where your IP has been registered; and
                    </li>
                    <li>
                      13.5.3 "unfair advantage" shall include, without
                      limitation:
                      <ul>
                        <li>
                          13.5.3.1 the exploitation of a fault, loophole or
                          error in our or any third party's software used by You
                          in connection with the Services (including in respect
                          of any game);
                        </li>
                        <li>
                          13.5.3.2 the use of third party software or analysis
                          systems; or
                        </li>
                        <li>
                          13.5.3.3 the exploitation by You, of an Error as
                          defined below, in any case either to Your advantage
                          and/or to the disadvantage of us or others.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p>
                  13.6 In exercising any of our rights under paragraph 12.4 in
                  relation to a Prohibited Practice, we shall use all reasonable
                  endeavours to ensure that, while complying with our regulatory
                  and other legal obligations, we exercise such rights in a
                  manner which is fair to You and to our other customers.
                </p>
                <p>
                  13.7 We reserve the right to inform relevant authorities,
                  other online gaming or gambling operators, other online
                  service providers and banks, credit card companies, electronic
                  payment providers or other financial institutions of Your
                  identity and of any suspected Prohibited Practice by You, and
                  You shall cooperate fully with us to investigate any such
                  activity.
                </p>
                <h5>
                  14. CLOSURE OF YOUR ACCOUNT; TERMINATION OF THE TERMS OF USE
                  CLOSURE AND TERMINATION BY YOU
                </h5>
                <p>
                  14.1 Provided that Your Account does not show that a balance
                  is due to us, You are entitled to close Your Account and
                  terminate the Terms of Use on not less than twenty four hours'
                  notice to us at any time, by contacting us through
                  support@bharatplays.com:
                  <ul>
                    <li>
                      14.1.1 indicating Your wish to close Your Account; and
                    </li>
                    <li>
                      14.1.2 stating the reasons why You wish to close Your
                      Account, in particular if You are doing so because of
                      concerns over the level of Your use of the same. We will
                      respond to Your request, confirming closure of Your
                      Account and the date on which such closure will be
                      effective, within a reasonable time, provided that You
                      continue to assume responsibility for all activity on Your
                      Account until such closure has been carried out by us (at
                      which point the Terms of Use shall terminate).
                    </li>
                  </ul>
                </p>
                <p>
                  14.2 When You request closure of Your Account under paragraph
                  14.1 we will, subject to paragraph 13.3, return any
                  outstanding balance in Your Account to You.
                </p>
                <p>
                  14.3 Upon any termination of Your Account under this paragraph
                  14 we shall be entitled (without limiting our rights under
                  paragraph 14.6) to withhold, from the repayment of the
                  outstanding balance on Your Account, any monies: (a) pursuant
                  to Collusion, Cheating, Fraud and Criminal Activity); (b)
                  pursuant to Breach of the Terms of Use; (c) as otherwise
                  provided by the Terms of Use; or (d) as required by law or
                  regulation
                </p>
                <p>
                  14.4 When repaying the outstanding balance on Your Account, we
                  shall use the same method of payment which You provided upon
                  registration of Your Account, or another payment method, that
                  we may reasonably select.
                </p>
                <p>
                  14.5 Where You have closed Your Account, we may in certain
                  circumstances be able to re-open Your Account with the same
                  account details as before, if You request us to do so. In such
                  circumstances, while Your Account will have the same account
                  details as before, it will be subject to the Terms of Use
                  which are in force at the date of any such re-opening and any
                  prior entitlements (including, but without limitation, to
                  bonuses or contingent winnings) will no longer be valid.
                </p>
                <p>CLOSURE AND TERMINATION BY US</p>
                <p>
                  14.6 We are, at any time (and notwithstanding any other
                  provisions contained in the Terms of Use), entitled to close
                  Your Account and terminate the Terms of Use on written notice
                  (or attempted notice) to You using Your Contact Details. In
                  the event of any such termination by us we shall, subject to
                  paragraph 14.7, as soon as reasonably practicable following a
                  request by You, refund the balance of Your Account.
                </p>
                <p>
                  14.7 Where we close Your Account and terminate the Terms of
                  Use pursuant to Collusion, Cheating, Fraud and Criminal
                  Activity or Breach of the Terms of Use, the balance of Your
                  Account will be non-refundable and deemed to be forfeited by
                  You to the extent of any claim that we may have against You as
                  at the date of such closure (whether under Your Account, a
                  Duplicate Account or otherwise). Closure of Your Account and
                  Termination of the Terms of Use, other than pursuant to
                  paragraphs 12 or 21 of these General Terms, will not affect
                  any outstanding bets, provided that such outstanding bets are
                  valid and You are not in breach of the Terms of Use in any
                  way. For the avoidance of doubt, we will not credit any
                  bonuses into Your Account, nor will You be entitled to any
                  contingent winnings, at any time after the date on which it
                  has been closed (whether by us pursuant to the Terms of Use,
                  or in response to Your request).
                </p>
                <p>
                  14.8 The following paragraphs shall survive any termination of
                  the Terms of Use: 20, 21, 22, 23, 24, 26, 27, 29, 30, 31, 32,
                  33 and 35 and any other paragraphs which are required for the
                  purposes of interpretation; together with any relevant
                  sections of the Game Rules, the Privacy Policy and the
                  Additional Terms.
                </p>
                <p>SUSPENSION BY US</p>
                <p>
                  14.9 We shall be entitled to suspend Your Account in the
                  circumstances expressly set out in the Terms of Use. Upon the
                  suspension of Your Account: (a) no activity shall be permitted
                  (including deposits, withdrawals, betting or gaming) until the
                  date upon which it is re-activated by us; (b) no bonuses or
                  contingent winnings will be credited to the Account; and (c)
                  we shall address the issue that has given rise to the Account
                  suspension with a view to resolving it as soon as reasonably
                  practicable so that the Account can, as appropriate, either be
                  re-activated or closed.
                </p>
                <h5>15. ACCESS TO, AND USE OF, THE SERVICES</h5>
                <p>
                  15.1 You are solely responsible for the supply and maintenance
                  of all of Your Access Devices and related equipment, networks
                  and internet access services that You need to use in order to
                  access the Services. We will not be liable in any way
                  whatsoever for any losses caused to You (whether resulting
                  from loss of service, poor internet connectivity, insufficient
                  bandwidth or otherwise) by the internet or any
                  telecommunications service provider that You have engaged in
                  order to access the Services. For the avoidance of doubt, the
                  Operator does not make any representation or give any warranty
                  as to the compatibility of the Services with any particular
                  third party software or hardware, including (for the avoidance
                  of doubt) third party analysis which promise certain results
                  from any of the Services.
                </p>
                <p>
                  15.2 Under no circumstances should You use the Services for
                  any purpose which is or is likely to be considered to be
                  defamatory, abusive, obscene, unlawful, of a racist, sexist or
                  of another discriminatory nature, or which could cause
                  offence. You must not use any abusive or aggressive language
                  or images, swear, threaten, harass or abuse any other person,
                  including other users, via the Website, or attempt to pass
                  Yourself off as being any other person, or behave in such a
                  manner towards any Operator staff used to provide the
                  Services, Customer Services, or any helpdesk or support
                  function which we make available to You.
                </p>
                <p>
                  15.3 You shall use the Website for personal entertainment only
                  and shall not be allowed to provide access or reproduce the
                  Website or any part of it in any form whatsoever without our
                  express consent, including creating links to it.
                </p>
                <p>
                  15.4 Any material (other than Software under paragraph 17)
                  downloaded by You from the Website shall be downloaded
                  entirely at Your own risk and the Operator shall not be liable
                  in respect of any loss of data or other damage caused by any
                  such download.
                </p>
                <p>
                  15.5 Where we have reason to believe that Your use of the
                  Services is in breach of any of paragraphs 15.2, 15.3, 15.4 we
                  shall, without prejudice to any of our other rights, be
                  entitled forthwith to remove from the Website any offending
                  content
                </p>
                <h5>16. BETTING AND GAMING TERMS</h5>
                <p>
                  16.1 Expressions used in the betting and gaming industry are
                  numerous. Should You have any doubt as to the meaning of any
                  expression, You should:
                  <ul>
                    <li>
                      {" "}
                      16.1.1 look up its meaning in relating to the event or
                      game You are betting or gaming on;
                    </li>
                    <li>
                      {" "}
                      16.1.2 if You are still in any doubt, contact
                      support@bharatplays.com for clarification; and 16.1.3 not
                      place any bet or game on any event until its meaning is
                      understood to Your satisfaction, because we cannot accept
                      any responsibility if You place a bet or game via the
                      products offered via the Services in circumstances where
                      You do not understand any of the terms involved in or
                      relating to the bet or game.
                    </li>
                  </ul>
                </p>
                <h5>17. ALTERATION OF THE WEBSITE</h5>
                <p>
                  We may, at our absolute discretion, alter or amend any product
                  or service (including any prices offered) available through
                  the Website at any time for the purpose of ensuring the
                  ongoing provision of the Website, but without prejudice to any
                  games and/or bets already in progress at the time of such
                  amendment. From time to time, we may restrict You from
                  accessing some parts of the Website for the purpose of
                  maintenance of the Website and/or alteration or amendment of
                  any of the games and/or products available through the
                  Website.
                </p>
                <h5>18. THIRD PARTY SOFTWARE</h5>
                <p>
                  18.1 In order to use the products offered through the
                  Services, You may be required to download and install software
                  supplied by third parties on to Your Access Device
                  ("Software"). Software may include, but is not limited to:
                  Access Device applications, our downloaded Casino and Poker
                  products and any promotional, marketing and/or facility
                  applications, products and software.
                </p>
                <p>
                  18.2 In such circumstances, You may be required to enter into
                  a separate agreement with the owner or licensor of such
                  Software in respect of Your use of the same (a "Third Party
                  Software Agreement"). In case of any inconsistency between the
                  Terms of Use and any Third Party Software Agreement, the Terms
                  of Use will prevail in so far as the inconsistency relates to
                  the relationship between You and the Operator.
                </p>
                <p>
                  18.3 It is Your responsibility to ensure that any Software is
                  downloaded onto Your Access Device in a manner compatible with
                  Your own Access Device's specific set-up. For the avoidance of
                  doubt, we shall not be liable to the extent that the incorrect
                  downloading of any Software has an adverse effect on the
                  operation of Your Access Device.
                </p>
                <p>
                  18.4 Notwithstanding that the Services provided via any Access
                  Device application shall be subject to the Terms of Use, the
                  terms under which any application ("App") is downloaded or
                  installed onto Your Access Device shall be governed by the
                  agreement entered into between You and the supplier of the
                  relevant App, but in case of any inconsistency between the
                  Terms of Use and any such agreement, the Terms of Use will
                  prevail in so far as the inconsistency relates to the
                  relationship between You and the Operator.
                </p>
                <h4>19. IT FAILURE</h4>
                <p>
                  Where problems occur in the software or hardware used by us to
                  provide the Services we will take all reasonable steps to
                  remedy the problem as soon as reasonably practicable. Where
                  such problems cause a game to be interrupted in circumstances
                  where it cannot be restarted from exactly the same position
                  without any detriment to You or other players, we will take
                  all reasonable steps to treat You in a fair manner (which may
                  include reinstating the balance on Your Account to the
                  position existing following completion of the last bet or game
                  logged on the Operator's server immediately prior to the
                  occurrence of the problem).
                </p>
                <h5>20. ERRORS OR OMISSIONS</h5>
                <p>
                  20.1 A number of circumstances may arise where a bet or wager
                  is accepted, or a payment is made, by us in error. A
                  non-exhaustive list of such circumstances is as follows:
                  <ul>
                    <li>
                      20.1.1 where we mis-state any odds or terms of a bet or
                      gaming wager to You as a result of obvious error or
                      omission in inputting the information or setting up a
                      market, or as a result of a computer malfunction;
                    </li>
                    <li>
                      20.1.2 where we have made a 'palpable error'. A palpable
                      error occurs where:
                      <ul>
                        <li>
                          20.1.2.1 in relation to bets placed prior to an event
                          taking place, the prices/terms offered are materially
                          different from those available in the general market;
                          or
                        </li>
                        <li>
                          20.1.2.2 in relation to any event, the price/terms
                          offered at the time the bet is placed are clearly
                          incorrect given the probability of the event
                          occurring;
                        </li>
                        <li>
                          20.1.3 where we have continued to accept bets on a
                          market which should have been suspended, including
                          where the relevant event is in progress (except where
                          'in-running' bets are accepted) or had already
                          finished (sometimes referred to as 'late bets');
                        </li>
                        <li>
                          20.1.4 where an error has been made as a result of a
                          Prohibited Practice;
                        </li>
                        <li>
                          20.1.5 where we should not have accepted, or have the
                          right to cancel or re-settle, a bet pursuant to the
                          Betting Rules (for example due to 'Related
                          Contingencies');
                        </li>
                        <li>
                          20.1.6 where an error is made by us as to the amount
                          of winnings/returns that are paid to You, including as
                          a result of a manual or computer input error; or
                        </li>
                        <li>
                          20.1.7 where an error has been made by us as to the
                          amount of free bets and/or bonuses that are credited
                          to Your Account,
                        </li>
                        <li>
                          {" "}
                          such circumstances being referred to as an "Error"
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p>
                  20.2 We reserve the right to:
                  <ul>
                    <li>
                      20.2.1 correct any Error made on a bet placed and
                      re-settle the same at the correct price or terms which
                      were available or should have been available through the
                      Operator (absent the publishing error) at the time that
                      the bet was placed and the bet will be deemed to have
                      taken place on the terms which were usual for that bet; or
                    </li>
                    <li>
                      20.2.2 where it is not reasonably practicable to correct
                      and re-settle under 20.2.1 above, to declare the bet void
                      and return Your stake into Your Account; or
                    </li>
                    <li>
                      20.2.3 in circumstances where the Error has resulted from
                      a Prohibited Practice, to take the steps set out in the
                      Terms of Use.
                    </li>
                  </ul>
                </p>
                <p>
                  20.3 Any monies which are credited to Your Account, or paid to
                  You as a result of an Error shall be deemed to be held by You
                  on trust for us and shall be immediately repaid to us when a
                  demand for payment is made by us to You. Where such
                  circumstances exist, if You have monies in Your Account we may
                  reclaim these monies from Your Account pursuant Terms of Use.
                  We agree that we shall use all reasonable endeavours to detect
                  any Errors and inform You of them as soon as reasonably
                  practicable.
                </p>
                <p>
                  20.4 Neither we (including our employees or agents) nor our
                  partners or suppliers shall be liable for any loss, including
                  loss of winnings, that results from any Error by us or an
                  error by You.
                </p>
                <p>
                  20.5 You shall inform us as soon as reasonably practicable
                  should You become aware of any Error.
                </p>
                <p>
                  20.6 Where You have used monies which have been credited to
                  Your Account or awarded to You as a result of an Error to
                  place subsequent bets or play games, we may cancel such bets
                  and/or withhold any winnings which You may have won with such
                  monies, and if we have paid out on any such bets or gaming
                  activities, such amounts shall be deemed to be held by You on
                  trust for us and You shall immediately repay to us any such
                  amounts when a demand for repayment is made by us to You.
                </p>
                <h5>21. EXCLUSION OF OUR LIABILITY</h5>
                <p>
                  21.1 Your access to and use of the Services is at Your sole
                  option, discretion and risk. We shall not be liable for any
                  attempts by You to use the Services by methods, means or ways
                  not intended by us.
                </p>
                <p>
                  21.2 We will provide the Services with reasonable skill and
                  care and substantially as described in the Terms of Use. We do
                  not make any other promises or warranties regarding the
                  Services, or any products or services forming a part of the
                  Services, and hereby exclude (to the extent permitted by law)
                  all implied warranties in respect of the same (including
                  implied warranties as to satisfactory quality and/or fitness
                  for Your purpose). In particular, we do not warrant that the
                  Website will have uninterrupted availability or that it will
                  be free of bugs, viruses or other errors.
                </p>
                <p>
                  21.3 WE (INCLUDING OUR GROUP COMPANIES, PAYMENT PROCESSOR,
                  AFFILIATES, OFFICERS, DIRECTORS, AGENTS AND EMPLOYEES) SHALL
                  NOT BE LIABLE TO YOU, WHETHER SUCH LIABILITY ARISES IN
                  CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, IN RESPECT
                  OF ANY:
                  <ul>
                    <li>21.3.1 LOSS OF DATA;</li>
                    <li>21.3.2 LOSS OF PROFITS;</li>
                    <li>21.3.3 LOSS OF REVENUE;</li>
                    <li>21.3.4 LOSS OF BUSINESS OPPORTUNITY;</li>
                    <li>21.3.5 LOSS OF OR DAMAGE TO GOODWILL OR REPUTATION;</li>
                    <li>21.3.6 BUSINESS INTERRUPTION; OR</li>
                    <li>
                      21.3.7 ANY INDIRECT, SPECIAL OR CONSEQUENTIAL LOSS OR
                      DAMAGE, EVEN WHERE SUCH LOSS OR DAMAGE HAS BEEN NOTIFIED
                      TO US AS BEING POSSIBLE, ARISING OUT OF THE TERMS OF USE
                      OR ANY USE WHATSOEVER BY YOU OF THE SERVICES.
                    </li>
                  </ul>
                </p>
                <h5>22. BREACH OF THE TERMS OF USE</h5>
                <p>
                  22.1 You will fully indemnify, defend and hold us and our
                  officers, directors, employees, agents, contractors and
                  suppliers harmless from and against any and all losses, costs,
                  expenses, claims, demands, liabilities and damages (including
                  legal fees), however caused, that may arise, whether or not
                  reasonably foreseeable, as a result of or in connection with:
                  <ul>
                    <li>
                      22.1.1 the access to and use of the Services by You or by
                      anyone else using Your username and password; and/or
                    </li>
                    <li>
                      22.1.2 any breach by You of any of the terms and
                      provisions of the Terms of Use.
                    </li>
                  </ul>
                </p>
                <p>
                  22.2 Where You are in breach of the Terms of Use, we may at
                  our sole discretion, prior to any suspension or termination of
                  Your Account, notify You (using Your Contact Details) that You
                  are in breach, requiring You to stop the relevant act or
                  failure to act, and/or requiring You to put right an act or
                  fault on Your part and warning You of our intended action if
                  You do not do so, provided always that such notification shall
                  not be a pre-condition to any suspension or termination of
                  Your Account.
                </p>
                <p>
                  22.3 We have the right to disable any user identification code
                  or password, whether chosen by You or allocated by us, at any
                  time, if in our reasonable opinion You have failed to comply
                  with any of the provisions of the Terms of Use.
                </p>
                <p>
                  22.4 In addition to any other remedy available, if You breach
                  any of the Terms of Use we shall be entitled to recover from
                  Your Account any positive balance to the extent of any amount
                  reasonably claimed against You pursuant to paragraph 21.1.
                </p>
                <h5>23. INTELLECTUAL PROPERTY RIGHTS</h5>
                <p>
                  23.1 All website design, text, graphics, music, sound,
                  photographs, video, the selection and arrangement thereof,
                  software compilations, underlying source code, software and
                  all other material forming part of the Services are subject to
                  copyright and other proprietary rights which are either owned
                  by us or used under licence from third party rights owners. To
                  the extent that any material contained within the Services may
                  be downloaded or printed then such material may be downloaded
                  to a single device only (and hard copies may be printed)
                  solely for Your own personal, non-commercial use.
                </p>
                <p>
                  23.2 Under no circumstances shall the use of the Services
                  grant to You any interest in any intellectual property rights
                  (for example copyright, know-how or trademarks) owned by us or
                  by any third party whatsoever, other than a personal,
                  non-exclusive, non-sublicensable licence to use such
                  intellectual property rights in connection with Your personal,
                  non-commercial use of the Services pursuant to the Terms of
                  Use.
                </p>
                <p>
                  23.3 No rights whatsoever are granted to use or reproduce any
                  trademarks or logos which appear on the Website except as
                  specifically permitted in accordance with the Terms of Use.
                </p>
                <p>
                  23.4 You must not, nor must You allow any other person to
                  copy, store, publish, rent, licence, sell, distribute, alter,
                  add to, delete, remove or tamper with the Website or any part
                  of it in any way or directly or indirectly disrupt or
                  interfere (or attempt to disrupt or interfere) with or alter
                  the Website, other than in the course of viewing or using the
                  Website in accordance with the Terms of Use.
                </p>
                <p>
                  23.5 All intellectual property rights in the name
                  "bharatplays", the logos, designs, trademarks and other
                  distinctive brand features of the Operator and any content
                  provided by the Operator or any third party for inclusion on
                  the Website vest in the Operator or the applicable third
                  party. You agree not to display or use such logos, designs,
                  trademarks and other distinctive brand features in any manner
                  without our prior written consent.
                </p>
                <h5>24. VIRUSES, HACKING AND OTHER OFFENCES</h5>
                <p>
                  24.1 You shall not:
                  <ul>
                    <li>24.1.1 corrupt the Website;</li>
                    <li>
                      24.1.2 attempt to gain unauthorised access to the Website,
                      the servers on which the Website is stored or any server,
                      computer or database connected to the Website;
                    </li>
                    <li>
                      24.1.3 flood the Website with information, multiple
                      submissions or "spam";
                    </li>
                    <li>
                      24.1.4 knowingly or negligently use any features which may
                      affect the function of the Website in any way for example
                      (but not limited to) releasing or propagating viruses,
                      worms, trojans, logic bombs or similar material that is
                      malicious or harmful;
                    </li>
                    <li>
                      24.1.5 interfere or tamper with, remove or otherwise alter
                      in any way, any information in any form which is included
                      on the Website;
                    </li>
                    <li>
                      24.1.6 attack the Website via a denial-of-service attack
                      or a distributed denial-of-service attack. We will report
                      any suspected breach to the relevant law enforcement
                      authorities and we will co-operate with those authorities
                      by disclosing Your identity to them. In the event of such
                      a breach, Your right to use the Website will cease
                      immediately.
                    </li>
                  </ul>
                </p>
                <p>
                  24.2 We will not be liable for any loss or damage caused by a
                  distributed denial-of-service attack, viruses or other
                  technologically harmful material that may infect Your Access
                  Device and related equipment, computer programs, data or other
                  proprietary material due to Your use of the Website or to Your
                  downloading of any material posted on such Website, or on any
                  website linked to the Website.
                </p>
                <p>24.3 Website is free from malicious software.</p>
                <h5>25. YOUR PERSONAL INFORMATION</h5>
                <p>
                  25.1 All information on Your Account held by us is securely
                  data warehoused and remains confidential except where
                  otherwise stated in the Terms of Use (including, for the
                  avoidance of doubt, the Privacy Policy).
                </p>
                <p>
                  25.2 We are required by law to comply with data protection
                  requirements in the way in which we use any personal
                  information collected from You in Your use of the Services. We
                  therefore take our obligations in relation to the way in which
                  we use Your personal information very seriously.
                </p>
                <p>
                  25.3 Prior to Your use of and when You use the Services it
                  will be necessary for us to collect certain information about
                  You, including Your name and date of birth, Your Contact
                  Details, and may also include information about Your marketing
                  preferences (all of which shall be known as "Your Personal
                  Information").
                </p>
                <p>
                  25.4 By providing us with Your Personal Information, You
                  consent to our processing Your Personal Information, including
                  any of the same which is particularly sensitive:
                  <ul>
                    <li>
                      25.4.1 for the purposes set out in the Terms of Use
                      (including the Privacy Policy); and
                    </li>
                    <li>
                      25.4.2 for other purposes where we (including Payment
                      Processor) need to process Your Personal Information for
                      the purposes of operating the Services, including by
                      sharing it with our service providers, employees and
                      agents for these purposes, for example to our providers of
                      postal services, marketing services and Customer Services
                      agents. We may also disclose Your Personal Information in
                      order to comply with a legal or regulatory obligation.
                    </li>
                  </ul>
                </p>
                <p>
                  25.5 We may retain copies of any communications that You send
                  to us (including copies of any emails) in order to maintain
                  accurate records of the information that we have received from
                  You.
                </p>
                <h5>26. USE OF 'COOKIES' ON THE WEBSITE</h5>
                <p>
                  26.1 The Website may use 'cookies' to track Your use of the
                  internet and to assist the functionality of the Website. A
                  cookie is a small file of text which is downloaded onto Your
                  Access Device when You access the Website and it allows us to
                  recognise when You come back to the Website. We use or shall
                  use cookies for the operation of the Website, including (for
                  example) to allow You to remain logged in as You browse
                  between, and use Your Account to bet on or play games on,
                  different parts of the Website. We also use cookies for our
                  own analytical purposes so that we can identify where
                  customers have encountered technical problems on the Website,
                  and therefore help us improve our customers' experience.
                </p>
                <p>
                  26.2 If You object to cookies or want to delete any cookies
                  that are already stored on Your Access Device, we recommend
                  that You follow the instructions for deleting existing cookies
                  and disabling future cookies on Your file management and
                  internet browsing software. Further information on deleting or
                  controlling cookies is available within our Privacy Policy or
                  at www.aboutcookies.org. Please note that by deleting our
                  cookies or disabling future cookies You may not be able to
                  access certain areas or features of the Website.
                </p>
                <h5>27. COMPLAINTS AND NOTICES</h5>
                <p>
                  27.1 No claim or dispute with regard to:
                  <ul>
                    <li>
                      27.1.1 the acceptance or settlement of a bet which You
                      have made using the Services will be considered more than
                      thirty days after the date of the original transaction;
                      and
                    </li>
                    <li>
                      27.1.2 a game which You have played using the Services
                      will be considered more than twelve weeks after the date
                      on which the relevant transaction or gameplay took place.
                    </li>
                  </ul>
                </p>
                <p>
                  27.2 Should You wish to make a complaint regarding the
                  Services, as a first step You should, as soon as reasonably
                  practicable, contact support@bharatplays.com about Your
                  complaint, which will be escalated as necessary within our
                  Support team until resolution.
                </p>
                <p>
                  27.3 You acknowledge that our random number generator will
                  determine the outcome of the games played through the Services
                  and You accept the outcomes of all such games. You further
                  agree that in the unlikely event of a disagreement between the
                  result that appears on Your screen and the game server used by
                  the Operator, the result that appears on the game server will
                  prevail, and You acknowledge and agree that our records will
                  be the final authority in determining the terms and
                  circumstances of Your participation in the relevant online
                  gaming activity and the results of this participation.
                </p>
                <p>
                  27.4 When we wish to contact You, we may do so using any of
                  Your Contact Details. Notices will be deemed to have been
                  properly served and received by You immediately after an email
                  is sent or after we have communicated with You directly by
                  telephone (including where we leave You a voicemail), or three
                  days after the date of posting of any letter. In proving the
                  service of any notice, it will be sufficient to prove, in the
                  case of a letter, that such letter was properly addressed,
                  stamped and placed in the post; in the case of an email, that
                  such email was sent to the specified email address (if any) in
                  Your Contact Details at the time that any such email was sent.
                </p>
                <p>
                  27.5 If there is no reaction on your complaint or notice from
                  us or in your opinion the issue is not yet resolved, you can
                  file a complaint or notice to the licensing and supervision
                  organization contacting complaints@gaminglicences.com.
                </p>
                <h5>28. TRANSFER OF RIGHTS AND OBLIGATIONS</h5>
                <p>
                  28.1 We reserve the right to transfer, assign, sublicense or
                  pledge the Terms of Use (an "assignment"), in whole or in
                  part, to any person without notice to You, provided that any
                  such assignment will be on the same terms or terms that are no
                  less advantageous to You.
                </p>
                <p>
                  28.2 You may not assign, sublicense or otherwise transfer in
                  any manner whatsoever any of Your rights or obligations under
                  the Terms of Use.
                </p>
                <h5>29. EVENTS OUTSIDE OUR CONTROL</h5>
                <p>
                  29.1 We will not be liable or responsible for any failure to
                  perform or delay in performance of, any of our obligations
                  under the Terms of Use that is caused by events outside our
                  reasonable control, including (without limitation) any
                  telecommunications network failures, power failures, failures
                  in third party computer (or other) equipment, fire, lightning,
                  explosion, flood, severe weather, industrial disputes or
                  lock-outs, terrorist activity and acts of government or other
                  competent authorities (a "Force Majeure Event").
                </p>
                <p>
                  29.2 Our performance is deemed to be suspended for the period
                  that the Force Majeure Event continues, and we will have an
                  extension of time for performance for the duration of that
                  period. We will use our reasonable endeavours to bring the
                  Force Majeure Event to a close or to find a solution by which
                  our obligations may be performed despite the Force Majeure
                  Event.
                </p>
                <h5>30. WAIVER</h5>
                <p>
                  30.1 If we fail to insist upon strict performance of any of
                  Your obligations or if we fail to exercise any of the rights
                  or remedies to which we are entitled, this shall not
                  constitute a waiver of such rights or remedies and shall not
                  relieve You from compliance with such obligations.
                </p>
                <p>
                  30.2 A waiver by us of any default shall not constitute a
                  waiver of any subsequent default.
                </p>
                <p>
                  30.3 No waiver by us of any of the provisions of the Terms of
                  Use shall be effective unless it is expressly stated to be a
                  waiver and is communicated to You in writing in accordance
                  with paragraph 27 (Complaints and Notices) above.
                </p>
                <h5>31. SEVERABILITY</h5>
                <p>
                  31.1 If any of the Terms of Use are determined by any
                  competent authority to be invalid, unlawful or unenforceable
                  to any extent, such term, condition or provision will to that
                  extent be severed from the remaining terms, conditions and
                  provisions which will continue to be valid to the fullest
                  extent permitted by law.
                </p>
                <p>
                  31.2 In such cases, the part deemed invalid or unenforceable
                  shall be amended in a manner consistent with the applicable
                  law to reflect, as closely as possible, the Operator's
                  original intent.
                </p>
                <h5>32. ENTIRE AGREEMENT</h5>
                <p>
                  32.1 The Terms of Use and any document expressly referred to
                  in them represent the entire agreement between You and us and
                  supersede any prior agreement, understanding or arrangement
                  between You and us, whether oral or in writing.
                </p>
                <p>
                  32.2 We each acknowledge that neither of us has relied on any
                  representation, undertaking or promise given by the other or
                  implied from anything said or written in negotiations between
                  us except as expressly stated in the Terms of Use.
                </p>
                <p>
                  32.3 Neither party shall have any remedy in respect of any
                  untrue statement made by the other, whether orally or in
                  writing, prior to the date of the contract (unless such untrue
                  statement was made fraudulently) and the other party's only
                  remedy shall be for breach of contract as provided in the
                  Terms of Use.
                </p>
                <h5>33. THIRD PARTY RIGHTS</h5>
                <p>
                  33.1 Unless these Terms of Use expressly state otherwise a
                  person who is not a party to these Terms of Use has no right
                  to enforce any of the terms. It is expressly forbidden for End
                  Users to transfer any assets of the value of any kind to a
                  third party, including but not limited, it is forbidden to
                  transfer ownership of account(s), winnings, deposits, bets,
                  rights and/or claims in connection with these assets, legal,
                  commercial or of any other character. Such prohibition
                  includes, but is not limited to encumbrance, pledging,
                  assigning, usufruct, trading, brokering, hypothecation and/or
                  gifting either separately or in cooperation with a fiduciary
                  or any other third party, company, natural or legal
                  individual, foundation and/or association in any way or shape
                  of form.
                </p>
                <h5>34. LAW AND JURISDICTION</h5>
                <p>
                  34.1 These Terms of Use shall (subject to paragraph 25.2) be
                  governed by and interpreted in accordance with the laws of the
                  Curacao.
                </p>
                <h5>35. RESPONSIBLE GAMING/GAMBLING</h5>
                <p>
                  35.1 For those customers who wish to restrict their gambling,
                  we provide a voluntary self-exclusion policy, which enables
                  You to close Your Account or restrict Your ability to place
                  bets or game on the Website for a minimum period of six
                  months. Once Your Account has been self-excluded You will be
                  unable to reactivate the Account under any circumstances until
                  the expiry of the period chosen under this paragraph. At the
                  expiry of the self-exclusion period, You will be entitled to
                  re-commence use of the Services by contacting
                  support@bharatplays.com
                </p>
                <p>
                  35.2 In case you are concerned about your gambling practices
                  there are a number of guidelines you may find useful:
                  <ul>
                    <li>
                      35.2.1 try and establish limits for the amounts you want
                      to wager or deposit.
                    </li>
                    <li>
                      35.2.2 consider how long you wish any wagering session to
                      last before commencing and be sure to keep an eye on the
                      clock.
                    </li>
                    <li>
                      35.2.3 try not to let gambling interfere with your daily
                      responsibilities
                    </li>
                  </ul>
                </p>
                <p>
                  35.3 Mind that gambling is not advised if you are recovering
                  for any form of dependency or if you are under the influence
                  of alcohol or any other substance including some prescription
                  medication.
                </p>
                <p>
                  35.4 You should always be aware that gambling is a form of
                  recreation and should not be viewed as an alternative source
                  of income or a way to recoup the debt. Recognising that you
                  may be developing a gambling problem is the first step to
                  regaining control.
                </p>
                <p>
                  35.5 Please bear in mind, that:
                  <ul>
                    <li>
                      35.5.1 Gambling should be entertaining and not seen as a
                      way of making money
                    </li>
                    <li>35.5.2 you should avoid chasing losses</li>
                    <li>35.5.3 Only gamble what you can afford to lose</li>
                    <li>
                      35.5.4 Keep track of time and the amount you spend
                      gambling
                    </li>
                  </ul>
                </p>
                <p>
                  35.6 If You require any information relating to this contact
                  our Support support@bharatplays.com or view our Responsible
                  Gambling section.
                </p>
                <p>
                  35.7 The Operator is committed to supporting Responsible
                  Gambling initiatives. We suggest visiting
                  www.responsiblegambling.org for further help, if you feel that
                  gambling starts to become a problem for you.
                </p>
                <h5>36. LINKS</h5>
                <p>
                  Where we provide hyperlinks to other websites, we do so for
                  information purposes only. You use any such links at your own
                  risk and we accept no responsibility for the content or use of
                  such websites, or for the information contained on them. You
                  may not link to this site, nor frame it without our express
                  written permission.
                </p>
                <h5>37. CONTACTING US</h5>
                <p>
                  The Operator can be contacted by e-mail
                  support@bharatplays.com. Please note that all calls may be
                  recorded for training and security purposes. If You are
                  concerned about your gambling practices there are a number of
                  guidelines you may find useful:
                  <ul className="subpoints">
                    <li>
                      ● Consider how long you wish any wagering session to last
                      before commencing and be sure to keep an eye on the clock.
                    </li>
                    <li>
                      ● Try not to let gambling interfere with your daily
                      responsibilities.
                    </li>
                    <li>
                      ● Gambling is not advised if you are recovering for any
                      form of dependency or if you are under the influence of
                      alcohol or any other substance including some prescription
                      medication. We recommend that you consult your GP for more
                      information.
                    </li>
                    <li>
                      ● Gambling is a form of recreation and should not be
                      viewed as an alternative source of income or a way to
                      recoup debt.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <BeforeLoginFooter />
        </div>
      </div>
    );
  }
}

export default BeforeTerms;
