import React from "react";
import Draggable from "react-draggable";

import DraggableICon from "../../assets/images/icons/all-directions.png";
import ArrowImg from "../../assets/images/icons/right.png";
import { RiDragMove2Line } from "react-icons/ri";

const VideoComponent = ({ handleClose, showVideo, videoSrc }) => {
  return (
    <>
      <Draggable handle=".handle">
        <div className={showVideo && "SlideVideo"}>
          <a
            href="#"
            className="VideoArrow"
            onClick={() => {
              handleClose(false);
            }}
          >
            {" "}
            <img src={ArrowImg} alt="Whatsapp Icon" />
          </a>
          <div className="VideoHide">
            <div className="handle">
              {/* <img src={DraggableICon} alt="drag" /> */}
              <RiDragMove2Line />
            </div>
            <div className="VideoIframe">
              {/* <iframe src={videoSrc} allow="autoplay"></iframe> */}
              <iframe
                src={videoSrc}
                loading="lazy"
                // style="border:0;position:absolute;top:0;height:100%;width:100%;"
                // allow="gyroscope;autoplay;encrypted-media;picture-in-picture;"
                // allowfullscreen="true"
              ></iframe>

              <div className="handle OtherDragBtn">
                {/* <img src={DraggableICon} alt="drag" /> */}
                <RiDragMove2Line />
              </div>

              <button className="VideoClose" onClick={() => handleClose(false)}>
                X
              </button>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};
export default VideoComponent;
