import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import MainSlider from "../../../../containers/MainSlider";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import StoryModal from "./storyModal";
import {
  postData,
  highLightsData,
  tagsData,
} from "../../../../lib/data/bonus.data";
import PostsComponent from "./posts";
import ReelsComponent from "./posts/reels";
import Bonus from ".";

import Post from "../../../../assets/images/icons/postt.png";
import Reels from "../../../../assets/images/icons/reel.png";
import BonusYour from "../../../../assets/images/icons/bonusyour.png";
import Tag from "../../../../assets/images/icons/platinum.png";
import Platinum from "./posts/Platinum";

import logo from "../../../../assets/svg/icon.jpg";
import ChangePasswordModal from "../../../../containers/ChangePasswordModal";
import { useNavigate } from "react-router-dom";

import ProfileIconNew from "../../../../assets/images/icons/configuration.png";
import MyBetsIcon from "../../../../assets/images/icons/MyBetwhite.png";
import AccountStatementNew from "../../../../assets/images/icons/AccountStatementwhite.png";

import { logoutUser } from "../../../../redux/auth/actions";
import numberWithCommas from "../../../../utils/numbersWithComma";

const BonusTc = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  window.onclick = (ev) => {
    if (document.getElementById("profiledropdown").contains(ev.target)) {
      // console.log("click in");
    } else {
      setDropShow(true);
      // handleSideBarVisibility(false);
    }
  };

  const [dropShow, setDropShow] = useState(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const { banners } = useSelector((state) => state.app);
  const [key, setKey] = useState("welcome");

  const [showStoryModal, setShowStoryModal] = useState(false);
  const [selectedHighLight, setSelectedHighLight] = useState(null);
  const [activeTab, setActiveTab] = useState(false);
  useEffect(() => {
    setActiveTab(tabs[0].key);
  }, []);
  const tabs = [
    {
      id: 1,
      name: "Posts",
      imgUrl: <img src={Post} alt="Post" />,
      key: "posts",
    },
    {
      id: 3,
      name: "Your Bonus",
      imgUrl: <img src={BonusYour} alt="Post" />,
      key: "bonus",
    },

    {
      id: 2,
      name: "Reels",
      imgUrl: <img src={Reels} alt="Post" />,
      key: "reels",
    },

    {
      id: 4,
      name: "Tags",
      imgUrl: <img src={Tag} alt="Post Platinum" />,
      key: "tags",
    },
  ];

  const renderContent = (tab) => {
    console.log(tab);
    if (tab === "posts") {
      return <PostsComponent data={postData} />;
    } else if (tab === "tags") {
      return <Platinum data={tagsData} />;
    } else if (tab === "reels") {
      return <ReelsComponent />;
    } else {
      return <Bonus callType="component" />;
    }
  };
  return (
    <div>
      <div className="bonustc_pge">
        <HeaderAfterLogin />

        {/* <MainSlider banners={banners} className="BonusntcBanner" /> */}
      </div>
      <main className="main footer-content terms BonusTermsnCond">
        <div className="container">
          <div className="ProfileSection">
            <div className="ProfilePhoto">
              <div className="profile-img">
                <img src={logo} />
              </div>
            </div>

            <div className="ProfileContent">
              <ul>
                <li className="d-md-block d-none">
                  <span>{user?.mstruserid}</span>
                </li>
                <li className="d-md-block d-none">
                  <div className="">
                    <ChangePasswordModal />
                  </div>
                </li>
                <li
                  className=" d-md-block d-none"
                  onClick={() => dispatch(logoutUser())}
                >
                  <a href="/" className="signout">
                    {/* <img src={SignOut1} /> */}
                    Sign Out
                  </a>
                </li>

                <li className=" d-md-none d-block">
                  <a href="/gatewaylist" className="">
                    Deposit
                  </a>
                </li>
                <li className=" d-md-none d-block">
                  <a href="/Withdraw" className="">
                    Withdraw
                  </a>
                </li>

                <li
                  className=" d-md-none d-block"
                  onClick={() => dispatch(logoutUser())}
                >
                  <a href="/" className="signout">
                    {/* <img src={SignOut1} /> */}
                    Sign Out
                  </a>
                </li>
              </ul>

              <ul className="d-md-flex d-none WebDepositSection">
                <li className="">
                  <a href="/gatewaylist" className="deposit">
                    Deposit
                  </a>
                </li>
                <li className="">
                  <a href="/withdraw" className="deposit">
                    Withdraw
                  </a>
                </li>
                <li className="">
                  <a href="/referral" className="">
                    Referral
                  </a>
                </li>
              </ul>

              <ul className="otherDetails d-md-flex d-none">
                <li>{user?.mstrname}</li>
                <li>{user?.mstruserid}</li>
                <li>{user?.email}</li>
                <li>{user?.mobileno}</li>
                <li>{user?.city}</li>
                <li>{user?.country}</li>
                <li>{user?.pin_code}</li>
              </ul>
            </div>
          </div>
          <ul className="otherDetails d-md-none d-flex">
            <li>{user?.mstrname}</li>
            <li>{user?.mstruserid}</li>
            <li>{user?.email}</li>
            <li>{user?.mobileno}</li>
          </ul>
        </div>

        <div className="d-md-none d-block MobileResetPass">
          <ul>
            <li className="">
              <div className="">
                <ChangePasswordModal />
              </div>
            </li>
            <li className="">
              <a href="/referral">Referral</a>
            </li>
          </ul>
        </div>

        <div className="container">
          {/* <h2 className="sectionTitle">Bonus Terms & Conditions</h2> */}

          <div className="BonusTermsCond">
            <div className="BonusHighlights ">
              <ul>
                {highLightsData.map((hl, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setSelectedHighLight(hl);
                        setShowStoryModal(true);
                      }}
                    >
                      <a href="#">
                        <img src={hl.imageUrl} alt={hl.highLightName} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="BonusSectionTabs">
              <div className="AllTabs">
                {tabs.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setActiveTab(tab.key)}
                      className={tab.key === activeTab ? "active" : ""}
                    >
                      {tab.imgUrl}
                    </div>
                  );
                })}
              </div>
              <div>{renderContent(activeTab)}</div>
            </div>
          </div>
          {showStoryModal && (
            <StoryModal
              showStoryModal={showStoryModal}
              setShowStoryModal={setShowStoryModal}
              selectedHighLight={selectedHighLight}
            />
          )}
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BonusTc;
