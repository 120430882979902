import PromotionHighlight5 from "../../assets/images/banner/bonus/WelcomeBonus.jpg";
import PromotionHighlight6 from "../../assets/images/banner/bonus/CashbackBonus1.jpg";
import PromotionHighlight7 from "../../assets/images/banner/bonus/ReferralBonus.jpg";
import PromotionHighlight8 from "../../assets/images/banner/bonus/EvolutionCasinoCupBonus.jpg";
import FirstTimedepositBonus from "../../assets/images/banner/bonus/FirstTimedepositBonus.jpg";
import sconddepositBonus from "../../assets/images/banner/bonus/SeconddepositBonus.jpg";
import thirdDepositBonus from "../../assets/images/banner/bonus/ThirdDepositbonus.jpg";
import ThisWorldCup from "../../assets/images/banner/bonus/cricketbash.jpg";
import LoosingCommission from "../../assets/images/banner/bonus/LoosingCommission.jpg";
import Flat10Rewards from "../../assets/images/banner/bonus/Flat10Reward.jpg";
import Flat20Rewards from "../../assets/images/banner/bonus/Flat20Reward.jpg";
import AffiliateTC from "../../assets/images/banner/bonus/Affiliate.jpg";
import FreeTicketsTC from "../../assets/images/banner/bonus/ipl.jpg";
import PromotionHighlight from "../../assets/images/banner/bonus/bonusHighlight1.png";
import PromotionHighlight1 from "../../assets/images/banner/bonus/bonusHighlight2.png";
import PromotionHighlight2 from "../../assets/images/banner/bonus/bonusHighlight3.png";
import PromotionHighlight3 from "../../assets/images/banner/bonus/bonusHighlight4.png";
import PromotionHighlight4 from "../../assets/images/banner/bonus/bonusHighlight5.png";

import BonusPost1 from "../../assets/images/banner/bonus/bonus2.jpg";
import BonusPost2 from "../../assets/images/banner/bonus/bonus4.jpg";
import BonusPost3 from "../../assets/images/banner/bonus/bonus5.jpg";
import BonusPost4 from "../../assets/images/banner/bonus/bonus2.jpg";

import PlatinumImg from "../../assets/images/banner/Platinumgrid.png";
import RefilBonusImg4 from "../../assets/images/banner/bonus/RefillBonus.jpg";

export const postData = [
  {
    heading: "Welcome Bonus",
    imgUrl: PromotionHighlight5,
    content: (
      <>
        <h4>WELCOME BONUS</h4>
        <p>
          We Here At Bharatplays Are Elated That You Decided To Trust Us And
          Make Your First Deposit On Our Platform. We Love To Pamper Our
          Customers And Hence You Deserve A Gift On Making Your First Deposit
          Here. Read Below To Learn How You Can Avail This Bonus Of 300% On Your
          Deposit Amount.
        </p>

        <h4>WHAT IS WELCOME BONUS?</h4>
        <p>
          You Are Eligible For A 300% Welcome Bonus On Your 1st Deposit Amount.
          So How Do You Get To Claim This Bonus? Just Follow This ONE Simple
          Step:
        </p>
        <p>
          Step 1: Complete Your RO Requirement Of 24X Your Bonus Amount And The
          Bonus Amount Will Be Automatically Credited To Your Wallet. Now You
          May Wonder, What Is A RO Requirement? Well, Read Further Below To
          Understand.
        </p>
        <p>Here Is An Example</p>
        <p>A) Your First Deposit Amount = Rs. 1,000</p>
        <p>B) Hence Your Bonus Of 300% = Rs. 3,000</p>
        <p>
          C) You Will Need To Complete An RO Requirement Of 24X Your Bonus
          Amount, Which Is Rs 3,000 * 24 = Rs. 72,000. Once You Have Achieved A
          Valid RO Requirement Of Rs. 72,000, The Rs. 3,000 Bonus Amount Will Be
          Added To Your Wallet Instantly.
        </p>

        <h4>WHAT IS RO REQUIREMENT?</h4>
        <p>
          Well RO Means Roll Over, Which In Essence Means You Need To Complete A
          Certain Valid Roll Over To Avail Your Bonus.The Valid RO Calculation
          Is As Follows:
        </p>
        <p>
          | PL | ≥ 50%
          <div className="seprator"></div>
          TOTAL STAKE
        </p>
        <p>
          In other words, bets must be with odds of at least Decimal 1.50 to be
          contributed towards bonus rollover requirements.
        </p>
        <p>
          So here is an example: you placed a bet of 100 rs in roulette on black
          color. So your stake is: 100 Rs
        </p>
        <p>Now There Are 3 Scenarios:</p>
        <p>
          <p>
            A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
            Hence Your Valid RO = Rs. 100
          </p>
          <p>
            B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) =
            100% Hence Your Valid RO = Rs. 100
          </p>
          <p>
            C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) =
            5% Hence Your Valid RO = 0 As The 5% 50%.
          </p>
        </p>

        <h4>TERMS AND CONDITIONS</h4>
        <p>
          <ol>
            <li>
              1. Sign-up points, allocated for players upon registration, are
              exclusively designated for accessing and experiencing the various
              casino games available on the platform
            </li>
            <li>
              2. These sign-up points hold no redeemable cash value and are
              solely intended for demonstration purposes.
            </li>
            <li>
              3. Users may utilize their sign-up points to participate in any of
              the casino games provided on the platform.
            </li>
            <li>
              4. However, it is important to note that any winnings accrued
              through the utilization of sign-up points will not be eligible for
              withdrawal. Upon a withdrawal request, all earnings obtained from
              sign-up points will be forfeited.
            </li>
            <li>
              5. Sign-up points cannot be converted into real currency or
              exchanged for any tangible rewards outside of their intended
              purpose of experiencing the demo versions of the casino games.
            </li>
            <li>
              6. The platform reserves the right to modify, revoke, or amend the
              terms related to sign-up points without prior notice.
            </li>
          </ol>
        </p>
      </>
    ),
  },

  {
    heading: "REFILL BONUS",
    imgUrl: RefilBonusImg4,
    content: (
      <>
        <h4>REFILL BONUS</h4>
        <p>
          The First And Only Platform In The Country To Now Offer Bonuses On
          Every Deposit We At "BHARATPLAYS" Strongly Value And Care For Our
          Loyal Customers And Thus You Deserve More! At "BHARATPLAYS" Now With
          Every Deposit Earn A Little More And Gain An Edge To Win More, And
          Thus With Every New Deposit Is A New Opportunity! "BHARATPLAYS" Wishes
          To Welcome You Back On Every Deposit With Upto 5% Extra Bonus (For
          Platinum Members)!!
        </p>
        <h4>What Is A Refill BONUS?</h4>
        <p>
          You Are Eligible For A 2% Refill Bonus On Every Re-Deposit That You Do
          . So Let's Understand How It Works …
        </p>
        <p>1.) Login To Your "BHARATPLAYS" Account</p>
        <p>
          2.) Apart From Your First Deposit, Every Deposit Henceforth You Will
          Receive 2% Extra Balance In Your Main Wallet Instantly After You Have
          Successfully Deposited.
        </p>
        <h4>TERMS AND CONDITIONS</h4>
        <p>
          1. Only One Bonus Is Allowed Per Customer, Family, Address, Shared
          Computer, Shared IP Address, Any Identical Account Details Including
          E-Mail Address, Bank Account Details, Credit Card Infomation And
          Payment System Account. Any Misuse Of This Bonus Offer Will Lead To An
          Account Being Closed
        </p>
        <p>2. Maximum limit is 500 INR</p>
        <p>
          3. Bonus Amount Will Be Issued Into Your Main Wallet After Your
          Successful Redeposit.
        </p>
        <p>
          4. This Promotion Is Available On Every Re-Deposit And Every Bonus
          Amount Received During The Day Shall Expire Within 24 Hours
        </p>
        <p>
          5. Multiple Accounts Will Not Be Considered For This Promotion. All
          Players Will Be Subject To Duplicate Checking. "BHARATPLAYS" Reserves
          The Right To Disqualify Players If Collusion Or Any Foul Play Takes
          Place In The Promotion.
        </p>
        <p>
          6. "BHARATPLAYS" Reserve The Right To Amend, Cancel And Reclaim Or
          Refuse Any Promotion At Its Own Discretions.
        </p>
        <p>
          7. Bonus Amount Cannot Be Withdrawn But The Winnings From The Bonus
          Are Withdrawable.
        </p>
        <p>8. "BHARATPLAYS" General Terms & Conditions Apply</p>
        <p>9. Rollover Is Not Valid For Any Of The P2P Games</p>
      </>
    ),
  },

  {
    heading: " 100% Cashback Bonus",
    imgUrl: PromotionHighlight6,
    content: (
      <>
        <h4>100% Cashback Bonus</h4>
        <p>
          1. Percentage: Upto 100% cashback bonus is awarded to every New member
          on his first deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 2,000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) 10x Rollover of the amount you have deposited must be completed
            to unlock the bonus.
          </p>
          <p>
            ii) Minimum 1000 INR deposit is required to avail deposit bonus.
          </p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>
        <p>5. Validation: Bonus is valid for 7 days after activation.</p>
        <p>
          6. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          7. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          8. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>9. For more information use our General Terms and Conditions.</p>
        <p>
          10. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
        <h4>What Is A Rollover Requirement?</h4>
        <p>Here Is An Example</p>
        <p>A) Your First Deposit Amount = Rs. 1,000</p>
        <p>B) Hence Your Bonus Of 400% = Rs. 4,000</p>
        <p>
          C) You Will Need To Complete An RO Requirement Of 40X Your Deposited
          Amount, Which Is Rs 1,000*40 = Rs. 40,000/- .
        </p>
        <p>
          D) “Bharatplays” will be actively depositing your bonus per rotation
          in your wallet
        </p>
        <p>
          E) Once You Have Achieved A Valid RO Requirement Of Rs. 40,000, Rs.
          4,000 Bonus Amount Will Be Unlocked To Use Instantly.
        </p>

        <h4>WHAT IS RO REQUIREMENT?</h4>
        <p>
          Well RO Means Roll Over, Which In Essence Means You Need To Complete A
          Certain Valid Roll Over To Unlock Your Bonus.The Valid RO Calculation
          Is As Follows:
        </p>

        <h4>TOTAL STAKE</h4>
        <p>
          In Other Words, Bets Must Be With Odds Of At Least Decimal 1.50 To Be
          Contributed Towards Bonus Rollover Requirements.
        </p>
        <p>
          So Here Is An Example: You Placed A Bet Of 100 Rs In Roulette On Black
          Color. So Your Stake Is: 100 Rs
        </p>

        <p>Now There Are 3 Scenarios:</p>
        <p>
          A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) = 5%
          Hence Your Valid RO = 0 As The 5% {`${"<"}`} 50%.
        </p>
      </>
    ),
  },
  {
    heading: " Unlimited Referral Bonus",
    imgUrl: PromotionHighlight7,
    content: (
      <>
        <h4>Unlimited Referral Bonus</h4>
        <h4>
          Introducing Exclusive "BHARATPLAYS" Refer-A-Friend Program: A Win-Win
          Opportunity!
        </h4>
        <p>
          Imagine A World Where Everyone Could Have The Privilege Of Being Your
          Friend Well, We Believe That Exceptional Friendships Deserve
          Exceptional Rewards. That's Why We've Created Our Exciting
          Refer-A-Friend Program, The Best Online Betting & Casino Referral
          Bonus, Where You And Your Friends Can Benefit Together. "BHARATPLAYS"
          Referral Code Is Open To All Users, Both New And Old. Get To Know How
          Referral Bonus Works.
        </p>

        <h4>
          How "BHARATPLAYS" Referral Bonus Works? | "BHARATPLAYS" Referral Code
        </h4>
        <p>
          1. Bring Your Friends On Board And Join Us For An Unforgettable Gaming
          Experience.
        </p>
        <p>
          2. When Your Friends Sign Up With "BHARATPLAYS" Using Your Unique
          Referral Link Or Code ("BHARATPLAYS" Referral Code), The Fun Begins &
          You Activate The Yolo247 Referral Bonus Process.
        </p>

        <p>
          3. Each Time Your Referred Friend Makes A Deposit, You'll Receive A
          Bonus Based On A Generous Percentage Structure:
          <p>
            ● 10% Bonus On Their First Deposit ○ Maximum Limit is capped at 1000
          </p>
          <p>
            ● 5% Bonus On Their Second Deposit ○ Maximum Limit is capped at 1000
          </p>
          <p>
            ● 5% Bonus On every deposit of referral aftwerwards ○ Maximum Limit
            is capped at 1000
          </p>
        </p>
        <h4>How To Refer A Friend & Win The Unlimited Referral Bonus?</h4>
        <p>
          1. Share Your Unique Referral Code/URL With Your Friend And Encourage
          Them To Join "BHARATPLAYS".
        </p>
        <p>
          2. Your Friend Should Register On "BHARATPLAYS" Using Your Referral
          Code To Ensure You Receive Credit For This Referral Bonus.
        </p>

        <p>
          3. Once Your Friend Completes Their Initial Deposit, Your Bonus will
          be credited to your account automatically.
        </p>
        <p>
          4. Join The "BHARATPLAYS" Community And Take Advantage Of This
          Incredible Opportunity To Share The Fun And Reap The Rewards Together.
          Start Referring Your Friends Today And Unlock A World Of Thrilling
          Experiences!
        </p>
        <h4>TERMS AND CONDITIONS</h4>
        <p>
          1. Referral Bonuses Will Be Awarded For The First Three Deposits Made
          By The Referred User.
        </p>
        <p>
          2. Only One Referral Bonus Will Be Active At A Time. For Instance, If
          The Referral Bonus For The First Deposit Is Active, The Bonuses For
          The Second And Third Deposits Will Be Queued And Processed
          Subsequently.
        </p>
        <p>
          3. Bonus Amount Will Be Issued Into Your Main Wallet After The
          Completion Of First Deposit And Similarly For Second & Third Deposit.
        </p>
        <p>
          4. The Deposit Amount Must Be Rolled Over 1 (one) Times. Turnover
          Generated Anywhere Across The Platform Will Be Calculated Towards The
          Turnover Requirements.
        </p>
        <p>
          5. The Referral Bonus Will Expire After 7 Days From The Date Of
          Activation. The Validity Period For The Referral Bonus Will Be 3
          Months. If The User Did Not Make Any Deposits Within This Period, The
          Bonus Scheme Will Automatically Expire.
        </p>
        <p>
          6. Void/Canceled/Draw Bets, Cashed-Out Bets Do Not Count Towards The
          Deposit Or Bonus Turnover Requirements.
        </p>
        <p>
          7. Multiple Accounts Will Not Be Considered For This Promotion. All
          Players Will Be Subject To Duplicate Checking. "BHARATPLAYS" Reserves
          The Right To Disqualify Players If Any Collusion Or Any Foul Play
          Takes Place In The Promotion.
        </p>
        <p>
          8. Bonus Amount Cannot Be Withdrawn But The Winnings From The Bonus
          Are Withdrawable.
        </p>
        <p>
          9. "BHARATPLAYS" Reserves The Right To Amend, Cancel And Reclaim Or
          Refuse Any Promotion At Its Own Discretion.
        </p>
        <p>
          10. In The Event, Any Fraudulent Activity Of The Player(S) Has Been
          Identified, Yolo247 Reserves The Right To Withhold Your Funds And
          Reject Your Withdrawals.
        </p>
        <p>11. "BHARATPLAYS" General Terms & Conditions Apply.</p>
      </>
    ),
  },
  {
    heading: "First Time deposit Bonus",
    imgUrl: FirstTimedepositBonus,
    content: (
      <>
        <h4>First Time deposit Bonus</h4>

        <p>
          1. Percentage: Upto 400% bonus is awarded to every New Club member on
          his first deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 25,000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) 40x Rollover of the amount you have deposited must be completed
            to unlock the bonus.
          </p>
          <p>ii) Minimum 100 INR deposit is required to avail deposit bonus.</p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>
        <p>5. Validation: Bonus is valid for 7 days after activation.</p>
        <p>
          6. Special Offer: “Bharatplays” will be actively depositing your bonus
          per rotation in your wallet
        </p>
        <p>
          7. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          8. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          9. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>10. For more information use our General Terms and Conditions.</p>
        <p>
          11. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
        <h4>What Is A Rollover Requirement?</h4>
        <p>Here Is An Example</p>
        <p>A) Your First Deposit Amount = Rs. 1,000</p>

        <p>B) Hence Your Bonus Of 400% = Rs. 4,000</p>
        <p>
          C) You Will Need To Complete An RO Requirement Of 40X Your Deposited
          Amount, Which Is Rs 1,000*40 = Rs. 40,000/- .
        </p>
        <p>
          D) “Bharatplays” will be actively depositing your bonus per rotation
          in your wallet
        </p>
        <p>
          E) Once You Have Achieved A Valid RO Requirement Of Rs. 40,000, Rs.
          4,000 Bonus Amount Will Be Unlocked To Use Instantly.
        </p>
        <h4>WHAT IS RO REQUIREMENT?</h4>
        <p>
          Well RO Means Roll Over, Which In Essence Means You Need To Complete A
          Certain Valid Roll Over To Unlock Your Bonus.The Valid RO Calculation
          Is As Follows:
        </p>

        <h4>TOTAL STAKE</h4>
        <p>
          In Other Words, Bets Must Be With Odds Of At Least Decimal 1.50 To Be
          Contributed Towards Bonus Rollover Requirements. So Here Is An
          Example: You Placed A Bet Of 100 Rs In Roulette On Black Color. So
          Your Stake Is: 100 Rs
        </p>
        <p>Now There Are 3 Scenarios:</p>
        <p>
          A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) = 5%
          Hence Your Valid RO = 0 As The 5%{`${"<"}`} 50%.
        </p>
      </>
    ),
  },
  {
    heading: "2nd deposit Bonus",
    imgUrl: sconddepositBonus,
    content: (
      <>
        <h4>200% 2nd deposit Bonus</h4>

        <p>
          1. Percentage: Upto 200% bonus is awarded to Every Club member on his
          second deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 15,000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) 25x Rollover of the amount you have deposited must be completed
            to unlock the bonus.
          </p>
          <p>ii) Minimum 100 INR deposit is required to avail deposit bonus.</p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>
        <p>5. Validation: Bonus is valid for 7 days after activation.</p>
        <p>
          6. Special Offer: “Bharatplays” will be actively depositing your bonus
          per rotation in your wallet
        </p>
        <p>
          7. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          8. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          9. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>10. For more information use our General Terms and Conditions.</p>
        <p>
          11. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
        <h4>What Is A Rollover Requirement?</h4>
        <p>Here Is An Example</p>
        <p>A) Your First Deposit Amount = Rs. 1,000</p>

        <p>B) Hence Your Bonus Of 400% = Rs. 4,000</p>
        <p>
          C) You Will Need To Complete An RO Requirement Of 40X Your Deposited
          Amount, Which Is Rs 1,000*40 = Rs. 40,000/- .
        </p>
        <p>
          D) “Bharatplays” will be actively depositing your bonus per rotation
          in your wallet
        </p>
        <p>
          E) Once You Have Achieved A Valid RO Requirement Of Rs. 40,000, Rs.
          4,000 Bonus Amount Will Be Unlocked To Use Instantly.
        </p>
        <h4>WHAT IS RO REQUIREMENT?</h4>
        <p>
          Well RO Means Roll Over, Which In Essence Means You Need To Complete A
          Certain Valid Roll Over To Unlock Your Bonus.The Valid RO Calculation
          Is As Follows:
        </p>

        <h4>TOTAL STAKE</h4>
        <p>
          In Other Words, Bets Must Be With Odds Of At Least Decimal 1.50 To Be
          Contributed Towards Bonus Rollover Requirements. So Here Is An
          Example: You Placed A Bet Of 100 Rs In Roulette On Black Color. So
          Your Stake Is: 100 Rs
        </p>
        <p>Now There Are 3 Scenarios:</p>
        <p>
          A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) = 5%
          Hence Your Valid RO = 0 As The 5%{`${"<"}`} 50%.
        </p>
      </>
    ),
  },
  {
    heading: "3rd Deposit Bonus",
    imgUrl: thirdDepositBonus,
    content: (
      <>
        <h4>50% 3rd Deposit Bonus</h4>

        <p>
          1. Percentage: Upto 50% bonus is awarded to Every Club member on his
          second deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 2000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) 5x Rollover of the amount you have deposited must be completed to
            unlock the bonus.
          </p>
          <p>ii) Minimum 100 INR deposit is required to avail deposit bonus.</p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>
        <p>5. Validation: Bonus is valid for 7 days after activation.</p>
        <p>
          6. Special Offer: “Bharatplays” will be actively depositing your bonus
          per rotation in your wallet
        </p>
        <p>
          7. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          8. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          9. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>10. For more information use our General Terms and Conditions.</p>
        <p>
          11. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
        <h4>What Is A Rollover Requirement?</h4>
        <p>Here Is An Example</p>
        <p>A) Your First Deposit Amount = Rs. 1,000</p>

        <p>B) Hence Your Bonus Of 400% = Rs. 4,000</p>
        <p>
          C) You Will Need To Complete An RO Requirement Of 40X Your Deposited
          Amount, Which Is Rs 1,000*40 = Rs. 40,000/- .
        </p>
        <p>
          D) “Bharatplays” will be actively depositing your bonus per rotation
          in your wallet
        </p>
        <p>
          E) Once You Have Achieved A Valid RO Requirement Of Rs. 40,000, Rs.
          4,000 Bonus Amount Will Be Unlocked To Use Instantly.
        </p>
        <h4>WHAT IS RO REQUIREMENT?</h4>
        <p>
          Well RO Means Roll Over, Which In Essence Means You Need To Complete A
          Certain Valid Roll Over To Unlock Your Bonus.The Valid RO Calculation
          Is As Follows:
        </p>

        <h4>TOTAL STAKE</h4>
        <p>
          In Other Words, Bets Must Be With Odds Of At Least Decimal 1.50 To Be
          Contributed Towards Bonus Rollover Requirements. So Here Is An
          Example: You Placed A Bet Of 100 Rs In Roulette On Black Color. So
          Your Stake Is: 100 Rs
        </p>
        <p>Now There Are 3 Scenarios:</p>
        <p>
          A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) = 100%
          Hence Your Valid RO = Rs. 100
        </p>
        <p>
          C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) = 5%
          Hence Your Valid RO = 0 As The 5%{`${"<"}`} 50%.
        </p>
      </>
    ),
  },
  // {
  //   heading: "100% Cashback Bonus",
  //   imgUrl: PromotionHighlight8,
  //   content: (
  //     <>
  //       <h4>100% Cashback Bonus</h4>

  //       <p>
  //         1. Percentage: Upto 100% cashback bonus is awarded to every New
  //         member on his first deposit on “Bharatplays”.
  //       </p>
  //       <p>2. Bonus Limit: Maximum Bonus you can receive is 2,000 INR</p>
  //       <p>
  //         3. Eligibility:
  //         <p>
  //           i) 10x Rollover of the amount you have deposited must be completed
  //           to unlock the bonus.
  //         </p>
  //         <p>
  //           ii) Minimum 1000 INR deposit is required to avail deposit bonus
  //         </p>
  //       </p>
  //       <p>
  //         4. Withdrawal:
  //         <p>
  //           i) You can withdraw your winnings for “UNLIMITED” times only on
  //           “BharatPlays”
  //         </p>
  //         <p>ii) Minimum 500 INR required in account for withdrawal.</p>
  //       </p>
  //       <p>5. Validation: Bonus is valid for 7 days after activation.</p>
  //       <p>
  //         6. Info: To get a bonus you need to fill your personal information
  //         in Profile.
  //       </p>

  //       <p>
  //         7. Multiple accounts are not allowed. One player will be detected in
  //         multi accounts creation all the bonuses will be removed and accounts
  //         will be blocked.
  //       </p>
  //       <p>
  //         8. This bonus can be used only for Sports betting and cannot be used
  //         in bonus wagering.
  //       </p>
  //       <p>9. For more information use our General Terms and Conditions.</p>
  //       <p>
  //         10. All restricted games excluded from bonus wagering will be
  //         supported with bonus money inaccessibility informational pop-up
  //         window with the following proposal to continue playing with your
  //         real money balance.
  //       </p>
  //       <h4>What Is A Rollover Requirement?</h4>
  //       <p>Here Is An Example</p>
  //       <p>A) Your First Deposit Amount = Rs. 1,000</p>

  //       <p>B) Hence Your Bonus Of 400% = Rs. 4,000</p>
  //       <p>
  //         C) You Will Need To Complete An RO Requirement Of 40X Your Deposited
  //         Amount, Which Is Rs 1,000*40 = Rs. 40,000/- .
  //       </p>
  //       <p>
  //         D) “Bharatplays” will be actively depositing your bonus per rotation
  //         in your wallet
  //       </p>
  //       <p>
  //         E) Once You Have Achieved A Valid RO Requirement Of Rs. 40,000, Rs.
  //         4,000 Bonus Amount Will Be Unlocked To Use Instantly.
  //       </p>
  //       <h4>WHAT IS RO REQUIREMENT?</h4>
  //       <p>
  //         Well RO Means Roll Over, Which In Essence Means You Need To Complete
  //         A Certain Valid Roll Over To Unlock Your Bonus.The Valid RO
  //         Calculation Is As Follows:
  //       </p>

  //       <h4>TOTAL STAKE</h4>
  //       <p>
  //         In Other Words, Bets Must Be With Odds Of At Least Decimal 1.50 To
  //         Be Contributed Towards Bonus Rollover Requirements. So Here Is An
  //         Example: You Placed A Bet Of 100 Rs In Roulette On Black Color. So
  //         Your Stake Is: 100 Rs
  //       </p>
  //       <p>Now There Are 3 Scenarios:</p>
  //       <p>
  //         A) You Win Rs.100 So P&L = Rs.100 So Valid RO = (|100|/100) = 100%
  //         Hence Your Valid RO = Rs. 100
  //       </p>
  //       <p>
  //         B) You Lose Rs.100 So P&L = Rs. -100 So Valid RO = (|-100|/100) =
  //         100% Hence Your Valid RO = Rs. 100
  //       </p>
  //       <p>
  //         C) You Win Rs.5 And Your Stake Was Rs.100 So Valid RO = (|5|/100) =
  //         5% Hence Your Valid RO = 0 As The 5%{`${"<"}`} 50%.
  //       </p>
  //     </>
  //   ),
  // },
  {
    heading: "Get 100% Lossback Bonus on Cricket League",
    imgUrl: ThisWorldCup,
    content: (
      <>
        <h4>Get 100% Lossback Bonus on Cricket League</h4>

        <p>
          <strong>‘You play we pay’</strong>
          The Cricket has arrived, and we're rolling out the red carpet for all
          cricket enthusiasts. At "BHARATPLAYS", we're proud to present an
          electrifying offer that'll make your cricketing thrill even more
          exhilarating. Get ready to dive into the action and enjoy an exclusive
          offer.
        </p>

        <h4>
          Instant Additional 100% Lossback Bonus On All Sports Exchange Bets!
        </h4>
        <h4>TERMS AND CONDITIONS</h4>
        <p>
          1. The Lossback Bonus Will Be Applied To ‘Lost EXCHANGE Bets - For The
          Day’ Placed During The Cricket 2023.
        </p>
        <p>
          2. The Bonus Amount For Qualifying Bets Placed During The Day Will Be
          Capped At INR 200.
        </p>

        <p>
          3. The Bonus Will Be Credited To Your "BHARATPLAYS" Account The Next
          Day.
        </p>
        <p>4. Bonus Will Be Expired Within 24-48 Hours.</p>
        <p>
          5. There Is No Limit To The Number Of Bets You Can Place To Earn The
          100% Lossback Bonus. ‘For The Day’.
        </p>
        <p>
          6. The Lossback Bonus Can Be Used To Place Future Bets On
          "BHARATPLAYS" As This Bonus Cannot Be Withdrawn. Only Winnings Can Be
          Withdrawn.
        </p>
        <p>7. This Promotion Is Valid Only For The Cricket 2023.</p>
        <p>
          8. "BHARATPLAYS" Reserves The Right To Disqualify Any User Found
          Engaging In Fraudulent Activities.
        </p>
        <p>9. "BHARATPLAYS" General Terms & Conditions Apply.</p>
      </>
    ),
  },
  {
    heading: "Loosing Commission",
    imgUrl: LoosingCommission,
    content: (
      <>
        <h4>Loosing Commission</h4>

        <p>
          Platinum Membership Users Are Eligible For 5% Lossback Bonus On Every
          Bet That You Do. So Let's Understand How It Works…
        </p>
        <p>1) Login To Your "BHARATPLAYS" Account</p>
        <p>
          2) Every sports bet Henceforth You Will Receive 5% Lossback Bonus In
          Your Main Wallet Every Monday After You Have Successfully placed a bet
          and have lost that wager.
        </p>

        <h4>TERMS AND CONDITIONS</h4>
        <p>1. The Loosing Commission Will Be Applied To Lost EXCHANGE Bets.</p>
        <p>
          2. The Bonus Amount For Qualifying Bets Placed During The Day Will Be
          Capped At INR 200.
        </p>

        <p>
          3. The Bonus Will Be Credited To Your "BHARATPLAYS" Account The Next
          Day.
        </p>
        <p>
          4. "BHARATPLAYS" Reserves The Right To Disqualify Any User Found
          Engaging In Fraudulent Activities.
        </p>
        <p>
          5. Only One Bonus Is Allowed Per Customer, Family, Address, Shared
          Computer, Shared IP Address, Any Identical Account Details Including
          E-Mail Address, Bank Account Details, Credit Card Infomation And
          Payment System Account. Any Misuse Of This Bonus Offer Will Lead To An
          Account Being Closed
        </p>
        <p>
          6. Bonus Amount Will Be Issued Into Your Main Wallet On Monday Which
          Will Calculate Your Weekly Losses.
        </p>
        <p>
          7. This Promotion Is Available On Your Weekly Losses And The Bonus
          Will Be Decided By One’s Overall P&L On The Platform To Avail This
          Bonus.
        </p>
        <p>8. Loosing Commission Expires Every 48 Hours.</p>
        <p>
          9. Multiple Accounts Will Not Be Considered For This Promotion. All
          Players Will Be Subject To Duplicate Checking. Bharatplays Reserves
          The Right To Disqualify Players If Collusion Or Any Foul Play Takes
          Place In The Promotion.
        </p>
        <p>
          10. "BHARATPLAYS" Reserve The Right To Amend, Cancel And Reclaim Or
          Refuse Any Promotion At Its Own Discretions.
        </p>
        <p>
          11. Bonus Amount Cannot Be Withdrawn But The Winnings From The Bonus
          Are Withdrawable.
        </p>
        <p>12. "BHARATPLAYS" General Terms & Conditions Apply</p>
        <p>13. Rollover Is Not Valid For Any Of The P2P Games</p>
      </>
    ),
  },
  {
    heading: "Flat 10% Rewards",
    imgUrl: Flat10Rewards,
    content: (
      <>
        <h4>Flat 10% Rewards</h4>

        <p>
          1. Percentage: 10% flat Reward is awarded to every New member on his
          second deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 1000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) Minimum 100 INR deposit is required to avail deposit Rewards.
          </p>
          <p>ii) No Conditions on this Rewards</p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>

        <p>5. Validation: Bonus is valid after 7 days from deposit.</p>
        <p>
          6. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          7. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          8. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>9. For more information use our General Terms and Conditions.</p>
        <p>
          10. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
      </>
    ),
  },
  {
    heading: "Flat 20% Rewards",
    imgUrl: Flat20Rewards,
    content: (
      <>
        <h4>Flat 20% Rewards</h4>

        <p>
          1. Percentage: 20% flat Reward is awarded to every New member on his
          first deposit on “Bharatplays”.
        </p>
        <p>2. Bonus Limit: Maximum Bonus you can receive is 2000 INR</p>
        <p>
          3. Eligibility:
          <p>
            i) Minimum 100 INR deposit is required to avail deposit Rewards.
          </p>
          <p>ii) No Conditions on this Rewards</p>
        </p>
        <p>
          4. Withdrawal:
          <p>
            i) You can withdraw your winnings for “UNLIMITED” times only on
            “BharatPlays”
          </p>
          <p>ii) Minimum 500 INR required in account for withdrawal.</p>
        </p>

        <p>5. Validation: Bonus is valid after 7 days from deposit.</p>
        <p>
          6. Info: To get a bonus you need to fill your personal information in
          Profile.
        </p>
        <p>
          7. Multiple accounts are not allowed. One player will be detected in
          multi accounts creation all the bonuses will be removed and accounts
          will be blocked.
        </p>
        <p>
          8. This bonus can be used only for Sports betting and cannot be used
          in bonus wagering.
        </p>
        <p>9. For more information use our General Terms and Conditions.</p>
        <p>
          10. All restricted games excluded from bonus wagering will be
          supported with bonus money inaccessibility informational pop-up window
          with the following proposal to continue playing with your real money
          balance.
        </p>
      </>
    ),
  },
  {
    heading: "Affiliates Terms & Condition",
    imgUrl: AffiliateTC,
    content: (
      <>
        <h4>"Bharatplays" Partner Programme Terms and Conditions</h4>

        <p>
          The following is an agreement between the relevant "Bharatplays"
          company referred to below, ("Bharatplays", "us" or "we"), and you
          ("you"), which contains the terms and conditions that apply to all
          members of the "Bharatplays" partner programme ("Agreement").
        </p>
        <p>
          {" "}
          Until 1st August 2015, wherever you are based, when you introduce
          Customers (as defined below) to us, you have your contract with and
          provide your services to, Bharatplays. This will continue for most of
          you.
        </p>
        <p>
          However, if you are based in Bulgaria, Denmark, Italy, Spain, Greece,
          Sweden, Russia or Mexico, please note that, with effect from 1st
          August 2015 when you provide partner services and introduce Customers
          to us, you are contracting with and providing your partner services to
          Bharatplays Limited. Any rights and obligations arising from your
          relationship with "Bharatplays" prior to that date have been and will
          be assumed by Bharatplays Limited with effect from 1st August 2015. It
          is understood that Bharatplays Limited will be procuring and managing
          your services in its capacity as a manager of marketing services for
          "Bharatplays".
        </p>
        <p>
          1. Our Rights and Obligations:
          <h5> Register your Customers</h5>
          <p>
            Wewill register your Customers and track their transactions. We
            reserve the right to refuse Customers (or to close their accounts)
            if necessary to comply with any requirements we may periodically
            establish. "Customer " means your visitors who joins our customer
            database (by way of registering at one or more of our
            products/websites including Sports, Casino, Poker, Games and Bingo)
            after arriving at the applicable site(s) via one of your click-thru
            URLs, which will be provided to you when you join. By opening an
            account with us, they will become our Customers and, accordingly,
            all of our rules, policies, and operating procedures will apply to
            them. Please be aware that subsequent to the US Congress passing the
            Unlawful Internet Gambling Enforcement Act of 2006 on 30 September
            2006, the Company no longer accepts customers who are resident in
            the USA.
          </p>
        </p>
        <h5> Provide you with a Dashboard</h5>
        <p>
          {" "}
          Wewill track your Customers' bets and plays making available to you a
          report summarising their activities, which you can access from this
          site
        </p>

        <h5> Pay a Affiliate Commission</h5>
        <p>
          Subject to clause 6, we will pay you Affiliate commission ("Affiliate
          Commission") of agreed upon percentage of the net profit (defined
          below) we earn from players directed from your site after they open an
          account with us and wager for real money at either Sports at
          "Bharatplays",
        </p>

        <p>
          2. Casino at "Bharatplays", Poker at "Bharatplays", Games at
          "Bharatplays" or Bingo at "Bharatplays".
          <h5>Modification</h5>
          <p>
            {" "}
            Wemaymodify any of the terms and conditions contained in this
            Agreement or replace it at any time and in our sole discretion by
            posting a change notice or a new agreement on our site.
            Modifications may include, for example, changes in the scope of
            available Affiliate Commissions and Partner programme rules. If any
            modification is unacceptable to you, your only recourse is to
            terminate this Agreement. Your continued participation in our
            partner programme following our posting of a change notice or new
            agreement on our site will constitute binding acceptance of the
            modification or of the new agreement.
          </p>
        </p>

        <h5>Subsection</h5>
        <p>
          You acknowledge and agree that regulations 9(1) and 9(2) (information
          to be provided by electronic means), 11(1) (placing of the order) of
          the Electronic Commerce (EC Directive) Regulations 2002 shall not
          apply to or have any effect on this Agreement.
        </p>

        <h5> Your Application</h5>
        <p>
          {" "}
          To become a member of our partner programme you will need to accept
          these terms and conditions by ticking the box indicating your
          acceptance and completing and submitting an online application form.
          The application form will form an integral part of this Agreement. We
          will in our sole discretion determine whether or not to accept your
          application and our decision is final and not subject to any right of
          appeal. We will notify you by email as to whether or not your
          application has been successful.
        </p>

        <p>
          3. Miscellaneous
          <h5> Governing Law</h5>
          <p>
            The laws of England will govern this Agreement, without reference to
            rules governing choice of law. Any action relating to this Agreement
            must be brought in England and you irrevocably consent to the
            jurisdiction of its courts.
          </p>
          <h5>Assignability</h5>
          <p>
            {" "}
            You may not assign this Agreement, by operation of law or otherwise,
            without our prior written consent. Subject to that restriction, this
            Agreement will be binding on, inure to the benefit of, and be
            enforceable against you and us and our respective successors and
            assigns.
          </p>
          <h5> Non-Waiver</h5>
          <p>
            {" "}
            Our failure to enforce your strict performance of any provision of
            this Agreement will not constitute a waiver of our right to
            subsequently enforce such provision or any other provision of this
            Agreement. No modifications, additions, deletions or interlineations
            of this Agreement are permitted or will be recognised by us. None of
            our employees or agents has any authority to make or to agree to any
            alterations or modifications to this Agreement or its terms
          </p>
          <h5>Remedies</h5>
          <p>
            {" "}
            Our rights and remedies hereunder shall not be mutually exclusive,
            that is to say that the exercise of one or more of the provisions of
            this Agreement shall not preclude the exercise of any other
            provision. You acknowledge, confirm, and agree that damages may be
            inadequate for a breach or a threatened breach of this Agreement
            and, in the event of a breach or threatened breach of any provision
            of this Agreement, we may seek enforcement or compliance by specific
            performance, injunction, or other equitable remedy. Nothing
            contained in this Agreement shall limit or affect any of our rights
            at law, or otherwise, for a breach or threatened breach of any
            provision of this Agreement, its being the intention of this
            provision to make clear that our rights shall be enforceable in
            equity as well as at law or otherwise.
          </p>
          <h5> Severability/Waiver</h5>
          <p>
            Whenever possible, each provision of this Agreement shall be
            interpreted in such a manner as to be effective and valid under
            applicable law but, if any provision of this Agreement is held to be
            invalid, illegal or unenforceable in any respect, such provision
            will be ineffective only to the extent of such invalidity, or
            unenforceability, without invalidating the remainder of this
            Agreement or any provision hereof. No waiver will be implied from
            conduct or failure to enforce any rights and must be in writing to
            be effective.
          </p>
        </p>

        <p>
          4. Your Rights and Obligations
          <h5>Linking to our Websites</h5>
          <p>
            By agreeing to participate in this Partner programme, you are
            agreeing to create and maintain unique links from your site to the
            websites which are owned, operated or branded by the "Bharatplays"
            group (“Bharatplays" Websites”). You may link to us with any of our
            banners, e-mails, articles or with a text link. This is the only
            method by which you may advertise on our behalf.
          </p>
          <h5> Minimum Affiliate Requirement</h5>
          <p>
            {" "}
            All "Bharatplays" partners are required to provide us with a minimum
            of 10 active Customers within a first month period of joining the
            program. Should a partner not reach this requirement the partner
            account may be closed, but you will have the option to open a new
            partner account. Active member definition: More than ₹1000
            deposit(Min 10 Players)
          </p>
          <h5>Spamming</h5>
          <p>
            Wewill terminate this Agreement immediately without recourse for you
            if there is any form of spamming or if you advertise our services in
            any other way. You shall not make any claims or representations, or
            give any warranties, in connection with us and you shall have no
            authority to, and shall not, bind us to any obligationss
          </p>
          <h5> Registering of Domain Names</h5>
          <p>
            {" "}
            You shall also refrain from registering (or applying to register)
            any domain name similar to any domain name used by or registered in
            the name of any member of the "Bharatplays" group, or any other name
            that could be understood to designate the "Bharatplays" group
          </p>
          <h5>Bidding on Brand Terms</h5>
          <p>
            You may not purchase or register keywords, search terms or other
            identifiers for use in any search engine, portal, sponsored
            advertising service or other search or Affiliate service and which
            are identical or similar to any of the "Bharatplays" group’s trade
            marks or otherwise include the word "Bharatplays",
            “Bharatplays"poker”, “Bharatplays"casino” or variations thereof, or
            include metatag keywords on the Partner Site which are identical or
            similar to any of the "Bharatplays" group’s trade marks
          </p>
          <h5> Agency Appointment</h5>
          <p>
            By this Agreement, we grant you the non-exclusive, non-assignable,
            right to direct Customers to any of our sites in accordance with the
            terms and conditions of this Agreement. This Agreement does not
            grant you an exclusive right or privilege to assist us in the
            provision of services arising from your Affiliates, and we obviously
            intend to contract with and obtain the assistance of others at any
            time to perform services of the same or similar nature as yours. You
            shall have no claim to Affiliate Commission or other compensation on
            business secured by or through persons or entities other than you
          </p>
          <h5> Approved Layouts</h5>
          <p>
            You will only use our approved advertising creative (banners, html
            mailers, editorial columns, images and logos) and will not alter
            their appearance nor refer to us in any promotional materials other
            than those that are available from www."Bharatplays" partners.com.
            The appearance and syntax of the hypertext transfer links are
            designed and designated by us and constitute the only authorised and
            permitted representation of our sites. In particular, please do not
            create a direct link to the promotional materials on any
            "Bharatplays" Website.
          </p>
          <h5> Good Faith</h5>
          <p>
            {" "}
            You will not knowingly benefit from known or suspected traffic not
            generated in good faith whether or not it actually causes us damage.
            We reserve the right to retain all amounts otherwise due to you
            under this Agreement if we have reasonable cause to believe of such
            traffic
          </p>
          <p>
            {" "}
            Wereserve the right to withhold partner payments and/or suspend or
            close accounts where partner customers are found to be abusing any
            "Bharatplays" offers or promotions whether with or without your
            knowledge. Such situations to include but not be limited to
            different customers betting both sides of an event or market so as
            to limit risk and claim bonuses
          </p>
          <h5> Responsibility for Your Site</h5>
          <p>
            You will be solely responsible for the development, operation, and
            maintenance of your site and for all materials that appear on your
            site. For example, you will be solely responsible for ensuring that
            materials posted on your site are not libellous or otherwise
            illegal. We disclaim all liability for these matters. Further, you
            will indemnify and hold us harmless from all claims, damages, and
            expenses (including, without limitation, legal fees) arising
            directly or indirectly out of the development, operation,
            maintenance, and contents of your site. The "Bharatplays" partner
            programme is intended for your direct participation. You shall not
            open partner accounts on behalf of other participants. Opening a
            partner account for a third party, brokering a partner account or
            the transfer of a partner account is not accepted. Partners wishing
            to transfer an account to another beneficial account owner must
            request permission to do so by Contacting Us. Approval is solely at
            our discretion.
          </p>
          <h5>Affiliation</h5>
          <p>
            {" "}
            No affiliation can be made between your site and any "Bharatplays"
            Website.
          </p>
          <h5> Licence to use Marks</h5>
          <p>
            {" "}
            Wehereby grant to you a non-exclusive, non-transferable licence,
            during the term of this Agreement, to use our trade name, trade
            marks, service marks, logos and any other designations, which we may
            from time to time approve ("Marks") solely in connection with the
            display of the promotional materials on your site. This licence
            cannot be sub-licensed, assigned or otherwise transferred by you.
            Your right to use the Marks is limited to and arises only out of
            this licence. You shall not assert the invalidity, unenforceability,
            or contest the ownership of the Marks in any action or proceeding of
            whatever kind or nature, and shall not take any action that may
            prejudice our rights in the Marks, render the same generic, or
            otherwise weaken their validity or diminish their associated
            goodwill. You must notify us immediately if you become aware of the
            misuse of the Marks by any third party
          </p>
          <h5> Confidential Information</h5>
          <p>
            {" "}
            During the term of this Agreement, you may be entrusted with
            confidential information relating to our business, operations, or
            underlying technology and/or the Partner programme (including, for
            example, Affiliate Commissions earned by you under the Partner
            programme). You agree to avoid disclosure or unauthorised use of any
            such confidential information to third persons or outside parties
            unless you have our prior written consent and that you will use the
            confidential information only for purposes necessary to further the
            purposes of this Agreement. Your obligations with respect to
            confidential information shall survive the termination of this
            Agreement
          </p>
          <h5> Data Protection</h5>
          <p>
            {" "}
            Where applicable, for the purposes of the Data Protection Act 2018,
            and the General Data Protection Regulation, "Bharatplays" remains an
            independent Data Controller. For the avoidance of doubt no Data
            Controller / Data Processor relationship or Joint Data Controller
            relationship is created by this Agreement. Nevertheless, by agreeing
            to participate in this Partner programme you shall at all times
            comply with the Data Protection Act 2018, the General Data
            Protection Regulation, and the Privacy and Electronic Communications
            (EC Directive) Regulations 2003, and/or any other related or similar
            applicable legislation. Failure to do so will be cause for the
            termination of this Agreement with immediate effect
          </p>
          <p>
            Where, at any time, "Bharatplays" shares data with you it is at
            "Bharatplays"’s sole discretion, may be subject to separate terms
            and conditions, and may be declined or ceased at any time. Should
            you state that you have a data subject’s permission to request their
            personal data from "Bharatplays" then "Bharatplays" may require
            evidence of this in the form of relevant terms and conditions, or
            specific consent for the sharing
          </p>
        </p>

        <p>
          <h5>The Affiliatel Commission</h5>
          <p>
            {" "}
            Subject to clause 6, you will earn Affiliate Commission of agreed
            upon Percentage on the net profit of your referred Customers. Net
            profit is defined as:- on Sports activities, all gross monies
            received by us in respect of all settled bets made by Customers
            after deducting;
            <p> i) monies paid out to Customers as winnings;</p>
            <p>
              {" "}
              ii) monies paid in the form of betting duties or taxes (or
              reasonable provisions in respect thereof)
            </p>
            <p> iii) bad debts; </p>
            <p>iv) fraud; </p>
            <p> v) returned stakes;</p>
            <p>
              vi) transactions which are reversed by instruction from the
              card-holder's bank (commonly referred to as charge-backs); and{" "}
            </p>
            <p> vii) voids and </p>
            <p>viii) bet/deposit bonuses.</p>
          </p>
        </p>

        <p>
          - on Casino activities, the value of opening balance with the addition
          of funds transferred in less
          <p> i) funds transferred out </p>
          <p> ii) closing balance </p>
          <p> iii) credits made to users </p>
          <p>
            iv) monies paid in the form of duties or taxes (or reasonable
            provisions in respect thereof){" "}
          </p>
          <p> v) licensing fees </p>
          <p> vi) charge-backs </p>
          <p> vii) bad debts and </p>
          <p> viii) fraud.</p>
        </p>

        <p>
          - on Poker activity, the Real Player Value (defined below) less
          <p>
            {" "}
            i) monies paid in the form of duties or taxes (or reasonable
            provisions in respect thereof){" "}
          </p>
          <p> ii) licensing fees, </p>
          <p> iii) poker chips bonus, </p>
          <p> iv) charge-backs, </p>
          <p> v) bad debts and </p>
          <p> vi) fraud.</p>
        </p>

        <p>
          - on Games activity, the gross stake, less
          <p> i) voids,</p>
          <p> ii) returns, </p>
          <p>
            {" "}
            iii) monies paid in the form of duties or taxes (or reasonable
            provisions in respect thereof)
          </p>
          <p> iv) licensing fees,</p>
          <p> v) credits made to users, </p>
          <p> vi) bonuses </p>
          <p> vii) charge-backs,</p>
          <p> viii) bad debts and </p>
          <p> ix) fraud.</p>
        </p>

        <p>
          - on Bingo activity, the gross stake, less
          <p> i) voids,</p>
          <p> ii) returns, </p>
          <p>
            {" "}
            iii) monies paid in the form of duties or taxes (or reasonable
            provisions in respect thereof)
          </p>
          <p> iv) licensing fees,</p>
          <p> v) credits made to users, </p>
          <p> vi) bonuses </p>
          <p> vii) charge-backs,</p>
          <p> viii) bad debts and </p>
          <p> ix) fraud.</p>
        </p>

        <p>
          {" "}
          Real Player Value is based in part on rake and fee in accordance with
          our FAQ section for poker, and in part on the net deposits of
          customers onto poker tables. This ensures partners are rewarded for
          bringing recreational poker players who contribute positively to the
          poker liquidity and ecology.
        </p>

        <p>
          {" "}
          Weretain the right to change the Affiliate Commission percentage and
          method of calculation of Affiliate Commission as we wish in accordance
          with clause 1.4. Payable Commission Calculations
        </p>

        <p>
          Partners are eligible for payment on the balance of their Sports,
          Casino, Poker, Games or Bingo earnings. Negative commission balances
          in either Sports, Casino, Poker, Games or Bingo will be deducted from
          available commissions
        </p>

        <p>
          {" "}
          <h5>Affiliate Commission Payouts</h5>
          <h5> Affiliate Fee Payout Requests</h5>
          <p>
            Your "Bharatplays" partner account must have a minimum of 10 active
            Customers before you will be eligible for Affiliate Commission
            payment. Subject to clause 6, you are entitled to one payout per
            week, providing you have fulfilled the above Customer requirement at
            the end of the preceding Week.
          </p>
          <p>
            {" "}
            You agree that a self-billing procedure is in place between you and
            "Bharatplays" in respect of payment of Affiliate Commissions. Under
            the self-billing procedure, if you wish to request payment of any
            Affiliate Commission you must make the payment request via your
            account on the "Bharatplays" partner system following which we will
            raise a VAT invoice showing all required particulars for the
            applicable Affiliate Commission on your behalf, which you agree to
            accept. Payment of any invoice will be made in accordance with and
            subject to the terms set out below. You agree not to raise any VAT
            invoice in respect of your partner services. You agree to notify us
            if you make any changes to the ownership of your business or your
            VAT registration. This self-billing procedure will remain in place
            for the term of this Agreement.
          </p>
          <p>
            {" "}
            If you contract with Bharatplays Limited in accordance with the
            terms of this Agreement the following shall apply to you: (i) it is
            understood that no VAT will be charged on the invoices and that in
            relation to partner services to Bharatplays Limited that company
            will account for any VAT under the reverse charge in Malta; and (ii)
            you agree and acknowledge that any and all VAT obligations which you
            may have under Maltese VAT legislation remain exclusively your
            obligations and that "Bharatplays", by agreeing to issue invoices on
            your behalf in accordance with the self-billing procedure referred
            to above, does not in any way accept and/or assume any
            responsibility to ensure compliance with such VAT obligations beyond
            issuing the invoice on your behalf
          </p>
          <p>
            {" "}
            Likewise, if you contract with Bharatplays, it is understood that
            your services are outside the scope of VAT and no VAT is chargeable.
          </p>
          <p>
            {" "}
            The minimum payout request for earned Affiliate Commission per Week
            depends upon the currency denomination of your partner account as
            shown below. There is no maximum limit. Minimum Limit:- 5k Affiliate
            Commission payments may be requested:
            <p>
              {" "}
              i) to a nominated "Bharatplays" account- recommended, the fastest
              and easiest way to withdraw money via your choice of any of the
              payment methods supported by "Bharatplays".com
            </p>
            <p>ii) bank wire.</p>
          </p>
        </p>

        <p>
          {" "}
          Please be aware that although "Bharatplays" will not deduct any
          charges for arranging a bank wire, your own bank may choose to levy a
          charge on such transactions. Bank wires will appear on your bank
          statement with reference ‘Bharatplays’, with the exception of payments
          made to members of the partner programme who are based in Bulgaria,
          Denmark, Italy, Spain, Greece, Sweden or Mexico, where the reference
          will be to "Bharatplays Limited" with effect from 1st August 2015
        </p>

        <p>
          {" "}
          Please note that if you are a partner resident in the US, Italy or
          Mexico then option (i) above will not be available to you.
        </p>

        <p>
          {" "}
          An active referred Customer is defined as a visitor to your site(s)
          who has clicked through to a "Bharatplays" Website via your click-thru
          link, has registered as a customer, deposited funds to their customer
          account and conducted betting or playing activity in "Bharatplays"
          products Sports at "Bharatplays", Casino at "Bharatplays", Poker at
          "Bharatplays", Games at "Bharatplays" and/or Bingo at "Bharatplays".
        </p>

        <p>
          Term and Termination, Consequences and Unsuitable Sites
          <h5>Term and Termination</h5>
          <p>
            {" "}
            The term of this Agreement will begin when you are approved as a
            partner and will be continuous unless and until either party
            notifies the other in writing that it wishes to terminate the
            Agreement, in which case this Agreement will be terminated
            immediately. Termination is at will, with or without reason, by
            either party. For purposes of notification of termination, delivery
            via e-mail is considered a written and immediate form of
            notification.
          </p>
          <h5>Consequence</h5>
          <p>
            Upon termination you must remove all of our banners/icons from your
            site and disable all links from your site to all "Bharatplays"
            Websites. All rights and licenses given to you in this Agreement
            shall immediately terminate. You will return to us any confidential
            information, and all copies of it in your possession, custody and
            control and will cease all uses of our Marks.
          </p>
          <h5> Unsuitable Sites</h5>
          <p>
            {" "}
            Wemayterminate this Agreement if we determine (in our sole
            discretion) that your site is unsuitable. Unsuitable sites include,
            but are not limited to, those that: are aimed at children, display
            pornography or other illegal sexual acts, promote violence, promote
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age, promote illegal activities
            or violate intellectual property rights or breach any relevant
            advertising regulations or codes of practice
          </p>
          <h5> Duplicate Accounts and Self Affiliates</h5>
          <p>
            {" "}
            You shall not open more than one partner account without our prior
            written consent nor will you earn commission on your own or related
            person's "Bharatplays" Sports, Casino, Poker, Games or Bingo
            account. The programme is intended for professional website
            publishers.
          </p>
          <h5> Limitation of Liability</h5>
          <p>
            Wewill not be liable for indirect, special, or consequential damages
            (or any loss of revenue, profits, or data) arising in connection
            with this Agreement or the partner programme, even if we have been
            advised of the possibility of such damages. Further, our aggregate
            liability arising with respect to this Agreement and the partner
            programme will not exceed the total Affiliate Commissions paid or
            payable to you under this Agreement. Nothing in this Agreement shall
            be construed to provide any rights, remedies or benefits to any
            person or entity not a party to this Agreement. Our obligations
            under this Agreement do not constitute personal obligations of our
            directors, employees or shareholders. Any liability arising under
            this Agreement shall be satisfied solely from the Affiliate
            Commission generated and is limited to direct damages.
          </p>
          <h5> Independent Investigation</h5>
          <p>
            You acknowledge that you have read this Agreement and agree to all
            its Terms and Conditions. You understand that we may at any time
            (directly or indirectly) solicit customer Affiliates on terms that
            may differ from those contained in this Agreement or operate or
            contract with websites that are similar to or compete with your
            website. You have independently evaluated the desirability of
            participating in this Partner programme and are not relying on any
            representation, guarantee, or statement other than as set out in
            this Agreement.
          </p>
          <h5> Continued Promotion</h5>
          <p>
            {" "}
            You shall incorporate and prominently and continually display the
            most up-to-date links provided by us on all pages of your website in
            a manner and location agreed by us and you shall not alter the form,
            location or operation of the links without our prior written
            consent. You are eligible for Affiliate Commissions based upon your
            continued promotion of Sports at "Bharatplays", Casino at
            "Bharatplays", Poker at "Bharatplays", Games at "Bharatplays" or
            Bingo at "Bharatplays". We reserve the right to reduce Affiliate
            Commission percentages if you reduce your efforts to recruit new
            Customers. Your reduced or suspended promotion of our sites will be
            deemed to represent your termination of this Agreement.
          </p>
          <h5> Relationship of Parties</h5>
          <p>
            Weand you are independent contractors and nothing in this Agreement
            will create any partnership, joint venture, agency, franchise, sales
            representative, or employment relationship between us. You will have
            no authority to make or accept any offers or representations on our
            behalf. You will not make any statement, whether on your site or
            otherwise, that would contradict anything in this Agreement
          </p>
          <h5>Indemnity</h5>
          <p>
            {" "}
            You shall defend, indemnify, and hold us, our directors, employees
            and representatives harmless from and against any and all
            liabilities, losses, damages and costs, including legal fees,
            resulting from, arising out of, or in any way connected with (a) any
            breach by you of any warranty, representation or term contained in
            this Agreement, (b) the performance of your duties and obligations
            under this Agreement, (c) your negligence or (d) any injury caused
            directly or indirectly by your negligent or intentional acts or
            omissions, or the unauthorised use of our banners and links or this
            Partner programme.
          </p>
          <h5>Disclaimers</h5>
          <p>
            {" "}
            Wemakenoexpress or implied warranties or representations with
            respect to the Partner programme, about ourselves or the Affiliate
            Commission payment arrangements (including, without limitation,
            functionality, warranties of fitness, merchantability, legality or
            non-infringement), and do not express nor imply any warranties
            arising out of a course of performance, dealing, or trade usage. In
            addition, we make no representation that the operation of our sites
            will be uninterrupted or error-free and will not be liable for the
            consequences if there are any. In the event of a discrepancy between
            the reports offered in the "Bharatplays" partners.com system and the
            "Bharatplays" database, the database shall be deemed accurate
          </p>
        </p>
      </>
    ),
  },
  {
    heading: "Free Tickets Terms & Condition",
    imgUrl: FreeTicketsTC,
    content: (
      <>
        <h4>
          Terms and Conditions: Free Tickets Offer for Bharatplays Players
        </h4>

        <p>
          1. **Eligibility:**
          <p>
            1.1 The Free Tickets Offer ("Offer") is available to registered
            players ("Players") of Bharatplays betting platform ("Bharatplays").
          </p>
          <p>
            1.2 Players must meet all eligibility criteria outlined in these
            Terms and Conditions to qualify for the Offer.
          </p>
        </p>

        <p>
          {" "}
          2. **Offer Details:**
          <p>
            {" "}
            2.1 Bharatplays may, at its sole discretion, offer free tickets to
            Players for participation in select betting events or promotions.
          </p>
          <p>
            2.2 The issuance of free tickets is subject to availability and may
            be limited based on predetermined criteria set by Bharatplays.
          </p>
        </p>

        <p>
          {" "}
          2. **Offer Details:**
          <p>
            {" "}
            2.1 Bharatplays may, at its sole discretion, offer free tickets to
            Players for participation in select betting events or promotions.
          </p>
          <p>
            2.2 The issuance of free tickets is subject to availability and may
            be limited based on predetermined criteria set by Bharatplays.
          </p>
        </p>

        <p>
          3. **Redemption:**
          <p>
            3.1 Free tickets provided under this Offer are non-transferable and
            cannot be redeemed for cash or any other form of compensation.
          </p>
          <p>
            3.2 Players must use free tickets within the specified timeframe and
            in accordance with the terms outlined for the relevant betting event
            or promotion.
          </p>
          <p>
            3.3 Bharatplays reserves the right to revoke or cancel free tickets
            if it determines, in its sole discretion, that a Player has violated
            these Terms and Conditions or engaged in any fraudulent activity.
          </p>
        </p>

        <p>
          4. **Availability and Modification:**
          <p>
            4.1 Bharatplays reserves the right to modify, suspend, or terminate
            the Offer at any time without prior notice.
          </p>
          <p>
            4.2 The availability of free tickets and the terms of the Offer are
            subject to change at the discretion of Bharatplays.
          </p>
        </p>

        <p>
          5. **Limitation of Liability:**
          <p>
            5.1 Bharatplays shall not be liable for any loss, damage, or injury
            arising from the use or inability to use the free tickets provided
            under this Offer
          </p>
          <p>
            5.2 Players acknowledge and agree that participation in betting
            activities carries inherent risks, and Bharatplays shall not be
            responsible for any losses incurred as a result of such
            participation
          </p>
        </p>

        <p>
          6. **Compliance with Laws:**
          <p>
            6.1 Players are solely responsible for ensuring compliance with all
            applicable laws and regulations governing online betting activities
            in their jurisdiction.
          </p>
          <p>
            6.2 Bharatplays reserves the right to withhold or cancel free
            tickets if it determines that a Player's participation in the Offer
            violates any applicable laws or regulations.
          </p>
        </p>

        <p>
          7. **General Terms:**
          <p>
            7.1 These Terms and Conditions constitute the entire agreement
            between Players and Bharatplays regarding the Offer and supersede
            any prior agreements or understandings, whether written or oral.
          </p>
          <p>
            7.2 The failure of Bharatplays to enforce any provision of these
            Terms and Conditions shall not constitute a waiver of such provision
            or the right to enforce it at a later time.
          </p>
          <p>
            7.3 These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction], and any disputes
            arising out of or relating to the Offer shall be subject to the
            exclusive jurisdiction of the courts of [Your Jurisdiction].
          </p>
        </p>
        <p>
          {" "}
          By participating in the Offer, Players agree to be bound by these
          Terms and Conditions. Bharatplays reserves the right to amend or
          update these Terms and Conditions at any time without prior notice.
          Players are encouraged to review the Terms and Conditions periodically
          for any changes or updates.
        </p>
      </>
    ),
  },
];

export const highLightsData = [
  {
    id: 1,
    imageUrl: PromotionHighlight,
    highLightName: "Promotion",
    stories: [
      {
        id: 1,
        imageUrl: PromotionHighlight,
        highLightName: "Promotion",
      },
    ],
  },
  {
    id: 2,
    imageUrl: PromotionHighlight1,
    highLightName: "Promotion",
    stories: [
      {
        id: 1,
        imageUrl: PromotionHighlight1,
        highLightName: "Promotion",
      },
    ],
  },
  {
    id: 3,
    imageUrl: PromotionHighlight2,
    highLightName: "Promotion",
    stories: [
      {
        id: 1,
        imageUrl: PromotionHighlight2,
        highLightName: "Promotion",
      },
    ],
  },
  {
    id: 4,
    imageUrl: PromotionHighlight3,
    highLightName: "Promotion",
    stories: [
      {
        id: 1,
        imageUrl: PromotionHighlight3,
        highLightName: "Promotion",
      },
    ],
  },
  {
    id: 5,
    imageUrl: PromotionHighlight4,
    highLightName: "Promotion",
    stories: [
      {
        id: 1,
        imageUrl: PromotionHighlight4,
        highLightName: "Promotion",
      },
    ],
  },
];

export const tagsData = [
  {
    heading: "Platinum Bonus",
    imgUrl: PlatinumImg,
    content: (
      <>
        <h4>What is a Platinum Bonus</h4>
        <p>
          Introducing the Platinum Membership from Bharatplays– the ultimate
          “VIP” access to a world of thrilling possibilities!
        </p>

        <p>Here's why you can't afford to miss out:</p>
        <p>✨ 25%Lifetime Losing Commission has your back for your lifetime.</p>
        <p>
          ✨ 5%Refill Bonus for a Lifetime and maximize chances of striking it
          big.
        </p>
        <p>✨10%Referral Bonus on your referrals deposit.</p>
        <p>✨ FreeUnlimited Offline Cash Deposit and Withdrawal Service.</p>
        <p>
          ✨ Access to the Elite Prediction Analyst for strategic & smarter
          bets.
        </p>

        <p> Why Choose Bharatplays?</p>
        <p>✅ Min₹100Deposit.</p>
        <p>✅ Self Deposit and Withdrawal.</p>
        <p>✅ 20%Instant No Conditions Bonus on 1st Deposit.</p>
        <p>✅ NoCommission on Winnings.</p>
        <p>✅ Best in the Market 0% Margin Odds</p>

        <h4>TERMS AND CONDITIONS</h4>
        <p>
          1. *Limited-time Offer Eligibility:* The special limited-time offer
          for Platinum Membership is available to the first 100 members who make
          a deposit of 1 lakh INR. After this quota is filled, the deposit
          requirement increases to 50 lakh INR.
        </p>
        <p>
          2. *Membership Activation:* To activate the Platinum Membership,
          members must make the specified deposit within the stipulated time
          frame. Failure to do so will result in the standard deposit
          requirement.
        </p>
        <p>
          3. *Refill Bonus Eligibility:* The 5% Refill Bonus for a lifetime is
          applicable to Platinum Members only. Standard members are not eligible
          for this exclusive offer.
        </p>
        <p>
          4. *Lifetime Losing Commission:* The 5% Lifetime Losing Commission
          cashback is a permanent benefit for Platinum Members, ensuring
          continuous support even during challenging times.
        </p>
        <p>
          5. *Referral Bonus Conditions:* The 10% Referral Bonus is applicable
          to the deposited amount made by the referred member. Only Platinum
          Members can avail of this bonus.
        </p>
        <p>
          6. *Free Unlimited Offline Services:* The Free Unlimited Offline Cash
          Deposit and Withdrawal Service is available exclusively for Platinum
          Members. Standard members are not eligible for this service.
        </p>

        <p>
          7. *Access to Elite Prediction Analyst:* Platinum Members have
          exclusive access to the Elite Prediction Analyst for strategic
          insights. This benefit is not available to standard members.
        </p>
        <p>
          8. *Special Limited-time Offer Expiry:* The special limited-time offer
          for the first 100 members expires once the 100 spots are claimed or
          when the specified time frame lapses.
        </p>
        <p>
          9. *Threshold Increase:* After the first 100 members, the Platinum
          Membership deposit threshold increases to 50 lakh INR. Members who
          wish to enjoy the Platinum perks must adhere to the new deposit
          requirement.
        </p>
        <p>
          10. *Amendment and Cancellation:* "BHARATPLAYS" reserves the right to
          amend, cancel, or refuse any aspect of the Platinum Membership offer
          at its discretion.
        </p>
        <p>
          11. *Bonus Withdrawal and Expiry:* While the bonus amount cannot be
          withdrawn, winnings derived from the bonus are fully withdrawable. All
          bonus amounts expire within 24 hours of issuance.:
        </p>
        <p>
          12. *Fair Play Policy:* Multiple accounts, collusion, or any form of
          foul play will result in disqualification from the Platinum Membership
          offer. "BHARATPLAYS" retains the right to take appropriate action in
          such instances.
        </p>

        <p>
          13. *General Terms Apply:* The general terms and conditions of
          "BHARATPLAYS" are applicable to the Platinum Membership offer.
        </p>

        <p>14. The Loosing Commission Will Be Applied To All Lost Bets.</p>
        <p>
          15. The Bonus Amount For Qualifying Bets Placed During The Day Will Be
          Capped At INR 50,000
        </p>
        <p>
          16. The Bonus Will Be Credited To Your "BHARATPLAYS" Account The Next
          Day.
        </p>
        <p>
          17. "BHARATPLAYS" Reserves The Right To Disqualify Any User Found
          Engaging In Fraudulent Activities.
        </p>
        <p>
          18. Only One Bonus Is Allowed Per Customer, Family, Address, Shared
          Computer, Shared IP Address, Any Identical Account Details Including
          E-Mail Address, Bank Account Details, Credit Card Infomation And
          Payment System Account. Any Misuse Of This Bonus Offer Will Lead To An
          Account Being Closed
        </p>
        <p>
          19. Bonus Amount Will Be Issued Into Your Main Wallet On Monday Which
          Will Calculate Your Weekly Losses.
        </p>
        <p>
          20. This Promotion Is Available On Your Weekly Losses And The Bonus
          Will Be Decided By One’s Overall P&L On The Platform To Avail This
          Bonus.
        </p>
        <p>21. Loosing Commission Expires Every 48 Hours.</p>
        <p>
          22. Multiple Accounts Will Not Be Considered For This Promotion. All
          Players Will Be Subject To Duplicate Checking. Bharatplays Reserves
          The Right To Disqualify Players If Collusion Or Any Foul Play Takes
          Place In The Promotion.
        </p>
        <p>
          23. "BHARATPLAYS" Reserve The Right To Amend, Cancel And Reclaim Or
          Refuse Any Promotion At Its Own Discretions.
        </p>
        <p>
          24. Bonus Amount Cannot Be Withdrawn But The Winnings From The Bonus
          Are Withdrawable.
        </p>
        <p>25. "BHARATPLAYS" General Terms & Conditions Apply</p>
      </>
    ),
  },
];

export const reelsData = [
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
  {
    id: 1,
    videoUrl:
      "https://drive.google.com/file/d/1FhhKDue1IpkqS09VRsi9gS4Phezx383R/preview?start=1",
    name: "test",
    key: "testKey",
  },
];
