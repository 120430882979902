import React, { useEffect, useState } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/upi.png";
import upi from "../../../../assets/images/paytm.png";
import rupee1 from "../../../../assets/images/icons/rupee1.png";
import bitcoin from "../../../../assets/images/icons/bitcoin.png";

import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import WhatsappIcon1 from "../../../../assets/images/icons/cash.png";
import VideoComponent from "../../../components/videoComponent";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const [showVideoModal, setShowVideoModal] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { appDetails } = useSelector((state) => state.app);
  const [activetab, setActiveTab] = useState("deposit");

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    console.log("item", item);
    dispatch(setSelectedGateway(item));
    navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="DepositSection">
          <div className="gatewaySection">
            <div className="gatewaypart">
              <Tabs
                defaultActiveKey="deposit"
                id="uncontrolled-tab-example"
                className=""
                onSelect={(e) => {
                  setActiveTab(e);
                }}
              >
                <Tab
                  eventKey="deposit"
                  title="UPI"
                  className="deposit_class mt-5"
                >
                  <div className="supportIconDeposit cards1">
                    <a
                      href={`tel://${appDetails?.PHONE}`}
                      className="card-icon"
                    >
                      <img src={WhatsappIcon1} />
                    </a>{" "}
                    <p>Cash</p>
                  </div>
                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "auto")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            {item.code === "hpay" ? (
                              <img src={upi} />
                            ) : (
                              <img src={DepositImg} />
                            )}
                          </div>

                          <p>{item?.name}</p>
                        </div>
                      );
                    })}

                  <div className="DepositVideo">
                    {" "}
                    <div className="OtherVideo">
                      {showVideoModal && activetab === "deposit" && (
                        <VideoComponent
                          show={showVideoModal}
                          handleClose={setShowVideoModal}
                          showVideo={showVideo}
                          videoSrc={
                            "https://iframe.mediadelivery.net/embed/182883/a30e5436-292e-4712-95f1-33bccebd0d16?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                          }
                        />
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="bank"
                  title="Scan & Pay"
                  className="bank_deposit_class mt-5"
                >
                  <div className="supportIconDeposit cards1">
                    <a
                      href={`tel://${appDetails?.PHONE}`}
                      className="card-icon"
                    >
                      <img src={WhatsappIcon1} />
                    </a>{" "}
                    <p>Cash</p>
                  </div>
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a.order - b.order)
                    ?.filter((f) => f.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            {item.code === "m_binance" ? (
                              <img src={bitcoin} />
                            ) : item.code === "upi" ? (
                              <img src={rupee1} />
                            ) : (
                              <img src={DepositImg} />
                            )}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      );
                    })}

                  <div className="DepositVideo">
                    {" "}
                    <div className="OtherVideo">
                      {showVideoModal && activetab === "bank" && (
                        <VideoComponent
                          show={showVideoModal}
                          handleClose={setShowVideoModal}
                          showVideo={showVideo}
                          videoSrc={
                            "https://iframe.mediadelivery.net/embed/182883/d766061a-7bc2-4462-a474-1866bb4eea51?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                          }
                        />
                      )}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default GatewaysList;
