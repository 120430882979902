import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const PostsComponent = ({ data }) => {
  const [key, setKey] = useState("welcome");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };
  return (
    <div>
      <div
        id="controlled-tab-example"
        onSelect={(k) => setKey(k)}
        className="mb-3 BonusPostSection"
        activeKey={key}
      >
        {data.map((list, index) => {
          return (
            <div title={list.heading} eventKey={index} className="BonusPost">
              <img
                src={list.imgUrl}
                onClick={() => {
                  setShowModal(true);
                  setModalContent(list);
                }}
              />
            </div>
          );
        })}
      </div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        className="BonusPostModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{modalContent && modalContent?.content}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default PostsComponent;
