import React, { PureComponent } from "react";
import { useSelector } from "react-redux";

import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const AboutUs = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main mt-5">
          <div className="container">
            <h2 className="sectionTitle"> About Us</h2>
            <div className="terms-content">
              <h3
                style={{
                  margin: "20px 0 20px 0",
                }}
              >
                🎉 Welcome to BharatPlays: Where Thrills and Fortunes Await! 🎉
              </h3>
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0 20px 0",
                  fontWeight: "700",
                }}
              >
                Are you ready to embark on an exhilarating journey of excitement
                and rewards? Look no further, because BharatPlays is here to
                redefine your betting experience like never before!
              </h4>
              <p>
                🔥 Join our Telegram Channel: Get front-row access to the latest
                odds, expert insights, and updates powered by state-of-the-art
                AI technology. Stay ahead of the game and make informed
                decisions with our real-time analysis.
              </p>
              <p>
                💰 Claim your Exclusive Bonus: Sign up now to unlock an
                incredible up to 50,000 INR bonus on your first deposits!
                Kick-start your winning spree and multiply your stakes with our
                generous welcome offer.
              </p>
              <p>
                🎯 Unleash the Power of Prediction: Our cutting-edge AI
                technology is your secret weapon for strategic betting. Leverage
                the wisdom of advanced algorithms to maximize your chances of
                success.
              </p>
              <p>
                🏆 Win Big, Win Bold: At BharatPlays, we're not just a betting
                company; we're your partners in the pursuit of victory. Whether
                you're a seasoned pro or new to the game, our platform is
                designed to cater to all levels of expertise.
              </p>
              <p>
                🌐 Seamless and Secure: Experience a user-friendly platform that
                guarantees the safety of your transactions and personal
                information. Your peace of mind is our top priority.
              </p>
              <p>
                🤝 Join the Community: Become a part of our vibrant community of
                like-minded enthusiasts who share your passion for sports and
                betting. Engage in discussions, exchange tips, and celebrate
                wins together!
              </p>
              <p>
                🚀 Don't Miss Out: The thrill of victory and the rush of
                strategic betting await you. Sign up on our website and follow
                us on Telegram to ensure you're always in the loop. It's time to
                elevate your betting game with BharatPlays!
              </p>
              <a href="#">
                <p>📢 Join Now: https://bharatplays.com/</p>
              </a>
              <a
                href={
                  appDetails.TELEGRAM != ""
                    ? appDetails.TELEGRAM
                    : "#"
                }
                target={appDetails.TELEGRAM != "" ? "_blank" : ""}
              >
                <p> 📱 Follow on Telegram: https://t.me/bharatplays</p>
              </a>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
