import React from "react";
import { reelsData } from "../../../../../lib/data/bonus.data";
const ReelsComponent = () => {
  return (
    <div>
      <div id="controlled-tab-example" className="mb-3 BonusPostSection">
        {reelsData.map((reel, index) => {
          return (
            <div title={reel.name} key={index} className="BonusPost">
              <iframe src={reel.videoUrl} allow="autoplay"></iframe>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ReelsComponent;
