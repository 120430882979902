import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ProgressBarComponent from "../../../components/progressBar";

const StoryModal = ({
  showStoryModal,
  setShowStoryModal,
  selectedHighLight,
}) => {
  const [currentStory, setCurrentStory] = useState(
    selectedHighLight.stories[0]
  );
  const [startProgressBar, setStartProgressBar] = useState(true);

  useEffect(() => {
    let currIdx = 0;
    const intervals = setInterval(() => {
      if (currIdx < selectedHighLight.stories.length - 1) {
        currIdx = currIdx + 1;
        setCurrentStory(selectedHighLight.stories[currIdx]);
      } else {
        setShowStoryModal(false);
        clearInterval(intervals);
      }
      setStartProgressBar(true);
    }, 2000);
    return () => {
      clearInterval(intervals);
    };
  }, []);

  return (
    <Modal
      show={showStoryModal}
      onHide={() => setShowStoryModal(false)}
      className="BonusHighlightImages"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body>
        {/* <ProgressBarComponent
          startProgressBar={startProgressBar}
          setStartProgressBar={setStartProgressBar}
        /> */}

        <img src={currentStory?.imageUrl} />
      </Modal.Body>

      {/* <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowStoryModal(false)}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
export default StoryModal;
